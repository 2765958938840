import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';
import reactSelect from 'react-select';

function ValvesAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset, setValue } = useForm();

  const [valve, setValve] = useState(null);
  const [valveTypes, setValveTypes] = useState([]);
  const [makers, setMakers] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [selectTanks, setSelectTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);

  useEffect(() => {
    // Carico le tipologie valvole
    axios.post(window.SERVER_URL+'valve_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setValveTypes(res.data.valve_types);

      // Carico i costruttori
      axios.post(window.SERVER_URL+'makers/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setMakers(res.data.makers);

        // Carico i serbatoi
        axios.post(window.SERVER_URL+'tanks/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTanks(res.data.tanks);
          let select_options = [];
          res.data.tanks.map((tank) => {
            select_options.push({ value: tank.id, label: tank.matricola });
          });
          setSelectTanks(select_options);

          // Se sto modificando una valvola esistente, carico le informazioni relative
          if (state && state.valve_id) {
            axios.post(window.SERVER_URL+'valves/get', { id: state.valve_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setValve(res.data);

              // Mi setto le select
              if (res.data.tank_id)
                setSelectedTank({ value: res.data.tank_id, label: res.data.tank.matricola });

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                if (typeof val !== 'object')
                  fields[key] = val;
              reset(fields);
            });
          }
        });
      });
    });    
  }, [reset]);

  const emptyExpDate = () => {
    setValue('data_scadenza', null);
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.tank_id = selectedTank ? selectedTank.value : null;
    delete form_data.scadenza;
    axios.post(window.SERVER_URL+'valves/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/valves');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica valvola</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label>Matricola</label>
                <input type="text" placeholder="Matricola" {...register("matricola")} />
              </Col>
              <Col>
                <label>Tipologia</label>
                <select {...register("valve_type_id")}>
                  <option value="">- Scegli una tipologia -</option>
                  { valveTypes ? valveTypes.map((valve_type) => {
                    return <option key={ valve_type.id } value={ valve_type.id }>{ valve_type.descrizione }</option>
                  }) : '' }
                </select>
              </Col>
              <Col>
                <label>Costruttore</label>
                <select {...register("maker_id")}>
                  <option value="">- Scegli un costruttore -</option>
                  { makers ? makers.map((maker) => {
                    return <option key={ maker.id } value={ maker.id }>{ maker.ragione_sociale }</option>
                  }) : '' }
              </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Serbatoio</label>  
                <Select value={selectedTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tank_id" options={selectTanks} onChange={(e) => setSelectedTank(e)} />
              </Col>
              <Col>
                <label>Descrizione</label>
                <input type="text" placeholder="Descrizione" {...register("descrizione")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Lotto produzione</label>
                <input type="text" placeholder="Lotto" {...register("lotto")} />
              </Col>
              <Col>
                <label>Data collaudo</label>
                <input type="date" placeholder="Data collaudo" {...register("data_collaudo")} onChange={emptyExpDate} />
              </Col>
              <Col>
                <label>Scadenza</label>
                <input type="date" readOnly placeholder="Data scadenza" {...register("data_scadenza")} />
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/valves', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default ValvesAdd;