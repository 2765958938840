import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';
import reactSelect from 'react-select';

function UsersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);

    axios.post(window.SERVER_URL+'groups/list', null, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data.groups);
      setRoles(res.data.groups);

      // Se sto modificando una utente esistente, carico le informazioni relative
      if (state && state.user_id) {
        axios.post(window.SERVER_URL+'users/get', { id: state.user_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          let user_data = res.data;
          setUser(res.data);

            setWait(false);

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(user_data))
              if (typeof val !== 'object')
                fields[key] = val;
            fields['password'] = '**********';
            reset(fields);
          });
        
      } else {
        setWait(false);
      }
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'users/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      Swal.fire({
        icon: 'success',
        title: 'Utente salvato',
        text: '',
        confirmButtonText: 'Torna agli utenti',
        confirmButtonColor: '#3085d6',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/users');
        }
      });
    }).catch(function(error) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        html: 'Utente non salvato.<br /><i>'+error+'</i>'
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica utente</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Nome</label>
                  <input type="text" placeholder="Nome" {...register("nome", { required: true })} />
                  {errors.nome && <span>Devi inserire un nome</span>}
                </Col>
                <Col>
                  <label>Cognome</label>
                  <input type="text" placeholder="Cognome" {...register("cognome", { required: true })} />
                  {errors.cognome && <span>Devi inserire un cognome</span>}
                </Col>
                <Col>
                  <label>Ruolo</label>
                  <select {...register("group_id", { required: true })}>
                    { roles && roles.length ? roles.map(role => 
                      <option key={ role.id } value={ role.id }>{ role.descrizione }</option>
                    ) : ''};
                  </select>
                  {errors.group_id && <span>Devi scegliere un ruolo</span>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>E-mail</label>
                  <input type="email" placeholder="E-mail" {...register("email", { required: true })} />
                  {errors.email && <span>Devi inserire un indirizzo e-mail</span>}
                </Col>
                <Col>
                  <label>Password</label>
                  <input type="password" placeholder="Password" {...register("password", { required: true })} />
                  {errors.password && <span>Devi inserire una password</span>}
                </Col>
                <Col>
                  <label>Inizio lavoro</label>
                  <input type="time" placeholder="Orario inizio" {...register("inizio_lavoro", { required: true })} />
                  {errors.inizio_lavoro && <span>Devi inserire l'orario di inizio</span>}
                </Col>
                <Col>
                  <label>Fine lavoro</label>
                  <input type="time" placeholder="Orario fine" {...register("fine_lavoro", { required: true })} />
                  {errors.fine_lavoro && <span>Devi inserire l'orario di fine</span>}
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/users', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default UsersAdd;