import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function MetersAdd() {
   // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [meter, setMeter] = useState(null);
  const [meterTypes, setMeterTypes] = useState([]);
  const [makers, setMakers] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [selectTanks, setSelectTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [readings, setReadings] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);
    // Carico le tipologie contatori
    axios.post(window.SERVER_URL+'meter_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setMeterTypes(res.data.meter_types);

      // Carico i costruttori
      axios.post(window.SERVER_URL+'makers/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setMakers(res.data.makers);

        // Carico i serbatoi
        axios.post(window.SERVER_URL+'tanks/list', { params: { 'light': true } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTanks(res.data.tanks);
          let select_options = [];
          res.data.tanks.map((tank) => {
            select_options.push({ value: tank.id, label: tank.matricola });
          });
          setSelectTanks(select_options);

          // Carico i clienti
          axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setCustomers(res.data.customers);
            let select_options = [];
            res.data.customers.map((customer) => {
              select_options.push({ value: customer.id, label: customer.etichetta });
            });
            setSelectCustomers(select_options);

            // Se sto modificando un contatore esistente, carico le informazioni relative
            if (state && state.meter_id) {
              axios.post(window.SERVER_URL+'meters/get', { id: state.meter_id }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data);
                setMeter(res.data);

                axios.post(window.SERVER_URL+'activity_operations/list', { params: { entity_id: state.meter_id, operation_id: 10 } }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res_readings => {
                  console.log(res_readings.data);
                  setReadings(res_readings.data.activity_operations);

                  // Mi setto le select
                  if (res.data.tank_id)
                    setSelectedTank({ value: res.data.tank_id, label: res.data.tank.matricola });
                  if (res.data.customer_id)
                    setSelectedCustomer({ value: res.data.customer_id, label: res.data.customer.etichetta });

                  setWait(false);

                  // Setto i campi della form
                  let fields = {};
                  for (const [key, val] of Object.entries(res.data))
                    if (typeof val !== 'object')
                      fields[key] = val;
                  reset(fields);
                });
              });
            }

            if (!state || !state.meter_id)
              setWait(false);
          });
        });
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.tank_id = selectedTank ? selectedTank.value : null;
    form_data.customer_id = selectedCustomer ? selectedCustomer.value : null;
    delete form_data.scadenza;
    axios.post(window.SERVER_URL+'meters/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/meters');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica contatore</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Matricola</label>
                  <input type="text" placeholder="Matricola" {...register("matricola")} />
                </Col>
                <Col>
                  <label>Tipologia</label>
                  <select {...register("meter_type_id")}>
                    <option value="">- Scegli una tipologia -</option>
                    { meterTypes ? meterTypes.map((meter_type) => {
                      return <option key={ meter_type.id } value={ meter_type.id }>{ meter_type.descrizione }</option>
                    }) : '' }
                </select>
                </Col>
                <Col>
                  <label>Costruttore</label>
                  <select {...register("maker_id")}>
                    <option value="">- Scegli un costruttore -</option>
                    { makers ? makers.map((maker) => {
                      return <option key={ maker.id } value={ maker.id }>{ maker.ragione_sociale }</option>
                    }) : '' }
                </select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Serbatoio</label>  
                  <Select value={selectedTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tank_id" options={selectTanks} onChange={(e) => setSelectedTank(e)} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Cliente</label>  
                  <Select value={selectedCustomer} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="customer_id" options={selectCustomers} onChange={(e) => setSelectedCustomer(e)} />
                </Col>
                <Col>
                  <label>Descrizione</label>
                  <input type="text" placeholder="Descrizione" {...register("descrizione")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Data installazione</label>
                  <input type="date" placeholder="Data installazione" {...register("data_installazione")} />
                </Col>
                <Col>
                  <label>Data disinstallazione</label>
                  <input type="date" placeholder="Data disinstallazione" {...register("data_disinstallazione")} />
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <h2>Letture contatore</h2>
            { readings && readings.length ? 
              <table>
                <tr>
                  <th>Data lettura</th>
                  <th>Anno di riferimento</th>
                  <th>Mese di riferimento</th>
                  <th>Lettura</th>
                  {/* <th>Livello riempimento</th> */}
                  <th>Stato</th>
                  <th></th>
                </tr>
                { readings.map((read) => {
                  return <tr>
                    <td>{ read.info.data ? `${new Date(read.info.data).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}` : '' }</td>
                    <td>{ read.info.anno }</td>
                    <td>{ read.info.mese }</td>
                    <td>{ read.info.lettura }</td>
                    {/* <td>{ read.info.riempimento }</td> */}
                    <td className="op_status">
                      <span className="square" style={{'background':read.operation_status.colore}}></span>
                      <span style={{'color':read.operation_status.colore}}>{ read.operation_status.descrizione }</span>
                    </td>
                    <td className="actions">
                      <Button onClick={() => { navigate('/activity_operations/add', {state: { activity_id: read.activity_id, activity_operation_id: read.id }}) }} title="Vai al dettaglio"><DynamicIcon iconName="IoIosArrowForward" /></Button>
                    </td>
                  </tr>
                }) }
              </table>
            : <p style={{'margin':'0 20px'}}>Nessuna lettura presente</p> }
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate(-1) } className="back"><DynamicIcon iconName="RiArrowGoBackFill" /> Torna indietro</Button>
            <Button onClick={() => navigate('/meters', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna ai contatori</Button>
          </Col>
        </Row>
      </Container>
    </>
  );

  
}

export default MetersAdd;