import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function LocationsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [location, setLocation] = useState();
  const [customers, setCustomers] = useState([]);
  const [provinces, setProvinces] = useState();
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setCustomers(res.data.customers);
      let select_options = [];
      res.data.customers.map((cust) => {
        select_options.push({ value: cust.id, label: cust.etichetta+(cust.town ? ' - '+cust.town.nome : '') });
      });
      setSelectCustomers(select_options);

      // Carico le province
      axios.post(window.SERVER_URL+'provinces/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(resprov => {
        setProvinces(resprov.data);

        // Recupero la lista dei comuni
        axios.post(window.SERVER_URL+'locations/towns', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTowns(res.data);
          let select_options = [];
          res.data.map((town) => {
            select_options.push({ value: town.id, label: town.nome });
          });
          setSelectTowns(select_options);

          // Se sto modificando una ubicazione esistente, carico le informazioni relative
          if (state && state.location_id) {
            axios.post(window.SERVER_URL+'locations/get', { id: state.location_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setLocation(res.data);

              // Mi setto le select
              if (res.data.customer_id)
                setSelectedCustomer({ value: res.data.customer_id, label: res.data.customer.etichetta+(res.data.customer.town ? ' - '+res.data.customer.town.nome : '') });
              if (res.data.town_id)
                setSelectedTown({ value: res.data.town_id, label: res.data.town.nome });

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                if (typeof val !== 'object')
                  fields[key] = val;
              reset(fields);

              setWait(false);
            });
          } else {
            // Se sto creando una nuova ubicazione
            if (state && state.customer_id) {
              // se sto creando una ubicazione agganciandola a un cliente, recupero le info del cliente
              axios.post(window.SERVER_URL+'customers/get', { id: state.customer_id }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data);
                let customer = res.data;
                // setto la select del customer
                setSelectedCustomer({ value: customer.id, label: customer.etichetta+(customer.town ? ' - '+customer.town.nome : '') });
                setSelectedTown({ value: customer.town_id, label: customer.town.nome });
                // setto tutte le altre info nella form che posso precompilare
                let fields = {
                  'denominazione': customer.etichetta,
                  'indirizzo': customer.indirizzo,
                  'province_id': customer.province_id
                };
                // provo a recuperare le coordinate dell'indirizzo
                let sigla_prov = null;
                resprov.data.forEach(function(p) {
                  if (p.id == customer.province_id)
                    sigla_prov = p.sigla;
                });

                let address_pos = geocode_point(customer.indirizzo+ ' '+customer.citta+(sigla_prov ? '('+sigla_prov+')' : ''));
                address_pos.then(response => {
                  fields['lat'] = response.data.results[0].geometry.location.lat;
                  fields['lng'] = response.data.results[0].geometry.location.lng;
                  reset(fields);
                }).catch(error => {
                  console.error(error);
                  reset(fields);
                });              

                setWait(false);
              });
            } else {
              setWait(false);
            }
          }
        });
      });
    });
  }, [reset]);

  const geocode_point = (address) => {
    return axios.get(window.GMAPS_GEOCODE_URL+address+'&key='+window.GMAPS_APIKEY);
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    delete form_data.scadenza;
    form_data.customer_id = selectedCustomer ? selectedCustomer.value : null;
    form_data.town_id = selectedTown ? selectedTown.value : null;
    axios.post(window.SERVER_URL+'locations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      Swal.fire({
        icon: 'success',
        title: 'Ubicazione registrata',
        confirmButtonText: 'Torna alle ubicazioni',
        confirmButtonColor: '#198754',
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          navigate('/locations');
        }
      });
    }).catch(function(error) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        html: "Si è verificato un errore durante il salvataggio dell'ubicazione: <i>"+error+"</i>"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica ubicazione</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label style={{'marginTop':'15px'}}>Cliente</label>
                  <Select value={selectedCustomer} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="customer_id" options={selectCustomers} onChange={(e) => setSelectedCustomer(e)} /> 
                </Col>
                <Col>
                  <label>Denominazione</label>
                  <input type="text" placeholder="Denominazione" {...register("denominazione")} />
                </Col>
                <Col>
                  <label>Sede tecnica</label>
                  <input type="text" placeholder="Sede tecnica" {...register("sede_tecnica")} />
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <label>Latitudine</label>
                  <input type="text" placeholder="Latitudine" {...register("lat")} />
                </Col>
                <Col lg={2}>
                  <label>Longitudine</label>
                  <input type="text" placeholder="Longitudine" {...register("lng")} />
                </Col>
                <Col>
                  <label>Descrizione</label>
                  <input type="text" placeholder="Descrizione" {...register("descrizione")} />
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <label>Indirizzo</label>
                  <input type="text" placeholder="Indirizzo" {...register("indirizzo")} />
                </Col>
                <Col lg={4}>
                  <label style={{'marginTop':'15px'}}>Comune</label>
                  <Select value={selectedTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="town_id" options={selectTowns} onChange={(e) => setSelectedTown(e)} /> 
                </Col>
                <Col>
                  <label>Provincia</label>
                  <select {...register("province_id")}>
                    <option value="">- Scegli una provincia -</option>
                    { provinces ? provinces.map((prov) => {
                      return <option key={ prov.id } value={ prov.id }>{ prov.descrizione }</option>
                    }) : '' }
                  </select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Info posizione (individuazione)</label>
                  <textarea placeholder="Info posizione" {...register("individuazione")}></textarea>
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
            <br /><hr /><br />
            <h2>Serbatoi presenti nell'ubicazione</h2>
            { location && location.tanks && location.tanks.length ?
              <ul>
              { location.tanks.map((t) => 
                <li key={ t.matricola } style={{'listStyleType':'none','paddingBottom':'12px','marginBottom':'12px','borderBottom':'1px solid #ccc'}}>
                  <Button onClick={() => { navigate('/tanks/add', {state: { tank_id: t.id }}) }} style={{'padding':'5px','marginRight':'5px'}}>
                    <DynamicIcon iconName="GiFuelTank" size="24" />
                  </Button>
                  <b>{ t.matricola }</b>
                  <div>
                    {/* Contatori per il serbatoio */}
                    <div>
                      <p style={{'fontWeight':'600','display':'inline-block','marginTop':'20px'}}>Contatori collegati:</p>
                      { t.meters && t.meters.length ?
                        <ul style={{'display':'inline-block'}}>
                          { t.meters.map((m) => 
                            <li key={ m.matricola } style={{'display':'inline-block','marginRight':'20px'}}>
                              <Button onClick={() => { navigate('/meters/add', {state: { meter_id: m.id }}) }} style={{'padding':'5px','marginRight':'5px'}}>
                                <DynamicIcon iconName="BsSpeedometer" size="24" />
                              </Button>
                              { m.matricola }
                            </li>
                          )}
                        </ul>
                      : <p style={{'display':'inline-block','margin':'0 20px'}}>Nessun contatore per questo serbatoio</p> }
                    </div>
                    {/* Estintori per il serbatoio */}
                    <div>
                      <p style={{'fontWeight':'600','display':'inline-block','marginTop':'20px'}}>Estintori collegati:</p>
                      { t.extinguishers && t.extinguishers.length ?
                        <ul style={{'display':'inline-block'}}>
                          { t.extinguishers.map((e) => 
                            <li key={ e.matricola } style={{'display':'inline-block','marginRight':'20px'}}>
                              <Button onClick={() => { navigate('/extinguishers/add', {state: { extinguisher_id: e.id }}) }} style={{'padding':'5px','marginRight':'5px'}}>
                                <DynamicIcon iconName="FaFireExtinguisher" size="24" />
                              </Button>
                              { e.matricola }
                            </li>
                          )}
                        </ul>
                      : <p style={{'display':'inline-block','margin':'0 20px'}}>Nessun estintore per questo serbatoio</p> }
                    </div>
                    {/* Valvole per il serbatoio */}
                    <div>
                      <p style={{'fontWeight':'600','display':'inline-block','marginTop':'20px'}}>Valvole collegate:</p>
                      { t.valves && t.valves.length ?
                        <ul style={{'display':'inline-block'}}>
                          { t.valves.map((v) => 
                            <li key={ v.matricola } style={{'display':'inline-block','marginRight':'20px'}}>
                              <Button onClick={() => { navigate('/valves/add', {state: { valve_id: v.id }}) }} style={{'padding':'5px','marginRight':'5px'}}>
                                <DynamicIcon iconName="GiValve" size="24" />
                              </Button>
                              { v.matricola }
                            </li>
                          )}
                        </ul>
                      : <p style={{'display':'inline-block','margin':'0 20px'}}>Nessuna valvola per questo serbatoio</p> }
                    </div>
                  </div>
                </li>
              )}
              </ul>
            : <p style={{'margin':'0 20px'}}>Non sono presenti serbatoi in questa ubicazione</p> }
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/locations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default LocationsAdd;