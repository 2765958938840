import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { GoTasklist } from 'react-icons/go';
import { FaTrash, FaSearch, FaArchive } from 'react-icons/fa';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Activities() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // DATA
  const [activities, setActivities] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  const [viewArchive, setViewArchive] = useState(0);
  const [forceReload, setForceReload] = useState(0);
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, [viewArchive, forceReload]);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
    
    params.completed = viewArchive;
    axios.post(window.SERVER_URL+'activities/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setActivities(res.data.activities);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (activity_id) => {
    setIdToDelete(activity_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questo intervento?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'activities/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setActivities(activities.filter(activity => activity.id !== idToDelete));
        getPage(1, query);
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }

  const createExtActivities = () => {
    Swal.fire({
      icon: 'question',
      title: 'Creazione attività estintori',
      text: "Sei sicuro di voler creare le attività?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Crea attività',
      // denyButtonText: 'Nuova pianificazione',
      cancelButtonText: "Annulla",
      confirmButtonColor: '#198754',
      cancelButtonColor: '#3085d6',
      // denyButtonColor: '#3085d6',
    }).then((result) => {
      if (result.isConfirmed) {
        setWait(true);
        axios.post(window.SERVER_URL+'extinguishers/maintenance_activities', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setWait(false);
          if (res.data.success === true) {
            console.log(res);

            // Confermo la riuscita della creazione attività
            Swal.fire({
              icon: 'success',
              title: 'Creazione attività completata',
              html: 'Sono state create<br>'+
                    '<b>'+res.data.count.c+'</b> attività di controllo<br>'+
                    '<b>'+res.data.count.s+'</b> attività di sostituzione',
              confirmButtonText: 'Procedi',
            }).then((result) => {
              if (result.isConfirmed) {
                setForceReload(forceReload+1);
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione',
              text: 'Si è verificato un errore durante la creazione attività'
            });
          }
        });
      }
    });
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>{ viewArchive ? 'Archivio interventi' : 'Interventi da fare' }</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" style={{'marginRight':'20px'}} onClick={() => { navigate('/activities/add', {state: {page: page, query: query}}); }}><BiPlus /> Aggiungi intervento</Button>
            <Button variant="success" className='batch' style={{'marginRight':'20px'}} onClick={() => { createExtActivities(); }}><DynamicIcon iconName="FaFireExtinguisher" /> Crea interventi estintori</Button>
            { viewArchive ?
              <Button onClick={() => { setViewArchive(0); }}><GoTasklist /> Vedi interventi da fare</Button>
            :
              <Button onClick={() => { setViewArchive(1); }}><FaArchive /> Visualizza archivio</Button>
            }
          </Col>
        </Row>
        <Row>
            <Col style={{margin:'0 20px'}}>
              <form className="custom_form" id="query_form">
                <FaSearch />
                <input type="text" placeholder="Cerca attività" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
              </form>
            </Col>
          </Row>
        <Row>
          <Col>
            { activities ? 
              <>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th style={{'width':'200px'}}>Sede tecnica</th>
                      <th>Ubicazione</th>
                      <th>Indirizzo</th>
                      <th>Comune</th>
                      <th style={{'width':'100px'}}>Priorità</th>
                      <th style={{'width':'120px'}}>Num. attività</th>
                      <th style={{'width':'160px'}}>Creato il</th>
                      <th style={{'width':'120px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { activities.length ? activities.map((activity) => {
                        return <tr key={ activity.id }>
                                <td>{ activity.id }</td>
                                <td>{ activity.location.sede_tecnica }</td>
                                <td>{ activity.location.denominazione }</td>
                                <td>{ activity.location.indirizzo }</td>
                                <td>{ activity.location.town ? activity.location.town.nome : '' } { activity.location.town && activity.location.town.province ? '('+activity.location.town.province.sigla+')' : '' }</td>
                                <td>{ activity.activity_priority.descrizione }</td>
                                <td style={{'textAlign':'center'}}>{ activity.activity_operations.length }</td>
                                <td>{ `${new Date(activity.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/activities/add', {state: {activity_id: activity.id, page: page, query: query}}) }}><IoIosArrowForward /></Button>
                                  <Button onClick={() => { handleDelete(activity.id) }} className="delete"><FaTrash /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Activities;