import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function TankTypesAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();

  const [tankType, setTankType] = useState();

  useEffect(() => {
    // Se sto modificando una tip. serbatoio esistente, carico le informazioni relative
    if (state && state.tank_type_id) {
        axios.post(window.SERVER_URL+'tank_types/get', { id: state.tank_type_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setTankType(res.data);

          let fields = {};
          for (const [key, val] of Object.entries(res.data))
            fields[key] = val;
          reset(fields);
        });
    }
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    
    axios.post(window.SERVER_URL+'tank_types/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/tank_types');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica tipologia serbatoio</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label>Sigla</label>
                <input type="text" placeholder="Sigla" {...register("sigla")} />
              </Col>
              <Col>
                <label>Sigla fornitore</label>
                <input type="text" placeholder="Sigla fornitore" {...register("sigla_fornitore")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Descrizione</label>
                <input type="text" placeholder="Descrizione" {...register("descrizione")} />
              </Col>
            </Row>
            
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/tank_types', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default TankTypesAdd;