import React, { useContext, useEffect, useState, useRef } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// PARTIALS
import Map from '../partials/map';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash, FaSearch, FaUserAlt, FaRegCalendarAlt, FaMapMarkerAlt, FaRoad, FaFlagCheckered, FaCarAlt, FaWrench, FaClock, FaCoffee } from 'react-icons/fa';
import { PiMagnifyingGlassBold, PiPrinterFill } from 'react-icons/pi';
import { GoDot } from 'react-icons/go';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function PathsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2, setValue: setValue2, formState: { errors: errors2 } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [path, setPath] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 42.9705321, lng: 13.7150639 });
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [mapKey, setMapKey] = useState(0);
  const [checks, setChecks] = useState([]);
  const [filteredChecks, setFilteredChecks] = useState([]);
  const [routedChecks, setRoutedChecks] = useState({});
  const [documentTypes, setDocumentTypes] = useState([]);
  const [docTypeOpID, setDocTypeOpID] = useState({
    1: 24,
    2: 24,
    3: 24,
    4: 24,
    5: 24,
    6: 24,
    7: 24,
    8: 24,
    9: 24,
    10: 24,
    11: 24,
    12: 24,
    13: 24,
    14: 24,
    15: 24,
    16: 24,
    17: 24,
    18: 24,
    19: 24,
    20: 3,
    21: 24,
    22: 24,
    23: 24,
    24: 2,
    25: 24,
    26: 3,
    27: 24,
    28: 24,
    29: 24,
    30: 1,
    31: 1
  });
  // REF
  const data_area = useRef(null);
  const list_area = useRef(null);
  const doc_area = useRef(null);

  useEffect(() => {
    // Carico le tipologia di documento
    axios.post(window.SERVER_URL+'document_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setDocumentTypes(res.data);

      if (state && state.path_id) {
        axios.post(window.SERVER_URL+'paths/get', { id: state.path_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setPath(res.data);

          let tmp_checks = [];
          // PARTENZA
          tmp_checks.push({
            id: null, 
            lat: res.data.partenza.lat, 
            lng: res.data.partenza.lng, 
            name: 'PARTENZA', 
            address: res.data.partenza.localita, 
            priority: null,
            num_operations: null,
            time_req: null,
            op_ids: null,
            activity_operations: null,
          });
          // LISTA INTERVENTI
          res.data.tappe.stops.forEach(function(act) {
            if (!act.orderNo.startsWith("break"))
              tmp_checks.push({
                id: act.orderNo, 
                lat: act.latitude, 
                lng: act.longitude, 
                name: act.locationName, 
                address: act.address, 
                priority: null,
                num_operations: null,
                time_req: null,
                op_ids: null,
                activity_operations: null,
              });
          });
          // ARRIVO
          tmp_checks.push({
            id: null, 
            lat: res.data.arrivo.lat, 
            lng: res.data.arrivo.lng, 
            name: 'ARRIVO', 
            address: res.data.arrivo.localita, 
            priority: null,
            num_operations: null,
            time_req: null,
            op_ids: null,
            activity_operations: null,
          });

          setChecks(tmp_checks);
          setFilteredChecks(tmp_checks);

          render();
        });
      } else {
        navigate('/paths');
      }
    });
  }, []);

  const hasBreak = (path_obj) => {
    let has_break = false;
    path_obj.stops.forEach(function(stop) {
      if (stop.orderNo.startsWith("break"))
        has_break = true;
    });
    return has_break;
  }

  const getActivityByID = (act_id) => {
    let tmp_act = null;
    path.activities.forEach(function(act) {
      if (act.id == act_id)
        tmp_act = act;
    });
    return tmp_act;
  }

  const giveMeRealIndex = (orderNo) => {
    if (path && path.tappe && path.tappe.stops) {
      let found = false;
      let index = 1;
      path.tappe.stops.forEach(function(stop) {
        if (stop.orderNo != orderNo && !found) {
          if (!stop.orderNo.startsWith("break"))
            index++;
        } else {
          found = true;
        }
      });
      return index;
    }
  }

  const getEntityByActOpID = (actop_id) => {
    for (const [key, val] of Object.entries(path.actop_entities)) {
      if (key == actop_id)
        return val;
    }
  }

  const getContactsByLocID = (loc_id) => {
    for (const [key, val] of Object.entries(path.loc_contacts)) {
      if (key == loc_id)
        return val;
    }
  }

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return <Map key={mapKey} center={center} zoom={zoom} startPoint={startPoint} endPoint={endPoint} distKm={0} width={'100%'} height={'600px'} checks={filteredChecks} noControls={true} detailPath={true} />;
    }
  }

  // Submit della form di generazione documenti
  const generateDoc = (form_data) => {
    console.log(form_data);
    switch (parseInt(form_data.doc_id)) {
      case 1: // Rapportino estintore
      case 2: // Rapportino generico manutenzione / Cambio valvola
      case 3: // Rapportino protezione catodica
      case 24: // Documento generico
        window.location.href = window.SERVER_URL.replace('api/', '')+'export/modules_from_path/'+form_data.doc_id+'/'+state.path_id;
      break;
      default:
        Swal.fire({
          icon: 'error',
          title: 'Attenzione',
          text: 'La generazione di questo tipo di documento non è ancora disponibile'
        });
      break;
    }
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Visualizza pianificazione</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
          </Col>
        </Row>
        { path ? 
          <>
            <Row className='action_bar one_row' style={{'marginTop':'20px'}}>
              <Button onClick={() => { data_area.current.scrollIntoView(); }}><DynamicIcon iconName="FaInfoCircle" /> Dati pianificazione</Button>
              <Button onClick={() => { list_area.current.scrollIntoView(); }}><DynamicIcon iconName="FaList" /> Lista interventi</Button>
              <Button onClick={() => { doc_area.current.scrollIntoView(); }}><DynamicIcon iconName="GrDocument" /> Genera rapportini</Button>
            </Row>
            <Row style={{'marginTop':'20px'}}>
              <Col>
                <h2 ref={data_area}>Dati pianificazione</h2>
                <Table>
                  <tbody>
                    <tr>
                      <td className='title'>Data</td>
                      <td><FaRegCalendarAlt /> { `${new Date(path.data).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}` }</td>
                      <td className='title'>Distanza</td>
                      <td><FaRoad /> { Math.round(path.tappe.distance) } km</td>
                    </tr>
                    <tr>
                      <td className='title'>Partenza</td>
                      <td>{ path.partenza.localita.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                      <td className='title'>Durata stimata</td>
                      <td><FaClock /> { (path.tappe.duration > 60 ? `${Math.floor(path.tappe.duration / 60)} ore ${path.tappe.duration % 60} min` : path.tappe.duration+' min') }</td>
                    </tr>
                    <tr>
                      <td className='title'>Arrivo</td>
                      <td>{ path.arrivo.localita.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                      <td className='title'>Assegnato a</td>
                      <td><FaUserAlt /> { path.user.nome } { path.user.cognome }</td>
                    </tr>
                    <tr>
                      <td className='title'>Interventi</td>
                      <td><FaMapMarkerAlt /> { hasBreak(path.tappe) ? (path.tappe.stops.length-1) : path.tappe.stops.length }</td>
                      <td className='title'>Creato il</td>
                      <td>{ `${new Date(path.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` }</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row style={{'marginTop':'20px'}}>
              <Col>
                <h2 ref={list_area}>Lista interventi</h2>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ 'width':'50px' }}></th>
                      <th style={{ 'width':'350px' }}></th>
                      <th>Nome e indirizzo / Intervento</th>
                      <th style={{'width':'250px'}}>Coordinate</th>
                      <th style={{ 'width':'200px' }}>Recapiti</th>
                      <th style={{ 'width':'120px' }}>Dist</th>
                      <th style={{ 'width':'120px' }}>Tempo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ 'textAlign':'center' }}><FaFlagCheckered style={{ 'fontSize':'20px' }} /></td>
                      <td>Partenza</td>
                      <td><DynamicIcon iconName="FaMapMarker" /> { path.partenza.localita }</td>
                      <td>
                        { path.partenza.lat }, { path.partenza.lng }
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    { path.tappe.stops ? path.tappe.stops.map((stop, index) => {
                      return (
                        <>
                          { !stop.orderNo.startsWith("break") ? 
                            <>
                              <tr key={ stop.orderNo } style={{'borderTop':'3px solid #000'}}>
                                <td style={{'textAlign':'center'}}><FaCarAlt style={{'fontSize':'20px'}} /></td>
                                <td>Spostamento a Intervento #{giveMeRealIndex(stop.orderNo)}</td>
                                <td>
                                  <DynamicIcon iconName="FaUserAlt" /> <b>{ stop.locationName }</b> &nbsp; <DynamicIcon iconName="FaMapMarker" /> <b>{ stop.address }</b>
                                  { getActivityByID(parseInt(stop.orderNo, 10)).location ?
                                    <>
                                      <br />
                                      <DynamicIcon iconName="FaInfoCircle" /> &nbsp;
                                      <i>{ getActivityByID(parseInt(stop.orderNo, 10)).location.individuazione ? getActivityByID(parseInt(stop.orderNo, 10)).location.individuazione : '-' }</i>
                                    </>
                                  : '' }
                                </td>
                                <td>
                                  { stop.latitude }, { stop.longitude }
                                </td>
                                <td>
                                  { getActivityByID(parseInt(stop.orderNo, 10)).location && getContactsByLocID(getActivityByID(parseInt(stop.orderNo, 10)).location.id).telefono ?
                                    <>
                                      <DynamicIcon iconName="BsTelephoneFill" /> &nbsp;
                                      { getContactsByLocID(getActivityByID(parseInt(stop.orderNo, 10)).location.id).telefono }
                                      <br />
                                    </>
                                  : '' }
                                  { getActivityByID(parseInt(stop.orderNo, 10)).location && getContactsByLocID(getActivityByID(parseInt(stop.orderNo, 10)).location.id).cellulare ?
                                    <>
                                      <DynamicIcon iconName="BsTelephoneFill" /> &nbsp;
                                      { getContactsByLocID(getActivityByID(parseInt(stop.orderNo, 10)).location.id).cellulare }
                                      <br />
                                    </>
                                  : '' }
                                </td>
                                <td>{ Math.round(stop.distance / 1000) } km</td>
                                <td>{ Math.round(stop.travelTime / 60) } min</td>
                              </tr>
                              <tr className="grey" key={ stop.orderNo+"bis" }>
                                <td style={{'textAlign':'center'}}><FaWrench style={{'fontSize':'20px'}} /></td>
                                <td className="tdcol">
                                  Intervento #{giveMeRealIndex(stop.orderNo)} &nbsp; <FaClock /> Orario previsto { stop.scheduledAt }
                                </td>
                                <td colSpan="4" className="tdcol">
                                  { path && getActivityByID(parseInt(stop.orderNo, 10)) ? getActivityByID(parseInt(stop.orderNo, 10)).activity_operations.map((act) => 
                                    <>
                                      <span className="act_link" onClick={() => { navigate('/activity_operations/add', {state: { activity_id: parseInt(stop.orderNo, 10), activity_operation_id: act.id }}) }}>
                                        <GoDot /> <b>{ act.operation.descrizione }</b> | <b>{ act.descrizione }</b> | <b>{ getEntityByActOpID(act.id).etichetta } Matr. { getEntityByActOpID(act.id).matricola }</b>
                                        { getEntityByActOpID(act.id).tank ?
                                          <>
                                            &nbsp;| Serbatoio Matr. { getEntityByActOpID(act.id).tank.matricola }
                                          </>
                                        : ''}
                                        <br />
                                        <FaClock /> Tempo previsto { act.operation.durata } min &nbsp; <DynamicIcon iconName="FaSuitcase" /> { act.supplier.ragione_sociale }
                                      </span>
                                      <Button style={{'padding':'2px 5px','fontSize':'13px','marginLeft':'10px'}} onClick={() => { window.location.href = window.SERVER_URL.replace('api/', '')+'export/modules/'+docTypeOpID[act.operation_id]+'/7/'+(getEntityByActOpID(act.id).tank_id ? getEntityByActOpID(act.id).tank_id : act.entity_id); }}>Stampa rapportino</Button>
                                    </>
                                  ) : ''}
                                </td>
                                <td>{ path && getActivityByID(parseInt(stop.orderNo, 10)) ? 
                                        (getActivityByID(parseInt(stop.orderNo, 10)).durata > 60 ? `${Math.floor(getActivityByID(parseInt(stop.orderNo, 10)).durata / 60)} ore ${getActivityByID(parseInt(stop.orderNo, 10)).durata % 60} min` : getActivityByID(parseInt(stop.orderNo, 10)).durata+' min') 
                                      : '' }
                                </td>
                              </tr>
                            </>
                          : 
                          <tr style={{'borderTop':'3px solid #000'}}>
                            <td style={{'textAlign':'center','background':'#f9e548'}}><FaCoffee style={{'fontSize':'22px'}} /></td>
                            <td style={{'background':'#f9e548'}}></td>
                            <td style={{'background':'#f9e548','textAlign':'center','fontSize':'20px','fontWeight':'600'}}>PAUSA PRANZO</td>
                            <td colSpan="3" style={{'background':'#f9e548'}}></td>
                            <td style={{'background':'#f9e548'}}>60 min</td>
                          </tr>
                          }
                        </>
                      );
                    }) : '' }
                    <tr style={{'borderTop':'3px solid #000'}}>
                      <td style={{ 'textAlign':'center' }}><FaFlagCheckered style={{ 'fontSize':'20px' }} /></td>
                      <td>Arrivo</td>
                      <td><DynamicIcon iconName="FaMapMarker" /> { path.arrivo.localita }</td>
                      <td>
                        { path.arrivo.lat }, { path.arrivo.lng }
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row style={{'marginTop':'20px'}}>
              <Col>
                <h2 ref={doc_area}>Genera rapportini</h2>
                <form key={2} className="custom_form" onSubmit={handleSubmit2(generateDoc)}>
                  <Row>
                    <Col className="custom_form_wrapper">
                      <select {...register2("doc_id", { required: true })}>
                        <option value="">- Seleziona modello -</option>
                        { documentTypes ? documentTypes.map((doc) => {
                            return <option key={'keydoc'+doc.id} value={doc.id}>{doc.descrizione}</option>
                          }) : '' }
                      </select>    
                      {errors2.doc_id && <span>Devi scegliere un modello</span>}
                    </Col>
                    <Col>
                      <Button type="submit" variant="primary" className="waste_btn" style={{'margin':'6px 0 0','padding':'10px 20px','width':'120px'}}>Genera</Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </>
        : '' }
      </Container>
    </>
  );
}

export default PathsAdd;