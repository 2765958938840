import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { FaUpload } from 'react-icons/fa';

function ImportCustomers() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // SPINNER
  const [wait, setWait] = useState(false);
  // SWEETALERT
  const MySwal = withReactContent(Swal);

  useEffect(() => {
  }, []);

  // Submit della form
  const onSubmit = (form_data) => {
    setWait(true);
    console.log(form_data);
    const formData = new FormData();
    formData.append('file', form_data.customers[0]);
    formData.append('type', 'clients');
    axios.post(window.SERVER_URL+'import', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log(res.data);
      setWait(false);
      if (res.data[0]) {
        Swal.fire({
          icon: 'success',
          title: 'Dati importati',
          html: res.data[1]
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          html: res.data[1]
        });
      }
    }).catch(function(error) {
      setWait(false);
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Si è verificato un errore durante l'importazione dei dati"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Importa clienti</h1>
          </Col>
        </Row>
        <Row>
            <Col style={{margin:'0 20px'}}>
              <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                <input type="file" name="customers" {...register("customers", { required: "Il file da importare è obbligatorio" })} />
                <Button type="submit" variant="success" className="waste_btn"><FaUpload /> Importa clienti</Button>
              </form>
            </Col>
          </Row>
      </Container>
    </>
  );
}

export default ImportCustomers;