import React, { useContext, useState, useEffect } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from "react-router-dom";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// AXIOS
import axios from 'axios';
// IMAGES
import logo_ophy from '../../gfx/ophygas.svg';

function AlertPopup(props) {
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Errore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Autenticazione fallita</h4> */}
        <p>Non è stato possibile autenticarti, controlla lo username e la password che hai inserito</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Login() {
  const { signin, lingue } = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  useEffect(() => {
  }, []);

  let navigate = useNavigate();

  const onSubmit = (form_data) => {
    /* FAKE LOGIN */
    signin({'success':'true', 'token':'dsada39hnf', 'name':'Fake User', 'user_id':1, 'group_id':1}, navigate('/dashboard'));
    /*
    console.log("DATI LOGIN");
    console.log(form_data);
    axios.post(window.SERVER_URL+'login', form_data, { headers: {
      'Access-Control-Allow-Origin': '*'
    }}).then(res => {
      console.log("RISPOSTA LOGIN");
      console.log(res.data);
      
      if (res.data.success && res.data.token)
        signin(res.data, navigate('/dashboard'));
      else
        setModalShow(true);
    });
    */
  }

  return (
    <Container id='login_form_wrapper'>
      <Row>
        <Col>
          <img id="logo" src={logo_ophy} />
          <br />
          <form className="custom_form" id="login_form" onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Username" {...register("username", { required: true })} />
            {errors.username && <span>Devi inserire il nome utente</span>}
            <input type="password" placeholder="Password" {...register("password", { required: true })} />
            {errors.password && <span>Devi inserire la password</span>}
            <Button type="submit" variant="primary" className="waste_btn">Accedi / Login</Button>
          </form>
        </Col>
        <AlertPopup show={modalShow} onHide={() => setModalShow(false)} />
      </Row>
    </Container>

  );
}

export default Login;