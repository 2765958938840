import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function RegulationsAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset, setValue } = useForm();

  const [regulation, setRegulation] = useState(null);

  useEffect(() => {
    // Se sto modificando una normativa esistente, carico le informazioni relative
    if (state && state.regulation_id) {
      axios.post(window.SERVER_URL+'regulations/get', { id: state.regulation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setRegulation(res.data);

        // Setto i campi della form
        let fields = {};
        for (const [key, val] of Object.entries(res.data))
          if (typeof val !== 'object')
            fields[key] = val;
        reset(fields);
      });
    }  
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'regulations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/regulations');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica normativa</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label>Sigla</label>
                <input type="text" placeholder="Sigla" {...register("sigla")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Descrizione</label>
                <textarea placeholder="Descrizione" {...register("descrizione")}></textarea>
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/regulations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default RegulationsAdd;