import React, { useEffect, useState, useRef } from 'react';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Map({ center, zoom, height, width, startPoint, endPoint, distKm, checks, optimize, noControls, detailPath, fitBounds }) {
  // window.points = [];
  const ref = useRef();
  const [numSelectedJobs, setNumSelectedJobs] = useState(0);

  const calcDistance = (coords1, coords2) => {
    const earthRadiusKm = 6371;
  
    const dLat = (coords2[0] - coords1[0]) * Math.PI / 180;
    const dLon = (coords2[1] - coords1[1]) * Math.PI / 180;
  
    const lat1 = coords1[0] * Math.PI / 180;
    const lat2 = coords2[0] * Math.PI / 180;
  
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = earthRadiusKm * c;
  
    return d;
  }

  const handlePoint = (mark, not_remove) => {
    var routePoints = JSON.parse(localStorage.getItem('routePoints')) || [];

    // console.log("PREV SELECTED JOBS:");
    // console.log(routePoints);
    let point_to_handle = mark.get('id');
    if (!routePoints.includes(point_to_handle)) {
      routePoints = [...routePoints, point_to_handle];  

      mark.setIcon(customIcon({
        fillColor: window.MARKER_COLORS['checked'],
        strokeColor: 'white'
      }));
      // console.log("Il punto "+point_to_handle+" è stato aggiunto");
      // console.log("NEW SELECTED JOBS:");
      // console.log(routePoints);
    } else {
      if (!not_remove) {  // solo se non ho settato il flag "not_remove"
        let tmp_points = routePoints.filter(point => point_to_handle !== point);
        routePoints = tmp_points;

        mark.setIcon(customIcon({
          fillColor: window.MARKER_COLORS[mark.get('priority')],
          strokeColor: 'white'
        }));
        // console.log("Il punto "+point_to_handle+" era già presente, è stato tolto");
        // console.log("NEW SELECTED JOBS:");
        // console.log(routePoints);
      }
    }

    setNumSelectedJobs(routePoints.length);
    localStorage.setItem('routePoints', JSON.stringify(routePoints));
  }

  const customIcon = (opts) => Object.assign({
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    fillColor: '#34495e',
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1,
  }, opts);

  const startOptimize = () => {
    var routePoints = JSON.parse(localStorage.getItem('routePoints')) || [];
    optimize(routePoints);
  }

  useEffect(() => {
    localStorage.setItem('routePoints', JSON.stringify([]));
    var directionsService = new window.google.maps.DirectionsService();
    var directionsRenderer = new window.google.maps.DirectionsRenderer();
    var map = new window.google.maps.Map(ref.current, { center, zoom });
    directionsRenderer.setMap(map);

    // posiziono sulla mappa tutti i marker relativi agli interventi da effettuare
    // console.log("TUTTI GLI INTERVENTI DA EFFETTUARE:");
    // console.log(checks);
    if (checks) {
      var checkpoints = {};
      let ar_checkpoints = [];
      let ar_coords = [];
      checks.forEach(function(check, index) {
        let latlng = new window.google.maps.LatLng(check.lat, check.lng);
        checkpoints[index] = new window.google.maps.Marker({
          position: latlng,
          map,
          title: check.name+" - "+check.address
        });
        checkpoints[index].setIcon(customIcon({
          fillColor: window.MARKER_COLORS[check.priority],
          strokeColor: 'white'
        }));
        checkpoints[index].set('id', check.id);
        checkpoints[index].set('priority', check.priority);

        if (detailPath === true) {
          if (check.name == 'PARTENZA' || check.name == 'ARRIVO')
            checkpoints[index].setLabel({
              text: check.name,
              className: 'marker_label_special'
            });
          else
            checkpoints[index].setLabel({
              text: '#'+index,
              className: 'marker_label'
            });
        }

        ar_checkpoints.push(checkpoints[index]);
        ar_coords.push(latlng);

        window.google.maps.event.addListener(checkpoints[index], 'click', function() {      
          handlePoint(this, false);    // il flag true serve per fare in modo che se il punto già esiste non va tolto
          // if (this.getIcon().fillColor != window.MARKER_COLORS['checked']) {
            // se il punto non era già selezionato lo aggiungo
            // addSelectedPoint(this);
          // } else {
            // se invece era selezionato lo rimuovo
            // removeSelectedPoint(this);
          // }
        });
      });

      if (detailPath === true) {
        var line = new window.google.maps.Polyline({
          path: ar_coords,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2
        });
        line.setMap(map);
      }

      if (fitBounds !== false) {
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < ar_checkpoints.length; i++)
          bounds.extend(ar_checkpoints[i].getPosition());
        map.fitBounds(bounds);
        map.setCenter(bounds.getCenter());
        map.setZoom(map.getZoom()-1); 
      }
    }

    // se ho impostato una partenza e un arrivo, traccio il percorso che devo effettuare
    if (startPoint && endPoint) {
      var request = {
        origin: startPoint,
        destination: endPoint,
        travelMode: 'DRIVING'
      };
      directionsService.route(request, function(result, status) {
        if (status == 'OK') {
          directionsRenderer.setDirections(result);
          if (result) {
            let steps = result.routes[0].legs[0].steps; // mi ricavo tutti gli step del percorso (a questa cosa si può accedere con una proprietà specifica dell'oggetto, ma bisogna testarla - vedere doc. Google Maps API)
            let points = [];
            steps.forEach(function(step, i) { // per ogni step del percorso creato da Google Maps
              var marker = new window.google.maps.Marker();  // creo un marker
              let latlng = new window.google.maps.LatLng(step.start_location.lat(), step.start_location.lng());
              marker.setPosition(latlng);  // posiziono il marker
              // marker.setMap(map);  // lo aggiungo alla mappa
              points.push(marker);
            });

            // creo tutti i marker intermedi
            var newMarkers = [];
            for (var i = 0; i < points.length-1; i++) {
              var current = points[i].getPosition();
              var end = points[i+1].getPosition();
              var distance = window.google.maps.geometry.spherical.computeDistanceBetween(current, end);
              var interval = 1000;
              while (distance > 1000) {
                var next = window.google.maps.geometry.spherical.computeOffset(current, interval, window.google.maps.geometry.spherical.computeHeading(current, end));
                var marker = new window.google.maps.Marker();
                marker.setPosition(next);  // posiziono il marker
                // marker.setMap(map);  // lo aggiungo alla mappa
                newMarkers.push(marker);
                distance -= interval;
                current = next;
              }
            }

            // unisco i 2 array per avere tutti i markers percorso che devo valutare
            points = points.concat(newMarkers);
            
            // per ogni marker percorso vedo quali sono gli interventi che posso effettuare in base alla distanza che ho stabilito
            for (var i = 0; i < points.length-1; i++) {
              let step_lat = points[i].getPosition().lat();
              let step_lng = points[i].getPosition().lng();
              checks.forEach(function(check, index) {
                let coords1 = [step_lat, step_lng];
                let coords2 = [check.lat, check.lng];
                let calc_d = calcDistance(coords1, coords2);
                if (calc_d <= distKm) {
                  handlePoint(checkpoints[index], true);  // il flag true serve per fare in modo che se il punto già esiste non va tolto
                }
              });
            }
          }
        }
      });
    }
  }, [ref, checks]);
  
  return (
    <>
      <div ref={ref} id="map" style={{ height, width }} />
      { noControls !== true ?
      <>
        <br /><hr />
        <Container className="content noMargin">
          <Row>
            <Col style={{ 'textAlign':'center' }}>
              <p>Hai selezionato <b>{ numSelectedJobs }</b> interventi</p>
              <Button variant="primary" className="waste_btn" style={{ 'display':'block','width':'320px' }} onClick={() => { startOptimize(); }}>Ottimizza percorso</Button>
            </Col>
          </Row>
        </Container>
      </>
      : '' }
    </>
  );
};

export default Map;