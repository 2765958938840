import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function StocksAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [stock, setStock] = useState(null);
  const [stockTypes, setStockTypes] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [taxRates, setTaxRates] = useState([]);
  const [selectTaxRates, setSelectTaxRates] = useState([]);
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);

  useEffect(() => {
    // Carico le categorie del magazzino
    axios.post(window.SERVER_URL+'stock_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setStockTypes(res.data.stock_types);

      // Carico le unità di misura
      axios.post(window.SERVER_URL+'measurement_units/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setMeasurementUnits(res.data.measurement_units);

        // Carico i fornitori
        axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setSuppliers(res.data.suppliers);
          let select_options = [];
          res.data.suppliers.map((sup) => {
            select_options.push({ value: sup.id, label: sup.etichetta });
          });
          setSelectSuppliers(select_options);

          // Carico le aliquote IVA
          axios.post(window.SERVER_URL+'tax_rates/list', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setTaxRates(res.data.tax_rates);
            let select_options = [];
            res.data.tax_rates.map((tax) => {
              select_options.push({ value: tax.id, label: tax.descrizione });
            });
            setSelectTaxRates(select_options);

            // Se sto modificando una materiale esistente, carico le informazioni relative
            if (state && state.stock_id) {
              axios.post(window.SERVER_URL+'stocks/get', { id: state.stock_id }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data);
                setStock(res.data);

                // Mi setto le select
                if (res.data.supplier_id)
                  setSelectedSupplier({ value: res.data.supplier_id, label: res.data.supplier.etichetta });
                if (res.data.tax_rate_id)
                  setSelectedTaxRate({ value: res.data.tax_rate_id, label: res.data.tax_rate.descrizione });

                // Setto i campi della form
                let fields = {};
                for (const [key, val] of Object.entries(res.data))
                  if (typeof val !== 'object')
                    fields[key] = val;
                reset(fields);
              });
            }
          });
        });
      });
    });    
  }, [reset]);

  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.supplier_id = selectedSupplier ? selectedSupplier.value : null;
    form_data.tax_rate_id = selectedTaxRate ? selectedTaxRate.value : null;
    axios.post(window.SERVER_URL+'stocks/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/stocks');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica materiale</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label>Codice</label>
                <input type="text" placeholder="Codice" {...register("codice")} />
              </Col>
              <Col>
                <label>Categoria</label>
                <select {...register("stock_type_id")}>
                  <option value="">- Scegli una categoria -</option>
                  { stockTypes ? stockTypes.map((stock_type) => {
                    return <option key={ stock_type.id } value={ stock_type.id }>{ stock_type.descrizione }</option>
                  }) : '' }
              </select>
              </Col>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Fornitore</label>
                <Select value={selectedSupplier} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Descrizione</label>
                <input type="text" placeholder="Descrizione" {...register("descrizione")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Prezzo</label>
                <input type="number" step="0.01" placeholder="Prezzo" {...register("prezzo")} />
              </Col>
              <Col>
                <label>Aliquota IVA</label>
                <Select value={selectedTaxRate} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tax_rate_id" options={selectTaxRates} onChange={(e) => setSelectedTaxRate(e)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Giacenza</label>
                <input type="number" min="0" step="0.1" placeholder="Giacenza" readOnly={state.stock_id ? true : false} {...register("giacenza")} />
              </Col>
              <Col>
                <label>Unità di misura</label>
                <select {...register("measurement_unit_id")}>
                  <option value="">- Scegli un'unità -</option>
                  { measurementUnits ? measurementUnits.map((unit) => {
                    return <option key={ unit.id } value={ unit.id }>{ unit.descrizione } { unit.sigla ? '('+unit.sigla+')' : '' }</option>
                  }) : '' }
                </select>
              </Col>
              <Col>
                <label>Livello di alert</label>
                <input type="number" min="0" step="0.1" placeholder="Livello di alert" {...register("livello_alert")} />
              </Col>
            </Row>
            { (!state || !state.stock_id) ? 
              <>
                <br /><hr />
                <Alert variant="info">
                  <p>Se stai aggiungendo un nuovo prodotto che ha una bolla di riferimento, inserisci qui i dettagli.<br />Verranno salvati sul movimento in ingresso del prodotto.</p>
                </Alert>
                <Row>
                  <Col>
                    <label>Numero bolla</label>
                    <input type="text" placeholder="Num. bolla" {...register("numero_bolla")} />
                  </Col>
                  <Col>
                    <label>Data bolla</label>
                    <input type="date" placeholder="Data bolla" {...register("data_bolla")} />
                  </Col>
                </Row>
                </>
              : '' }
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/stocks', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default StocksAdd;