import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// MODAL
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function TanksAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2, setValue: setValue2, formState: { errors: errors2 } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [tank, setTank] = useState(null);
  const [tankTypes, setTankTypes] = useState([]);
  const [tankEquipments, setTankEquipments] = useState([]);
  const [makers, setMakers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [regulationsTanks, setRegulationsTanks] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico le tipologie serbatoi
    axios.post(window.SERVER_URL+'tank_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setTankTypes(res.data.tank_types);

      // Carico gli equipment
      axios.post(window.SERVER_URL+'tank_equipments/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTankEquipments(res.data.tank_equipments);

        // Carico i costruttori
        axios.post(window.SERVER_URL+'makers/list', { params: { 'light': true } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setMakers(res.data.makers);

          // Carico i fornitori
          axios.post(window.SERVER_URL+'suppliers/list', { params: { 'light': true } }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setSuppliers(res.data.suppliers);

            // Carico le ubicazioni
            axios.post(window.SERVER_URL+'locations/list', { params: { 'light': true } }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setLocations(res.data.locations);
              let select_options = [];
              res.data.locations.map((loc) => {
                select_options.push({ value: loc.id, label: loc.denominazione });
              });
              setSelectLocations(select_options);

              // Carico le province
              axios.post(window.SERVER_URL+'provinces/list', { params: { 'light': true } }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                setProvinces(res.data);

                // Carico le tipologia di documento
                axios.post(window.SERVER_URL+'document_types/list', {}, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  setDocumentTypes(res.data);

                  // Se sto modificando una serbatoio esistente, carico le informazioni relative
                  if (state && state.tank_id) {
                    axios.post(window.SERVER_URL+'tanks/get', { id: state.tank_id }, { headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }}).then(res => {
                      console.log(res.data);
                      setTank(res.data);
                      setRegulationsTanks(res.data.regulations_tanks);

                      setWait(false);

                      // Mi setto le select
                      if (res.data.location_id)
                        setSelectedLocation({ value: res.data.location_id, label: res.data.location.denominazione });

                      // Setto i campi della form
                      let fields = {};
                      for (const [key, val] of Object.entries(res.data))
                        if (typeof val !== 'object')
                          fields[key] = val;
                      reset(fields);
                    });
                  }
                
                  if (!state || !state.tank_id)
                    setWait(false);
                });
              });
            });
          });
        });
      });
    });    
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.location_id = selectedLocation ? selectedLocation.value : null;
    axios.post(window.SERVER_URL+'tanks/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      Swal.fire({
        icon: 'success',
        title: 'Serbatoio salvato',
        text: '',
        // showDenyButton: false,
        // showCancelButton: true,
        confirmButtonText: 'Torna ai serbatoi',
        // denyButtonText: 'Annulla',
        // cancelButtonText: 'Annulla',
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/tanks');
        }
      });
    }).catch(function(error) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        html: 'Il serbatoio non è stato salvato.<br /><i>'+error+'</i>'
      });
      // console.log(error);
    });
  }

  const handleDelete = (reg_id) => {
    setIdToDelete(reg_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questa normativa?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'regulations_tanks/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setRegulationsTanks(regulationsTanks.filter(reg => reg.id !== idToDelete));
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }

  // Submit della form di generazione documenti
  const generateDoc = (form_data) => {
    console.log(form_data);
    switch (parseInt(form_data.doc_id)) {
      case 1: // Rapportino estintore
      case 2: // Rapportino generico manutenzione / Cambio valvola
      case 3: // Rapportino protezione catodica
      case 24: // Documento generico
        window.location.href = window.SERVER_URL.replace('api/', '')+'export/modules/'+form_data.doc_id+'/7/'+state.tank_id;
      break;
      default:
        Swal.fire({
          icon: 'error',
          title: 'Attenzione',
          text: 'La generazione di questo tipo di documento non è ancora disponibile'
        });
      break;
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica serbatoio</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form key={1} className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Anno</label>
                  <input type="text" placeholder="Anno" {...register("anno", { required: true })} />
                  {errors.anno && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Matricola</label>
                  <input type="text" placeholder="Matricola" {...register("matricola", { required: true })} />
                  {errors.matricola && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Provincia</label>
                  <select {...register("province_id", { required: true })}>
                    <option value="">- Scegli una provincia -</option>
                    { provinces ? provinces.map((prov) => {
                      return <option key={ prov.id } value={ prov.id }>{ prov.sigla } - { prov.descrizione }</option>
                    }) : '' }
                  </select>
                  {errors.province_id && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Num. fabbrica</label>
                  <input type="text" placeholder="Num. fabbrica" {...register("num_fabbrica", { required: true })} />
                </Col>
              </Row>
              {/* <br /><hr /> */}
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Ubicazione</label>
                  <Select value={selectedLocation} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="location_id" options={selectLocations} onChange={(e) => setSelectedLocation(e)} />
                </Col>
                <Col>
                  <label>Costruttore</label>
                  <select {...register("maker_id")}>
                    <option value="">- Scegli un costruttore -</option>
                    { makers ? makers.map((maker) => {
                      return <option key={ maker.id } value={ maker.id }>{ maker.ragione_sociale }</option>
                    }) : '' }
                  </select>
                </Col>
                <Col>
                  <label>Fornitore</label>
                  <select {...register("supplier_id")}>
                    <option value="">- Scegli un fornitore -</option>
                    { suppliers ? suppliers.map((supplier) => {
                      return <option key={ supplier.id } value={ supplier.id }>{ supplier.ragione_sociale }</option>
                    }) : '' }
                  </select>
                </Col>
              </Row>
              {/* <br /><hr /> */}
              <Row>
                <Col>
                  <label>Capacità (lt)</label>
                  <input type="text" placeholder="Capacità" {...register("capacita")} />
                </Col>
                <Col>
                  <label>Tipologia</label>
                  <select {...register("tank_type_id", { required: true })}>
                    <option value="">- Scegli una tipologia -</option>
                    { tankTypes ? tankTypes.map((tank_type) => {
                      return <option key={ tank_type.id } value={ tank_type.id }>{ tank_type.descrizione }</option>
                    }) : '' }
                  </select>
                </Col>
                <Col>
                  <label>Equipment</label>
                  <select {...register("tank_equipment_id", { required: true })}>
                    <option value="">- Scegli un tipo -</option>
                    { tankEquipments ? tankEquipments.map((tank_equipment) => {
                      return <option key={ tank_equipment.id } value={ tank_equipment.id }>{ tank_equipment.descrizione }</option>
                    }) : '' }
                  </select>
                </Col>
                <Col>
                  <label>Riempimento (%)</label>
                  <input type="text" placeholder="Riempimento" {...register("riempimento")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Note</label>
                  <textarea placeholder="Note" {...register("note")}></textarea>
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        { tank ? 
          <>
            <hr />
            <Row className='boxed'>
              <Col>
                <h2>Estintori</h2>
                { tank.extinguishers.length ? 
                  <ul>
                    { tank.extinguishers.map((ext) => {
                      return <li key={ ext.matricola } style={{'listStyleType':'none','borderBottom':'1px solid #ccc','paddingBottom':'7px','marginBottom':'12px'}}>
                                <Button onClick={() => { navigate('/extinguishers/add', {state: { extinguisher_id: ext.id }}) }} style={{'padding':'5px','marginRight':'5px'}}><DynamicIcon iconName="FaFireExtinguisher" size="24" /></Button>
                                { ext.descrizione ? ext.descrizione : 'Estintore' } <br />
                                Matricola: { ext.matricola } <br />
                                Peso: { ext.peso } kg <br />
                                Data installazione: { ext.data_installazione ? ext.data_installazione.substr(0, 10).split("-").reverse().join("/") : '-' } <br />
                                Data scadenza: { ext.data_scadenza ? ext.data_scadenza.substr(0, 10).split("-").reverse().join("/") : '-' } <br />
                                <Button style={{'padding':'2px 5px','fontSize':'13px'}} onClick={() => { window.location.href = window.SERVER_URL.replace('api/', '')+'export/modules/1/4/'+ext.id; }}>Rapportino</Button>
                              </li>
                    }) }
                  </ul> : <p>Nessun estintore collegato</p> }
              </Col>
              <Col>
                <h2>Valvole</h2>
                { tank.valves.length ? 
                  <ul>
                    { tank.valves.map((valve) => {
                      return <li key={ valve.matricola } style={{'listStyleType':'none','borderBottom':'1px solid #ccc','paddingBottom':'7px','marginBottom':'12px'}}>
                                Matricola: { valve.matricola } <br />
                                Lotto: { valve.lotto } <br />
                                Data collaudo: { valve.data_collaudo ? valve.data_collaudo.substr(0, 10).split("-").reverse().join("/") : '-' } <br />
                                Data scadenza: { valve.data_scadenza ? valve.data_scadenza.substr(0, 10).split("-").reverse().join("/") : '-' }
                              </li>
                    }) }
                  </ul> : <p>Nessun estintore collegato</p> }
              </Col>
              <Col>
                <h2>Contatori</h2>
                { tank.meters.length ? 
                  <ul>
                    { tank.meters.map((meter) => {
                      return <li key={ meter.matricola } style={{'listStyleType':'none','borderBottom':'1px solid #ccc','paddingBottom':'7px','marginBottom':'12px'}}>
                                <Button onClick={() => { navigate('/meters/add', {state: {meter_id: meter.id}}) }} style={{'padding':'5px','marginRight':'5px'}}><DynamicIcon iconName="BsSpeedometer" size="24" /></Button>
                                { meter.descrizione ? meter.descrizione : 'Contatore' } <br />
                                Matricola: { meter.matricola } <br />
                                Data installazione: { meter.data_installazione ? meter.data_installazione.substr(0, 10).split("-").reverse().join("/") : '-' } <br />
                                Data disinstallazione: { meter.disinstallazione ? meter.disinstallazione.substr(0, 10).split("-").reverse().join("/") : '-' }
                              </li>
                    }) }
                  </ul> : <p>Nessun estintore collegato</p> }
              </Col>
            </Row>
            <hr />
            <Row className='boxed'>
              <Col>
                <h2>Genera modello</h2>
              </Col>
            </Row>
            <form key={2} className="custom_form" onSubmit={handleSubmit2(generateDoc)}>
              <input type="hidden" value={tank.id} />
              <Row>
                <Col className="custom_form_wrapper">
                    <select {...register2("doc_id", { required: true })}>
                      <option value="">- Seleziona modello -</option>
                      { documentTypes ? documentTypes.map((doc) => {
                          return <option key={'keydoc'+doc.id} value={doc.id}>{doc.descrizione}</option>
                        }) : '' }
                    </select>    
                    {errors2.doc_id && <span>Devi scegliere un modello</span>}
                </Col>
                <Col>
                  <Button type="submit" variant="primary" className="waste_btn" style={{'margin':'6px 0 0','padding':'10px 20px','width':'120px'}}>Genera</Button>
                </Col>
              </Row>
            </form>
            <hr />
            <Row className='boxed'>
              <Col>
                <h2>Normative</h2>
              </Col>
            </Row>
            <Row className="action_bar top" style={{'padding':'0 35px'}}>
              <Col>
                <Button variant="success" onClick={() => { navigate('/regulations_tanks/add', { state: { tank_id: tank.id, tank_label: tank.matricola } }); }}><BiPlus /> Aggiungi normativa</Button>
              </Col>
            </Row>
            <Row className='boxed'>
              <Col>
                { regulationsTanks ?
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Normativa</th>
                        <th>Data certificazione</th>
                        <th>Descrizione</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { regulationsTanks.map((reg) => {
                        return <tr key={ reg.id }>
                                <td>{ reg.id }</td>
                                <td>{ reg.regulation.descrizione }</td>
                                <td>{ reg.data_certificazione }</td>
                                <td>{ reg.regulation_description.descrizione }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/regulations_tanks/add', { state: { regulation_tank_id: reg.id } }) }}><IoIosArrowForward /></Button>
                                  <Button onClick={() => { handleDelete(reg.id) }} className="delete"><FaTrash /></Button>
                                </td>
                              </tr>
                      }) }
                    </tbody>
                  </Table>
                : <p className='boxed'>Nessuna normativa registrata</p> }
              </Col>
            </Row>
            <br /><hr />
          </>
        : '' }
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/tanks', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
          </Col>
        </Row>
      </Container>
    </>
  );

  
}

export default TanksAdd;