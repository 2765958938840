import React from 'react';
// BOOTSTRAP
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalConfirm(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.modalOptions.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.modalOptions.text}</Modal.Body>
            <Modal.Footer>
                { props.modalOptions.closeTxt ?
                    <Button variant="secondary" onClick={() => props.handleClose(null)}>{props.modalOptions.closeTxt}</Button>
                : '' }
                { props.modalOptions.confirmTxt ?
                    <Button variant="danger" onClick={() => props.handleConfirm(null)}>{props.modalOptions.confirmTxt}</Button>
                : '' }
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirm;