import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";
// REACT SELECT
import Select from 'react-select';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash, FaSearch } from 'react-icons/fa';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Tanks() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // DATA
  const [tanks, setTanks] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  const [orderField, setOrderField] = useState('id');
  const [orderType, setOrderType] = useState('ASC');
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    axios.post(window.SERVER_URL+'locations/towns', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setTowns(res.data);
      let select_options = [];
      res.data.map((town) => {
        select_options.push({ value: town.id, label: town.nome });
      });
      setSelectTowns(select_options);

      axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setSuppliers(res.data.suppliers);
        let select_options = [];
        res.data.suppliers.map((sup) => {
          select_options.push({ value: sup.id, label: sup.ragione_sociale });
        });
        setSelectSuppliers(select_options);

        document.getElementById('query').value = state && state.query ? state.query : query;
        getPage(
          state && state.page ? state.page : page,
          state && state.query ? state.query : query
        );

        setWait(false);
      });    
    });    
  }, []);

  const retrieveTanks = (params) => {
    if (params && params.page) {
      axios.post(window.SERVER_URL+'tanks/list', { params }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setTanks(res.data.tanks);
        setPage(params.page);
        setNumPages(res.data.pages);
        setCount(res.data.count);
      });
    }
  }

  const getPage = (i, params) => {
    if (!params)
      var params = {
        page: i,
        search: {
          query: (query && query.length >= 3 ? query : ''),
          fornitore: selectedSupplier ? selectedSupplier.value : '',
          comune: selectedTown ? selectedTown.value : ''
        },
        order: {
          field: orderField,
          type: orderType
        }
      }
    console.log("PARAMS:");
    console.log(params); 
    retrieveTanks(params);
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    setQuery(typed);
    var params = {
      page: 1,
      search: {
        query: (typed && typed.length >= 3 ? typed : ''),
        fornitore: selectedSupplier ? selectedSupplier.value : '',
        comune: selectedTown ? selectedTown.value : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeSelectedSupplier = (supplier) => {
    console.log(supplier);
    setSelectedSupplier(supplier);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        fornitore: supplier ? supplier.value : '',
        comune: selectedTown ? selectedTown.value : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeSelectedTown = (town) => {
    console.log(town);
    setSelectedTown(town);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        fornitore: selectedSupplier ? selectedSupplier.value : '',
        comune: town ? town.value : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeOrder = (field) => {
    console.log("CHANGED ORDER");
    setOrderField(field);
    let tmpType = (field == orderField ? (orderType == 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    setOrderType(tmpType);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        fornitore: selectedSupplier ? selectedSupplier.value : '',
        comune: selectedTown ? selectedTown.label : ''
      },
      order: {
        field: field ? field : 'id',
        type: tmpType
      }
    }
    getPage(1, params);
  }

  const handleDelete = (tank_id) => {
    setIdToDelete(tank_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questo serbatoio?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'tanks/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setTanks(tanks.filter(tank => tank.id !== idToDelete));
        getPage(1, query);
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Serbatoi</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
            <Col>
              <Button variant="success" onClick={() => { navigate('/tanks/add', {state: {page: page, query: query}}); }}><BiPlus /> Aggiungi serbatoio</Button>
            </Col>
          </Row>
        <Row>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>Matricola e/o nominativo</label>
              <form className="custom_form" id="query_form">
                <FaSearch />
                <input type="text" placeholder="Cerca serbatoio" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
              </form>
            </Col>
            <Col>
              <label style={{textAlign: 'left'}}>Comuni</label>
              <Select value={selectedTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="town" options={selectTowns} onChange={(e) => handleChangeSelectedTown(e)} />
            </Col>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>Fornitori</label>
              <Select value={selectedSupplier} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => handleChangeSelectedSupplier(e)} />
            </Col>
          </Row>
        <Row>
          <Col>
            { tanks ? 
              <>
                <Table striped responsive className='orderable'>
                  <thead>
                    <tr>
                      <th onClick={() => { handleChangeOrder('id') }}>ID { orderField == 'id' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('matricola') }}>Matricola { orderField == 'matricola' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('anno') }}>Anno { orderField == 'anno' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('location.denominazione') }}>Ubicazione { orderField == 'location.denominazione' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('location.customer.etichetta') }}>Cliente { orderField == 'location.customer.etichetta' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('location.town.nome') }}>Comune { orderField == 'location.town.nome' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('tank_type.descrizione') }}>Tipologia { orderField == 'tank_type.descrizione' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('tank_equipment.descrizione') }}>Equip. { orderField == 'tank_equipment.descrizione' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('num_fabbrica') }}>Num. fabbrica { orderField == 'num_fabbrica' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('capacita') }}>Capacità (lt) { orderField == 'capacita' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('riempimento') }}>Riemp. (%) { orderField == 'riempimento' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('meters.num') }}>Num. contatori { orderField == 'meters.num' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('maker.ragione_sociale') }}>Costruttore { orderField == 'maker.ragione_sociale' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('supplier.ragione_sociale') }}>Fornitore { orderField == 'supplier.ragione_sociale' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th style={{'width':'220px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { tanks.length ? tanks.map((tank) => {
                        return <tr key={ tank.id }>
                                <td>{ tank.id }</td>
                                <td>{ tank.matricola }</td>
                                <td>{ tank.anno }</td>
                                <td>{ tank.location ? tank.location.denominazione : '' }</td>
                                <td>{ tank.location && tank.location.customer ? tank.location.customer.etichetta : '' }</td>
                                <td>{ tank.location && tank.location.town ? tank.location.town.nome : '' } { tank.location && tank.location.town && tank.location.town.province ? '('+tank.location.town.province.sigla+')' : '' }</td>
                                <td>{ tank.tank_type ? tank.tank_type.descrizione : '-' }</td>
                                <td>{ tank.tank_equipment ? tank.tank_equipment.descrizione : '-' }</td>
                                <td>{ tank.num_fabbrica }</td>
                                <td>{ tank.capacita }</td>
                                <td>{ tank.riempimento }</td>
                                <td>{ tank.meters ? tank.meters.length : '-' }</td>
                                <td>{ tank.maker ? tank.maker.ragione_sociale : '' }</td>
                                <td>{ tank.supplier ? tank.supplier.ragione_sociale : '' }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/tanks/add', { state: { tank_id: tank.id, page: page, query: query } }) }}><IoIosArrowForward /></Button>
                                  <Button onClick={() => { navigate('/locations/add', { state: { location_id: tank.location_id, page: page, query: query } }) }} title="Vai all'ubicazione"><DynamicIcon iconName='FaMapMarkerAlt' /></Button>
                                  <Button onClick={() => { navigate('/customers/add', { state: { customer_id: tank.location.customer.id, page: page, query: query } }) }} title="Vai al cliente"><DynamicIcon iconName="FaIndustry" /></Button>
                                  <Button onClick={() => { handleDelete(tank.id) }} className="delete"><FaTrash /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Tanks;