import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function OperationSuppliersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [operationSupplier, setOperationSupplier] = useState();
  const [operations, setOperations] = useState([]);
  const [selectOperations, setSelectOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [taxRates, setTaxRates] = useState([]);
  const [selectTaxRates, setSelectTaxRates] = useState([]);
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);

  useEffect(() => {
    // Carico le operazioni
    axios.post(window.SERVER_URL+'operations/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setOperations(res.data.operations);
      let select_options = [];
      res.data.operations.map((op) => {
        select_options.push({ value: op.id, label: op.descrizione });
      });
      setSelectOperations(select_options);

      // Carico i fornitori
      axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setSuppliers(res.data.suppliers);
        let select_options = [];
        res.data.suppliers.map((sup) => {
          select_options.push({ value: sup.id, label: sup.etichetta });
        });
        setSelectSuppliers(select_options);

        // Carico le aliquote IVA
        axios.post(window.SERVER_URL+'tax_rates/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTaxRates(res.data.tax_rates);
          let select_options = [];
          res.data.tax_rates.map((tax) => {
            select_options.push({ value: tax.id, label: tax.descrizione });
          });
          setSelectTaxRates(select_options);

          // Se sto modificando un'attività esistente, carico le informazioni relative
          if (state && state.operation_supplier_id) {
            axios.post(window.SERVER_URL+'operation_suppliers/get', { id: state.operation_supplier_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setOperationSupplier(res.data);

              // Mi setto le select
              if (res.data.operation_id)
                setSelectedOperation({ value: res.data.operation_id, label: res.data.operation.descrizione });
              if (res.data.supplier_id)
                setSelectedSupplier({ value: res.data.supplier_id, label: res.data.supplier.etichetta });
              if (res.data.tax_rate_id)
                setSelectedTaxRate({ value: res.data.tax_rate_id, label: res.data.tax_rate.descrizione });

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                if (typeof val !== 'object')
                  fields[key] = val;
              reset(fields);
            });
          }
        });
      });
    });
  }, []);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.operation_id = selectedOperation ? selectedOperation.value : null;
    form_data.supplier_id = selectedSupplier ? selectedSupplier.value : null;
    form_data.tax_rate_id = selectedTaxRate ? selectedTaxRate.value : null;
    axios.post(window.SERVER_URL+'operation_suppliers/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/operation_suppliers');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica prezzo attività</h1>
        </Col>
      </Row>
       <Row>
         <Col className="custom_form_wrapper">
           <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
             <Row>
               <Col>
                 <label style={{'margin':'8px 0 18px'}}>Attività</label>
                 <Select value={selectedOperation} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="operation_id" options={selectOperations} onChange={(e) => setSelectedOperation(e)} />
               </Col>
               <Col>
                 <label style={{'margin':'8px 0 18px'}}>Fornitore</label>
                 <Select value={selectedSupplier} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
               </Col>
              </Row>
              <Row>
               <Col>
                 <label>Prezzo (€)</label>
                 <input type="number" placeholder="Prezzo" min="1" max="10000" step="0.1" {...register("prezzo")} />
               </Col>
               <Col>
                 <label>Aliquota IVA</label>
                 <Select value={selectedTaxRate} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tax_rate_id" options={selectTaxRates} onChange={(e) => setSelectedTaxRate(e)} />
               </Col>
             </Row>
             <Row>
              <Col>
                <label>Sospeso</label>
                <select {...register("sospeso")}>
                  <option value="1" selected={(!operationSupplier || (operationSupplier && operationSupplier.sospeso == '1')) ? true : false}>Sì</option>
                  <option value="0" selected={(!operationSupplier || (operationSupplier && operationSupplier.sospeso == '0')) ? true : false}>No</option>
                </select>
              </Col>
              <Col>
                <label>Raggruppa in fattura</label>
                <select {...register("raggruppa_in_fattura")}>
                  <option value="1" selected={(!operationSupplier || (operationSupplier && operationSupplier.raggruppa_in_fattura == '1')) ? true : false}>Sì</option>
                  <option value="0" selected={(!operationSupplier || (operationSupplier && operationSupplier.raggruppa_in_fattura == '0')) ? true : false}>No</option>
                </select>
              </Col>
             </Row>
             <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
           </form>
         </Col>
       </Row>
       <Row className="action_bar bottom">
         <Col>
           <Button onClick={() => navigate('/operation_suppliers', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
         </Col>
       </Row>
     </Container>
  );
}

export default OperationSuppliersAdd;