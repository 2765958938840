import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { FaUpload } from 'react-icons/fa';

function ImportValves() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setSuppliers(res.data.suppliers);
      let select_options = [];
      res.data.suppliers.map((sup) => {
        select_options.push({ value: sup.id, label: sup.ragione_sociale });
      });
      setSelectSuppliers(select_options);
    });
  }, []);

  // Submit della form
  const onSubmit = (form_data) => {
    const formData = new FormData();
    formData.append('file', form_data.valves[0]);
    formData.append('type', 'valves');
    formData.append('supplier_id', (selectedSupplier ? selectedSupplier.value : null));
    console.log(formData);
    axios.post(window.SERVER_URL+'import', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/import_valves');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Importa sostituzioni valvole</h1>
          </Col>
        </Row>
        <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>Fornitore</label>
              <Select value={selectedSupplier} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
            </Col>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>File da importare</label>
              <input type="file" name="valves" {...register("valves", { required: "Il file da importare è obbligatorio" })} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" variant="success" className="waste_btn" style={{'width':'320px'}}><FaUpload /> Importa sostituzioni valvole</Button>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  );
  
}

export default ImportValves;