import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { FaUpload } from 'react-icons/fa';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function ImportJobs() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  // SWEETALERT
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setSuppliers(res.data.suppliers);
      let select_options = [];
      res.data.suppliers.map((sup) => {
        select_options.push({ value: sup.id, label: sup.ragione_sociale });
      });
      setSelectSuppliers(select_options);
    });
  }, []);

  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    setWait(true);

    if (form_data.jobs) {

      const formData = new FormData();
      formData.append('file', form_data.jobs[0]);
      formData.append('type', 'jobs');
      formData.append('supplier_id', (selectedSupplier ? selectedSupplier.value : null));
      console.log(formData);
      axios.post(window.SERVER_URL+'import', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token,
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        // console.log(res.data);
        setWait(false);

        if (res.data[0]) {
          Swal.fire({
            icon: 'success',
            title: 'Dati importati',
            html: res.data[1]
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Errore',
            html: res.data[1]
          });
        }
      }).catch(function(error) {
        setWait(false);
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Si è verificato un errore durante l'importazione dei dati"
        });
      });
    } else {
      setWait(false);

      Swal.fire({
        icon: 'error',
        title: 'File mancante',
        text: "Devi caricare un file con gli ordini di lavoro"
      });
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Importa ordini di lavoro</h1>
          </Col>
        </Row>
        <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>Fornitore</label>
              <Select value={selectedSupplier} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
            </Col>
            <Col style={{margin:'0 20px'}}>
              <label style={{textAlign: 'left'}}>File da importare</label>
              <input type="file" name="jobs" {...register("jobs", { required: "Il file da importare è obbligatorio" })} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" variant="success" className="waste_btn" style={{'width':'320px'}}><FaUpload /> Importa ordini di lavoro</Button>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  );
  
}

export default ImportJobs;