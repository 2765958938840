import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function OperationsAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset, setValue } = useForm();

  const [operation, setOperation] = useState(null);
  const [operationTypes, setOperationTypes] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);

  useEffect(() => {
    // Carico le tipologie di attività
    axios.post(window.SERVER_URL+'operation_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setOperationTypes(res.data.operation_types);

      // Carico le tipologie di entità
      axios.post(window.SERVER_URL+'entity_types/list', { 'is_utente': 0 }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setEntityTypes(res.data);

        // Se sto modificando un'operazione esistente, carico le informazioni relative
        if (state && state.operation_id) {
          axios.post(window.SERVER_URL+'operations/get', { id: state.operation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            setOperation(res.data);

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(res.data))
              if (typeof val !== 'object')
                fields[key] = val;
            reset(fields);
          });
        }
      });
    });    
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'operations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/operations');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica attività</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label>Descrizione</label>
                <input type="text" placeholder="Descrizione" {...register("descrizione")} />
              </Col>
              <Col>
                <label>Categoria attività</label>
                <select {...register("operation_type_id")}>
                  <option value="">- Scegli una categoria -</option>
                  { operationTypes ? operationTypes.map((operation_type) => {
                    return <option key={ operation_type.id } value={ operation_type.id }>{ operation_type.descrizione }</option>
                  }) : '' }
              </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Tipo entità</label>
                <select {...register("entity_type_id")}>
                  <option value="">- Scegli una tipologia entità -</option>
                  { entityTypes ? entityTypes.map((entype) => {
                    return <option key={ entype.id } value={ entype.id }>{ entype.descrizione }</option>
                  }) : '' }
              </select>
              </Col>
              <Col>
                <label>Durata (in minuti)</label>
                <input type="number" min="1" max="9999" step="1" placeholder="Durata in min." {...register("durata")} />
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/operations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );
}

export default OperationsAdd;