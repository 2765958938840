import React from 'react';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { BiPlus } from 'react-icons/bi';

function Dashboard() {
  let navigate = useNavigate();
  
  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Dashboard</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          {/*
          <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/news/add'); }}><BiPlus /> lorem ipsum</Button>
          <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/inspections/add'); }}><BiPlus /> lorem ipsum</Button>
          */}
        </Col>
      </Row>
    </Container>
  );
  
}

export default Dashboard;