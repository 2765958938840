import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash, FaSearch } from 'react-icons/fa';

function StockTypes() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const [measurementUnits, setMeasurementUnits] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);

  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'measurement_units/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setMeasurementUnits(res.data.measurement_units);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (measurement_unit_id) => {
    setIdToDelete(measurement_unit_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questa categoria magazzino?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'measurement_units/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setMeasurementUnits(measurementUnits.filter(unit => unit.id !== idToDelete));
        getPage(1, query);
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }
  
  return (
    <>
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Unità di misura</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/measurement_units/add', {state: {page: page, query: query}}); }}><BiPlus /> Aggiungi unità di misura</Button>
          </Col>
        </Row>
        <Row>
            <Col style={{margin:'0 20px'}}>
              <form className="custom_form" id="query_form">
                <FaSearch />
                <input type="text" placeholder="Cerca unità" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
              </form>
            </Col>
          </Row>
        <Row>
          <Col>
            { measurementUnits ? 
              <>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Sigla</th>
                      <th>Descrizione</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { measurementUnits.length ? measurementUnits.map((measurement_unit) => {
                        return <tr key={ measurement_unit.id }>
                                <td>{ measurement_unit.id }</td>
                                <td>{ measurement_unit.sigla }</td>
                                <td>{ measurement_unit.descrizione }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/measurement_units/add', {state: {measurement_unit_id: measurement_unit.id, page: page, query: query}}) }}><IoIosArrowForward /></Button>
                                  <Button onClick={() => { handleDelete(measurement_unit.id) }} className="delete"><FaTrash /></Button> 
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna anagrafica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default StockTypes;