import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';
import { BsTrash } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';

function SuppliersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [supplier, setSupplier] = useState();
  const [provinces, setProvinces] = useState();
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico le province
    axios.post(window.SERVER_URL+'provinces/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setProvinces(res.data);

      // Recupero la lista dei comuni
      axios.post(window.SERVER_URL+'locations/towns', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTowns(res.data);
        let select_options = [];
        res.data.map((town) => {
          select_options.push({ value: town.id, label: town.nome });
        });
        setSelectTowns(select_options);
      
        // Se sto modificando un fornitore esistente, carico le informazioni relative
        if (state && state.supplier_id) {
          axios.post(window.SERVER_URL+'suppliers/get', { id: state.supplier_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            setSupplier(res.data);

            // Mi setto le select
            if (res.data.town_id)
              setSelectedTown({ value: res.data.town_id, label: res.data.town.nome });
            
            let fields = {};
            for (const [key, val] of Object.entries(res.data))
              if (key !== 'province')
                fields[key] = val;
            reset(fields);

            setWait(false);
          });
        } else {
          setWait(false);
        }
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.town_id = selectedTown ? selectedTown.value : null;
    axios.post(window.SERVER_URL+'suppliers/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }
    }).then(res => {
      Swal.fire({
        icon: 'success',
        title: 'Fornitore registrato',
        confirmButtonText: 'Torna ai fornitori',
        confirmButtonColor: '#198754',
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          navigate('/suppliers');
        }
      });
    }).catch(function(error) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        html: 'Si è verificato un errore durante il salvataggio del fornitore: <i>'+error+'</i>'
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica fornitore</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Codice</label>
                  <input type="text" placeholder="Codice" {...register("codice")} />
                </Col>
                <Col>
                  <label>Nome</label>
                  <input type="text" placeholder="Nome" {...register("nome")} />
                </Col>
                <Col>
                  <label>Cognome</label>
                  <input type="text" placeholder="Cognome" {...register("cognome")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Ragione sociale</label>
                  <input type="text" placeholder="Ragione sociale" {...register("ragione_sociale")} />
                </Col>
                <Col>
                  <label>Partita IVA</label>
                  <input type="text" placeholder="Partita IVA" {...register("partita_iva")} />
                </Col>
                <Col>
                  <label>Codice fiscale</label>
                  <input type="text" placeholder="Codice fiscale" {...register("codice_fiscale")} />
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <label>Indirizzo</label>
                  <input type="text" placeholder="Indirizzo" {...register("indirizzo")} />
                </Col>
                <Col lg={4}>
                  <label style={{'marginTop':'15px'}}>Comune</label>
                  <Select value={selectedTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="town_id" options={selectTowns} onChange={(e) => setSelectedTown(e)} /> 
                </Col>
              </Row>
              <Row>
                <Col>
                  <input type="text" placeholder="CAP" {...register("cap")} />
                </Col>
                <Col>
                <select {...register("province_id")}>
                  <option value="">- Scegli una provincia -</option>
                  { provinces ? provinces.map((prov) => {
                    return <option key={ prov.id } value={ prov.id }>{ prov.descrizione }</option>
                  }) : '' }
                </select>
                </Col>
                <Col>
                  <input type="text" placeholder="Nazione" {...register("stato")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Telefono</label>
                  <input type="text" placeholder="Telefono" {...register("telefono")} />
                </Col>
                <Col>
                  <label>Cellulare</label>
                  <input type="text" placeholder="Cellulare" {...register("cellulare")} />
                </Col>
                <Col>
                  <label>Indirizzo e-mail</label>
                  <input type="text" placeholder="Indirizzo e-mail" {...register("email")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Indirizzo PEC</label>
                  <input type="text" placeholder="Indirizzo PEC" {...register("pec")} />
                </Col>
                <Col>
                  <label>Codice destinatario</label>
                  <input type="text" placeholder="Codice destinatario" {...register("sdi")} />
                </Col>
              </Row>
              
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/suppliers', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
          </Col>
        </Row>
      </Container>
    </>
  );

}

export default SuppliersAdd;