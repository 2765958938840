import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function DeleteActivityOperations() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [operationStatuses, setOperationStatuses] = useState([]);
  const [showDates, setShowDates] = useState(false);
  const [operationTypes, setOperationTypes] = useState([]);
  const [selectOperationTypes, setSelectOperationTypes] = useState([]);
  const [selectedOperationType, setSelectedOperationType] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico i comuni
    axios.post(window.SERVER_URL+'locations/towns', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setTowns(res.data);
      let select_options = [];
      res.data.map((town) => {
        select_options.push({ value: town.id, label: town.nome });
      });
      setSelectTowns(select_options);    

      // Carico i fornitori
      axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setSuppliers(res.data.suppliers);
        let select_options = [];
        res.data.suppliers.map((sup) => {
          select_options.push({ value: sup.id, label: sup.ragione_sociale });
        });
        setSelectSuppliers(select_options);

        // Carico le tipologie di operazione
        axios.post(window.SERVER_URL+'operations/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setOperationTypes(res.data.operations);
          let select_options = [];
          res.data.operations.map((type) => {
            select_options.push({ value: type.id, label: type.descrizione });
          });
          setSelectOperationTypes(select_options);

          // Carico gli stati delle attività
          axios.post(window.SERVER_URL+'operation_statuses/list', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setOperationStatuses(res.data.operation_statuses);

            setWait(false);
          });    
        });    
      });    
    });
  }, []);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    Swal.fire({
      icon: 'question',
      title: 'Vuoi davvero cancellare le attività?',
      text: "L'operazione non è reversibile",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Procedi',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        setWait(true);
        form_data.town_id = selectedTown ? selectedTown.value : null;
        form_data.supplier_id = selectedSupplier ? selectedSupplier.value : null;
        form_data.operation_type_id = selectedOperationType ? selectedOperationType.value : null;
        if (!form_data.operation_status_id) {
          delete form_data.data_da;
          delete form_data.data_a;
        }
        axios.post(window.SERVER_URL+'activity_operations/delete', form_data, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            // 'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          setWait(false);
          console.log(res.data);
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Cancellazione completata',
              html: 'Sono state cancellate <b>'+res.data.count+'</b> attività'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione',
              text: 'Si è verificato un errore durante la cancellazione delle attività'
            });
          }
        }).catch(function(error) {
          console.log(error);
        });
      }
    });
  }

  // Cancellazione interventi senza attività
  const deleteEmptyActivities = () => {
    Swal.fire({
      icon: 'question',
      title: 'Vuoi davvero cancellare gli interventi senza attività?',
      text: "L'operazione non è reversibile",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Procedi',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        setWait(true);
        axios.post(window.SERVER_URL+'activities/delete_empty', {}, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            // 'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          setWait(false);
          console.log(res.data);
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Cancellazione completata',
              html: 'Sono stati cancellati <b>'+res.data.count+'</b> interventi'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione',
              text: 'Si è verificato un errore durante la cancellazione degli interventi'
            });
          }
        }).catch(function(error) {
          console.log(error);
        });
      }
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Cancella attività</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label style={{textAlign:'left'}}>Comune</label>  
                  <Select value={selectedTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="town" options={selectTowns} onChange={(e) => setSelectedTown(e)} />
                </Col>
                <Col style={{margin:'0 20px'}}>
                  <label style={{textAlign:'left'}}>Fornitori</label>
                  <Select value={selectedSupplier} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <label>Descrizione</label>
                  <input type="text" placeholder="Descrizione" {...register("descrizione")} />
                </Col>
                <Col>
                  <label>Data collaudo</label>
                  <input type="date" placeholder="Data collaudo" {...register("data_collaudo")} />
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <label style={{'marginTop':'15px'}}>Tipo attività</label>
                  <Select value={selectedOperationType} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="operation_type_id" options={selectOperationTypes} onChange={(e) => setSelectedOperationType(e)} />
                </Col>
                <Col>
                  <label>Stato</label>
                  <select {...register("operation_status_id")} onChange={(e) => setShowDates(e.target.value ? true : false)}>
                    <option value="">- Tutti gli stati -</option>
                    { operationStatuses ? operationStatuses.map((operation_stasus) => {
                      return <option key={ operation_stasus.id } value={ operation_stasus.id }>{ operation_stasus.descrizione }</option>
                    }) : '' }
                  </select>
                </Col>
                <Col>
                  <label>Stato attuale</label>
                  <select {...register("stato_attuale")}>
                    <option value="1">Sì</option>
                    <option value="0">No</option>
                  </select>
                </Col>
              </Row>
              { showDates ? 
                <Row>
                  <Col>
                    <label>Data (da)</label>
                    <input type="date" placeholder="Data (da)" {...register("data_da")} />
                  </Col>
                  <Col>
                    <label>Data (a)</label>
                    <input type="date" placeholder="Data (a)" {...register("data_a")} />
                  </Col>
                </Row>
              : '' }
              <Button type="submit" variant="primary" className="waste_btn">Cancella attività</Button>
            </form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col></Col>
          <Col style={{'textAlign':'center'}}>
            <h2>Usa il tasto qui sotto se vuoi cancellare<br />tutti gli interventi che non hanno alcuna attività</h2>
            <Button variant="primary" className="waste_btn" onClick={() => deleteEmptyActivities()}>Cancella interventi senza attività</Button>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}

export default DeleteActivityOperations;