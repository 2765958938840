import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// PARTIALS
import Map from '../partials/map';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";

function ActivitiesAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue, formState: { errors }  } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [activity, setActivity] = useState(null);
  const [activityOperations, setActivityOperations] = useState([]);
  const [activityPriorities, setActivityPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  // GOOGLE MAP
  const [path, setPath] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 42.9705321, lng: 13.7150639 });
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [mapKey, setMapKey] = useState(0);
  const [checks, setChecks] = useState([]);
  const [filteredChecks, setFilteredChecks] = useState([]);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);

    // Carico le priorità
    axios.post(window.SERVER_URL+'activity_priorities/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setActivityPriorities(res.data.activity_priorities);

      // Carico le ubicazioni
      axios.post(window.SERVER_URL+'locations/list', { 'params': { 'light': true } }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setLocations(res.data.locations);
        let select_options = [];
        res.data.locations.map((loc) => {
          select_options.push({ value: loc.id, label: loc.denominazione });
        });
        setSelectLocations(select_options);

        // Se sto modificando un intervento esistente, carico le informazioni relative
        if (state && state.activity_id) {
          setWait(true);
          axios.post(window.SERVER_URL+'activities/get', { id: state.activity_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            setActivity(res.data);
            setActivityOperations(res.data.activity_operations);

            // Mi setto le select
            if (res.data.location_id)
              setSelectedLocation({ value: res.data.location_id, label: res.data.location.denominazione });

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(res.data))
              if (typeof val !== 'object')
                fields[key] = val;
            reset(fields);

            // Setto la mappa
            let tmp_checks = [];
            tmp_checks.push({
              id: null, 
              lat: res.data.location.lat, 
              lng: res.data.location.lng, 
              name: 'INTERVENTO', 
              address: res.data.location.denominazione+' '+res.data.location.indirizzo, 
              priority: null,
              num_operations: null,
              time_req: null,
              op_ids: null,
              activity_operations: null,
            });
            setChecks(tmp_checks);
            setCenter({ lat: res.data.location.lat, lng: res.data.location.lng });
            render();

            setWait(false);
          });
        } else {
          setWait(false);
        }
      });
    });
  }, [reset, state.activity_id]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.user_id = authobj.user_id;
    form_data.location_id = selectedLocation ? selectedLocation.value : null;
    axios.post(window.SERVER_URL+'activities/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      if (res.data.ret.success) {
        navigate('/activities/add', {state: { activity_id: res.data.ret.activity_id }});
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Attenzione',
          text: 'Si è verificato un errore durante il salvataggio'
        });
      }
    }).catch(function(error) {
      console.log(error);
    });
  }

  const handleDelete = (operation_id) => {
    setIdToDelete(operation_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questa operazione?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'activity_operations/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setActivityOperations(activityOperations.filter(operation => operation.id !== idToDelete));
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return <Map key={mapKey} center={center} zoom={zoom} startPoint={startPoint} endPoint={endPoint} distKm={0} width={'100%'} height={'300px'} checks={checks} noControls={true} detailPath={false} fitBounds={false} />;
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica intervento</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={ state && state.activity_id ? 8 : 12 } className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Ubicazione</label>
                  <Select value={selectedLocation} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="location_id" options={selectLocations} onChange={(e) => setSelectedLocation(e)} />
                  <Row className="action_bar" style={{'marginTop':'20px'}}>
                    <Col>
                      <Button onClick={() => navigate('/locations/add', { state: { location_id: activity.location.id } }) } className="back"><DynamicIcon iconName="FaMapMarkerAlt" /> Vai all'ubicazione</Button>
                      <Button onClick={() => navigate('/customers/add', { state: { customer_id: activity.location.customer.id } }) } className="back" style={{'marginLeft':'20px'}}><DynamicIcon iconName="FaIndustry" /> Vai al cliente</Button>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <label>Priorità</label>
                  <select {...register("activity_priority_id", { required: true })}>
                    <option value="">- Scegli una priorità -</option>
                    { activityPriorities ? activityPriorities.map((activity_priority) => {
                      return <option key={ activity_priority.id } value={ activity_priority.id }>{ activity_priority.descrizione }</option>
                    }) : '' }
                  </select>
                  {errors.activity_priority_id && <span>Devi scegliere una priorità</span>}
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <label>Codice</label>
                  <input type="text" placeholder="Cod. attività" {...register("codice_attivita")} />
                </Col>
                <Col>
                  <label>Data prevista</label>
                  <input type="date" placeholder="Data prevista" {...register("data_preventivata")} />
                </Col>
                <Col>
                  <label>Data effettuazione</label>
                  <input type="date" placeholder="Data effettuazione" {...register("data_effettuazione")} />
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <label>Note</label>
                  <textarea placeholder="Note" {...register("note")}></textarea>
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
          { state && state.activity_id ?
            <Col lg={4} style={{'padding':'20px 40px 20px 0'}}>
              <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
            </Col>
          : '' }
        </Row>
        { state && state.activity_id ?
          <>
            <hr />
            <Row className='boxed'>
              <Col>
                <h2>Attività da effettuare</h2>
              </Col>
            </Row>
            <Row className="action_bar top" style={{'padding':'0 35px'}}>
              <Col>
                <Button variant="success" onClick={() => { navigate('/activity_operations/add', {state: { activity_id: state.activity_id }}); }}><DynamicIcon iconName="BiPlus" /> Aggiungi attività</Button>
              </Col>
            </Row>
            <Row className="boxed">
              { activityOperations.length ? 
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th style={{'width':'80px'}}>ID</th>
                      <th>Tipologia</th>
                      <th>Oggetto</th>
                      <th>Matricola</th>
                      <th>Descrizione</th>
                      <th>Stato</th>
                      <th style={{'width':'170px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { activityOperations.map((operation) => {
                        return <tr key={ operation.id }>
                                <td>{ operation.id }</td>
                                <td>{ operation.operation.descrizione }</td>
                                <td>{ operation.entity ? operation.entity.etichetta : '' }</td>
                                <td>{ operation.entity_id ? operation.entity.matricola : operation.matricola }</td>
                                <td>{ operation.descrizione }</td>
                                <td className="op_status">
                                  <span className="square" style={{'background':operation.operation_status.colore}}></span>
                                  <span style={{'color':operation.operation_status.colore}}>{ operation.operation_status.descrizione }</span>
                                </td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/activity_operations/add', {state: { activity_id: activity.id, activity_operation_id: operation.id }}) }} title="Vai al dettaglio"><DynamicIcon iconName="IoIosArrowForward" /></Button>
                                  <Button onClick={() => { navigate('/'+operation.entity.modello_plurale+'/add', {state: { [operation.entity.modello_singolare+'_id']: operation.entity.id }}) }} title={"Vai al "+operation.entity.etichetta}>
                                    <DynamicIcon iconName={window.ENTITY_ICONS[operation.entity.modello_singolare]} />
                                  </Button>
                                  <Button onClick={() => { handleDelete(operation.id) }} className="delete"><DynamicIcon iconName="FaTrash" /></Button>
                                </td>
                              </tr>
                      }) }
                  </tbody>
                </Table>
              : <p style={{'margin':'0 20px'}}>Non sono presenti operazioni per questa attività</p> }
            </Row>
            <br /><hr />
          </>
        : '' } 
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate(-1) } className="back"><DynamicIcon iconName="RiArrowGoBackFill" /> Torna indietro</Button>
            <Button onClick={() => navigate('/activities', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName="IoIosArrowBack" /> Torna agli interventi</Button>
          </Col>
        </Row>
      </Container>
    </>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default ActivitiesAdd;