import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// MODAL
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';

function ExtinguishersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // DATA
  const [extinguisher, setExtinguisher] = useState(null);
  const [extinguisherTypes, setExtinguisherTypes] = useState([]);
  const [extinguisherActivities, setExtinguisherActivities] = useState([]);
  const [makers, setMakers] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [selectTanks, setSelectTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);
    // Carico le tipologie estintori
    axios.post(window.SERVER_URL+'extinguisher_types/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setExtinguisherTypes(res.data.extinguisher_types);

      // Carico i costruttori
      axios.post(window.SERVER_URL+'makers/list', { 'params': { 'light': true } }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setMakers(res.data.makers);

        // Carico i serbatoi
        axios.post(window.SERVER_URL+'tanks/list', { 'params': { 'light': true } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTanks(res.data.tanks);
          let select_options = [];
          res.data.tanks.map((tank) => {
            select_options.push({ value: tank.id, label: tank.matricola });
          });
          setSelectTanks(select_options);

          // Se sto modificando un estintore esistente, carico le informazioni relative
          if (state && state.extinguisher_id) {
            axios.post(window.SERVER_URL+'extinguishers/get', { id: state.extinguisher_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setExtinguisher(res.data);
              setExtinguisherActivities(res.data.extinguisher_activities);

              // Mi setto le select
              if (res.data.tank_id)
                setSelectedTank({ value: res.data.tank_id, label: res.data.tank.matricola });

              setWait(false);

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                if (typeof val !== 'object')
                  fields[key] = val;
              reset(fields);
            });
          }

          if (!state || !state.extinguisher_id)
              setWait(false);
        });
      });    
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.tank_id = selectedTank ? selectedTank.value : null;
    delete form_data.scadenza;
    axios.post(window.SERVER_URL+'extinguishers/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/extinguishers');
    }).catch(function(error) {
      console.log(error);
    });
  }

  const handleDelete = (activity_id) => {
    setIdToDelete(activity_id);
    setModalOptions({
      'title': 'ATTENZIONE',
      'text': 'Sei sicuro di voler cancellare questa attività?',
      'closeTxt': 'ANNULLA',
      'confirmTxt': 'ELIMINA',
    });
    setShowDeleteConfirm(true);
  }

  const doDelete = () => {
    axios.post(window.SERVER_URL+'extinguisher_activities/delete', { id: idToDelete }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setExtinguisherActivities(extinguisherActivities.filter(activity => activity.id !== idToDelete));
        setShowDeleteConfirm(false);
      } else {
        setModalOptions({
          'title': 'ERRORE',
          'text': 'Si è verificato un errore durante la cancellazione',
          'closeTxt': 'CHIUDI',
          'confirmTxt': null
        });
        setShowDeleteConfirm(true);
      }
    });
  }

  return (
    <>
      <ModalConfirm show={showDeleteConfirm} handleClose={setShowDeleteConfirm} handleConfirm={doDelete} modalOptions={modalOptions} />
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica estintore</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Matricola</label>
                  <input type="text" placeholder="Matricola" {...register("matricola")} />
                </Col>
                <Col>
                  <label>Tipologia</label>
                  <select {...register("extinguisher_type_id")}>
                    <option value="">- Scegli una tipologia -</option>
                    { extinguisherTypes ? extinguisherTypes.map((extinguisher_type) => {
                      return <option key={ extinguisher_type.id } value={ extinguisher_type.id }>{ extinguisher_type.descrizione }</option>
                    }) : '' }
                </select>
                </Col>
                <Col>
                  <label>Costruttore</label>
                  <select {...register("maker_id")}>
                    <option value="">- Scegli un costruttore -</option>
                    { makers ? makers.map((maker) => {
                      return <option key={ maker.id } value={ maker.id }>{ maker.ragione_sociale }</option>
                    }) : '' }
                </select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Peso (kg)</label>
                  <input type="text" placeholder="Peso (kg)" {...register("peso")} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Serbatoio</label>  
                  <Select value={selectedTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tank_id" options={selectTanks} onChange={(e) => setSelectedTank(e)} />
                </Col>
                <Col>
                  <label>Descrizione</label>
                  <input type="text" placeholder="Descrizione" {...register("descrizione")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Data costruzione</label>
                  <input type="date" placeholder="Data costruzione" {...register("data_costruzione")} />
                </Col>
                <Col>
                  <label>Data installazione</label>
                  <input type="date" placeholder="Data installazione" {...register("data_installazione")} />
                </Col>
                <Col>
                  <label>Data scadenza</label>
                  <input type="date" placeholder="Data scadenza" {...register("data_scadenza")} />
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        { extinguisher && false ? 
                                <>
                                  <hr />
                                  <Row className='boxed'>
                                    <Col>
                                      <h2>Attività effettuate</h2>
                                    </Col>
                                  </Row>
                                  <Row className="action_bar top" style={{'padding':'0 35px'}}>
                                    <Col>
                                      <Button variant="success" onClick={() => { navigate('/extinguisher_activities/add', { state: { extinguisher_id: extinguisher.id, selected_extinguisher: { value: extinguisher.id, label: '['+extinguisher.matricola+'] '+extinguisher.extinguisher_type.descrizione+' ('+extinguisher.peso+' kg)' } } }); }}><BiPlus /> Aggiungi attività</Button>
                                    </Col>
                                  </Row>
                                  <Row className='boxed'>
                                    <Col>
                                      { extinguisherActivities ?
                                        <Table striped responsive>
                                          <thead>
                                            <tr>
                                              <th>ID</th>
                                              <th>Tipologia</th>
                                              <th>Data effettuazione</th>
                                              <th>Note</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            { extinguisherActivities.map((act) => {
                                              return <tr key={ act.id }>
                                                      <td>{ act.id }</td>
                                                      <td>{ act.extinguisher_activity_type.descrizione }</td>
                                                      <td>{ act.data_effettuazione }</td>
                                                      <td>{ act.note }</td>
                                                      <td className="actions">
                                                        <Button onClick={() => { navigate('/extinguisher_activities/add', {state: { activity_id: act.id, extinguisher_id: extinguisher.id, selected_extinguisher: { value: extinguisher.id, label: '['+extinguisher.matricola+'] '+extinguisher.extinguisher_type.descrizione+' ('+extinguisher.peso+' kg)' } }}) }}><IoIosArrowForward /></Button>
                                                        <Button onClick={() => { handleDelete(act.id) }} className="delete"><FaTrash /></Button>
                                                      </td>
                                                    </tr>
                                            }) }
                                          </tbody>
                                        </Table>
                                      : <p className='boxed'>Nessuna attività registrata</p> }
                                    </Col>
                                  </Row>
                                  <br /><hr />
                                </>
        : '' }
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate(-1) } className="back"><DynamicIcon iconName="RiArrowGoBackFill" /> Torna indietro</Button>
            <Button onClick={() => navigate('/extinguishers', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna agli estintori</Button>
          </Col>
        </Row>
      </Container>
    </>
  );

  
}

export default ExtinguishersAdd;