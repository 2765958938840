import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { Link, useNavigate } from "react-router-dom";
// AXIOS
import axios from 'axios';
// CONFIG
// import conf from "../../config.json";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// IMAGES
import logo_ophy from '../../gfx/ophygas.svg';
// ICONS
import { BsBell, BsSearch, BsGearFill, BsSpeedometer, BsBank } from 'react-icons/bs';
import { GiHamburgerMenu, GiFuelTank, GiValve, GiTeePipe, GiThermometerScale } from 'react-icons/gi';
import { ImStatsDots, ImExit, ImUsers } from 'react-icons/im';
import { FaUserCircle, FaDatabase, FaRoute, FaIndustry, FaSuitcase, FaList, FaMapMarkerAlt, FaFolderOpen, FaFireExtinguisher, FaWarehouse, FaBoxOpen, FaExchangeAlt, FaCashRegister, FaPercentage, FaFileImport } from 'react-icons/fa';
import { TbDatabaseImport, TbReceiptTax } from 'react-icons/tb';
import { GoTasklist } from 'react-icons/go';
import { IoMdArrowDropdown, IoMdBuild, IoMdSettings } from 'react-icons/io';
import { GrDocumentText, GrMoney } from 'react-icons/gr';
import { AiFillSafetyCertificate } from 'react-icons/ai';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function toggleMenu() {
  document.getElementById('menu').classList.toggle('active');
}

function closeMenu() {
  document.getElementById('menu').classList.remove('active');
}

function openSubNav(e) {
  e.preventDefault();
  let submenu = e.target.parentNode.querySelector('ul');
  if (submenu)
    submenu.classList.toggle('visible');
  return false;
}

function Header() {
  const { authobj, setPlantID, plantID, setPlantName, lingue, lang, setLanguage, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();

  useEffect(() => {
    closeMenu();
    setGlobalRefresh(null);
    // axios.post(window.SERVER_URL+'helps/unread', { account_id: authobj.user_id, plant_id: plantID }, { headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': 'Bearer '+authobj.token
    // }}).then(res => {
    //   console.log(res.data);
    // }).catch(function(error) {
    //   console.log(error);
    // });
  }, [globalRefresh]);

  return (
    <>
      <Container id='header'>
        <Row>
          <Col>
            <img alt="Ophygas" id='logo' src={logo_ophy} onClick={() => { setGlobalRefresh(1); navigate('/dashboard') }} />
          </Col>
          <Col id='headicons_wrapper'>
            <div className='headicon'><GiHamburgerMenu onClick={toggleMenu} /></div>
            {/* <div className='headicon'><BsBell onClick={() => { authobj.group_id > 2 ? navigate('/helps') : navigate('/admin/helps') }} /><span>0</span></div> */}
          </Col>
        </Row>
      </Container>
      <Container id='menu'>
        <Row>
          <div className="sx">
            <GiHamburgerMenu onClick={toggleMenu} />
          </div>
          <div className="dx">
            <h2>MENÙ</h2>
            <div id="info_connected">
              <div>
                <FaUserCircle />
                <div>
                  Sei collegato come:<br />
                  <b>{ authobj.name }</b>
                  {/* <br/><span className="bottom">lorem ipsum: { authobj.group_name }</span> */}
                </div>
              </div>
            </div>
            <nav>
              <ul>
                <li><Link to="/dashboard" onClick={closeMenu}><ImStatsDots /> Dashboard</Link></li>
                <li><Link to="#" onClick={openSubNav}><FaDatabase /> Anagrafiche <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/customers" onClick={closeMenu}><FaIndustry /> Clienti</Link></li>
                    <li><Link to="/suppliers" onClick={closeMenu}><FaSuitcase /> Fornitori</Link></li>
                    <li><Link to="/makers" onClick={closeMenu}><BsGearFill /> Costruttori</Link></li>
                    <li><Link to="/locations" onClick={closeMenu}><FaMapMarkerAlt /> Ubicazioni</Link></li>
                    <li><Link to="/banks" onClick={closeMenu}><BsBank /> Banche</Link></li>
                  </ul>
                </li>
                {/* <li><Link to="#" onClick={openSubNav}><FaMapMarkerAlt /> Ubicazioni e utenze <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    
                    <li><Link to="#" onClick={closeMenu}><IoMdBuild /> Utenze</Link></li>
                  </ul>
                </li> */}
                <li><Link to="#" onClick={openSubNav}><GiFuelTank style={{'fontSize':'28px','marginTop':'-10px'}} /> Serbatoi <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/tanks" onClick={closeMenu}><GiFuelTank style={{'fontSize':'28px','marginTop':'-10px'}} /> Gestisci serbatoi</Link></li>
                    <li><Link to="/tank_types" onClick={closeMenu}><FaList /> Tipologie serbatoi</Link></li>
                    {/* <li><Link to="#" onClick={closeMenu}><AiFillSafetyCertificate /> Certificazioni tubazioni</Link></li> */}
                    {/* <li><Link to="#" onClick={closeMenu}><GiTeePipe /> Tipologie impianti</Link></li> */}
                    <li><Link to="/regulations" onClick={closeMenu}><GrDocumentText /> Normative</Link></li>
                    <li><Link to="/regulation_descriptions" onClick={closeMenu}><GrDocumentText /> Descrizioni normative</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><GiValve /> Valvole <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/valves" onClick={closeMenu}><GiValve /> Gestisci valvole</Link></li>
                    <li><Link to="/valve_types" onClick={closeMenu}><FaList /> Tipologie valvole</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><BsSpeedometer /> Contatori <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/meters" onClick={closeMenu}><BsSpeedometer /> Gestisci contatori</Link></li>
                    <li><Link to="/meter_types" onClick={closeMenu}><FaList /> Tipologie contatori</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><FaFireExtinguisher /> Estintori <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/extinguishers" onClick={closeMenu}><FaFireExtinguisher /> Gestisci estintori</Link></li>
                    <li><Link to="/extinguisher_types" onClick={closeMenu}><FaList /> Tipologie estintori</Link></li>
                    {/* <li><Link to="/extinguisher_activities/add" onClick={closeMenu}><MdTaskAlt /> Registra attività</Link></li> */}
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><GoTasklist /> Attività <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/activities" onClick={closeMenu}><GoTasklist /> Gestisci interventi</Link></li>
                    <li><Link to="/plan" onClick={closeMenu}><FaRoute /> Pianifica interventi</Link></li>
                    <li><Link to="/paths" onClick={closeMenu}><FaRoute /> Gestisci pianificazioni</Link></li>
                    {/* <li><Link to="#" onClick={closeMenu}><TbDatabaseImport /> Importa attività</Link></li> */}
                    <li><Link to="/operations" onClick={closeMenu}><FaList /> Tipologie attività</Link></li>
                    <li><Link to="/operation_types" onClick={closeMenu}><FaFolderOpen /> Categorie attività</Link></li>
                    <li><Link to="/delete_activity_operations" onClick={closeMenu}><DynamicIcon iconName="MdDeleteSweep" /> Cancella attività</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><FaWarehouse /> Magazzino <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/stocks" onClick={closeMenu}><FaBoxOpen /> Materiali</Link></li>
                    <li><Link to="/stock_types" onClick={closeMenu}><FaFolderOpen /> Categorie magazzino</Link></li>
                    <li><Link to="/measurement_units" onClick={closeMenu}><GiThermometerScale /> Unità di misura</Link></li>
                    <li><Link to="/stock_handlings" onClick={closeMenu}><FaExchangeAlt /> Gestisci movimenti</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><FaCashRegister /> Amministrazione <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/delivery_notes" onClick={closeMenu}><DynamicIcon iconName="GrNotes" /> Bolle</Link></li>
                    <li><Link to="/tax_rates" onClick={closeMenu}><TbReceiptTax /> Aliquote IVA</Link></li>
                    <li><Link to="/operation_suppliers" onClick={closeMenu}><GrMoney /> Prezzi attività</Link></li>
                  </ul>
                </li>
                <li><Link to="#" onClick={openSubNav}><FaFileImport /> Import <IoMdArrowDropdown className="last" /></Link>
                  <ul>
                    <li><Link to="/import_customers" onClick={closeMenu}><FaIndustry /> Allineamento clienti</Link></li>
                    <li><Link to="/import_activities" onClick={closeMenu}><FaFileImport /> Importa attività da template</Link></li>
                    {/* <li><Link to="/import_meters" onClick={closeMenu}><BsSpeedometer /> Letture contatori</Link></li> */}
                    <li><Link to="/import_jobs" onClick={closeMenu}><GoTasklist /> Ordini di lavoro da SAP</Link></li>
                    {/* <li><Link to="/import_valves" onClick={closeMenu}><GiValve /> Sostituzione valvole</Link></li> */}
                  </ul>
                </li>
                <li><Link to="/users" onClick={closeMenu}><ImUsers /> Utenti</Link></li>
                {/* <li><Link to="#" onClick={openSubNav}><IoMdSettings /> Configurazione <IoMdArrowDropdown className="last" /></Link>
                  <ul></ul>
                </li> */}
                <li><Link to="#" className="logout" onClick={signout}><ImExit /> Logout</Link></li>
              </ul>
            </nav>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Header;