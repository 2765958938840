import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function DeliveryNotesAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [deliveryNote, setDeliveryNote] = useState();
  const [carriers, setCarriers] = useState([]);
  const [selectCarriers, setSelectCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [appearances, setAppearances] = useState([]);
  const [selectAppearances, setSelectAppearances] = useState([]);
  const [selectedAppearance, setSelectedAppearance] = useState(null);
  const [transportations, setTransportations] = useState([]);
  const [selectTransportations, setSelectTransportations] = useState([]);
  const [selectedTransportation, setSelectedTransportation] = useState(null);
  const [motives, setMotives] = useState([]);
  const [selectMotives, setSelectMotives] = useState([]);
  const [selectedMotive, setSelectedMotive] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [tanks, setTanks] = useState([]);
  const [selectTanks, setSelectTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [customerTanks, setCustomerTanks] = useState([]);
  const [selectCustomerTanks, setSelectCustomerTanks] = useState([]);
  const [selectedCustomerTank, setSelectedCustomerTank] = useState(null);
  const [meters, setMeters] = useState([]);
  const [selectMeters, setSelectMeters] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [valves, setValves] = useState([]);
  const [selectValves, setSelectValves] = useState([]);
  const [selectedValve, setSelectedValve] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [selectStocks, setSelectStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [extinguishers, setExtinguishers] = useState([]);
  const [selectExtinguishers, setSelectExtinguishers] = useState([]);
  const [selectedExtinguisher, setSelectedExtinguisher] = useState(null);
  const [selectedCustom, setSelectedCustom] = useState(null);
  const [selectedStockQuantity, setSelectedStockQuantity] = useState(0);
  const [selectedCustomQuantity, setSelectedCustomQuantity] = useState(0);
  const [accSelected, setAccSelected] = useState('tank');
  const [materials, setMaterials] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);

    // Carico i vettori per il trasporto
    axios.post(window.SERVER_URL+'delivery_note_carriers/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setCarriers(res.data.delivery_note_carriers);
      let select_options = [];
      res.data.delivery_note_carriers.map((carrier) => {
        select_options.push({ value: carrier.id, label: carrier.descrizione });
      });
      setSelectCarriers(select_options);

      // Carico gli aspetti dei beni
      axios.post(window.SERVER_URL+'delivery_note_appearances/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setAppearances(res.data.delivery_note_appearances);
        let select_options = [];
        res.data.delivery_note_appearances.map((app) => {
          select_options.push({ value: app.id, label: app.descrizione });
        });
        setSelectAppearances(select_options);

        // Carico i trasporti a cura
        axios.post(window.SERVER_URL+'delivery_note_transportations/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTransportations(res.data.delivery_note_transportations);
          let select_options = [];
          res.data.delivery_note_transportations.map((trans) => {
            select_options.push({ value: trans.id, label: trans.descrizione });
          });
          setSelectTransportations(select_options);

          // Carico le causali
          axios.post(window.SERVER_URL+'delivery_note_motives/list', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setMotives(res.data.delivery_note_motives);
            let select_options = [];
            res.data.delivery_note_motives.map((mot) => {
              select_options.push({ value: mot.id, label: mot.descrizione });
            });
            setSelectMotives(select_options);

            // Carico i fornitori
            axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setSuppliers(res.data.suppliers);
              let select_options = [];
              res.data.suppliers.map((sup) => {
                select_options.push({ value: sup.id, label: sup.ragione_sociale });
              });
              setSelectSuppliers(select_options);

              // Carico i clienti
              axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data.customers);
                setCustomers(res.data.customers);
                let select_options = [];
                res.data.customers.map((cust) => {
                  select_options.push({ value: cust.id, label: cust.etichetta });
                });
                setSelectCustomers(select_options);

                // Carico i serbatoi
                axios.post(window.SERVER_URL+'tanks/list', { params: { 'light': true } }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  setTanks(res.data.tanks);
                  let select_options = [];
                  res.data.tanks.map((tank) => {
                    select_options.push({ value: tank.id, label: tank.matricola });
                  });
                  setSelectTanks(select_options);

                  // Carico gli estintori
                  axios.post(window.SERVER_URL+'extinguishers/list', { params: { 'light': true } }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }}).then(res => {
                    setExtinguishers(res.data.extinguishers);
                    let select_options = [];
                    res.data.extinguishers.map((ext) => {
                      select_options.push({ value: ext.id, label: ext.matricola });
                    });
                    setSelectExtinguishers(select_options);

                    // Carico i contatori
                    axios.post(window.SERVER_URL+'meters/list', { params: { 'light': true } }, { headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }}).then(res => {
                      setMeters(res.data.meters);
                      let select_options = [];
                      res.data.meters.map((meter) => {
                        select_options.push({ value: meter.id, label: meter.matricola });
                      });
                      setSelectMeters(select_options);

                      // Carico le valvole
                      axios.post(window.SERVER_URL+'valves/list', { params: { 'light': true } }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(res => {
                        setValves(res.data.valves);
                        let select_options = [];
                        res.data.valves.map((valve) => {
                          select_options.push({ value: valve.id, label: valve.matricola });
                        });
                        setSelectValves(select_options);

                        // Carico il magazzino
                        axios.post(window.SERVER_URL+'stocks/list', { params: { 'light': true } }, { headers: {
                          'Access-Control-Allow-Origin': '*',
                          'Authorization': 'Bearer '+authobj.token
                        }}).then(res => {
                          setStocks(res.data.stocks);
                          let select_options = [];
                          res.data.stocks.map((stock) => {
                            select_options.push({ value: stock.id, label: stock.descrizione+' - Tipologia: '+stock.stock_type.descrizione+' - Unità di misura: '+stock.measurement_unit.descrizione });
                          });
                          setSelectStocks(select_options);

                          // Se sto modificando una bolla esistente, carico le informazioni relative
                          if (state && state.delivery_note_id) {
                              axios.post(window.SERVER_URL+'delivery_notes/get', { id: state.delivery_note_id }, { headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': 'Bearer '+authobj.token
                              }}).then(res => {
                                console.log(res.data);
                                setDeliveryNote(res.data);

                                setMaterials(res.data.materiali);

                                // Carico i serbatoi del cliente
                                axios.post(window.SERVER_URL+'tanks/list_by_customer', { customer_id: res.data.customer_id }, { headers: {
                                  'Access-Control-Allow-Origin': '*',
                                  'Authorization': 'Bearer '+authobj.token
                                }}).then(res_ctanks => {
                                  setCustomerTanks(res_ctanks.data);
                                  let select_options = [];
                                  res_ctanks.data.map((tank) => {
                                    select_options.push({ value: tank.id, label: tank.matricola });
                                  });
                                  setSelectCustomerTanks(select_options);

                                  // Mi setto le select
                                  if (res.data.delivery_note_carrier_id)
                                    setSelectedCarrier({ value: res.data.delivery_note_carrier_id, label: res.data.delivery_note_carrier.descrizione });
                                  if (res.data.delivery_note_appearance_id)
                                    setSelectedAppearance({ value: res.data.delivery_note_appearance_id, label: res.data.delivery_note_appearance.descrizione });
                                  if (res.data.delivery_note_transportation_id)
                                    setSelectedTransportation({ value: res.data.delivery_note_transportation_id, label: res.data.delivery_note_transportation.descrizione });
                                  if (res.data.delivery_note_motive_id)
                                    setSelectedMotive({ value: res.data.delivery_note_motive_id, label: res.data.delivery_note_motive.descrizione });
                                  if (res.data.supplier_id)
                                    setSelectedSupplier({ value: res.data.supplier_id, label: res.data.supplier.ragione_sociale });
                                  if (res.data.customer_id)
                                    setSelectedCustomer({ value: res.data.customer_id, label: res.data.customer.etichetta });
                                  if (res.data.tank_id)
                                    setSelectedCustomerTank({ value: res.data.tank_id, label: res.data.tank.matricola });

                                  // Setto i campi della form
                                  let fields = {};
                                  for (const [key, val] of Object.entries(res.data))
                                    if (typeof val !== 'object')
                                      fields[key] = val;
                                  fields['giorno'] = fields['data_trasporto'].slice(0, 10);
                                  reset(fields);

                                  setWait(false);
                                });
                              });
                          } else {
                            // Recupero il numero ipotetico della nuova bolla
                            axios.post(window.SERVER_URL+'delivery_notes/get_next_id', { }, { headers: {
                              'Access-Control-Allow-Origin': '*',
                              'Authorization': 'Bearer '+authobj.token
                            }}).then(res => {
                              let next_number = res.data;
                              // Setto i campi di default della form
                              let fields = {};
                              fields['anno'] = new Date().getFullYear();
                              fields['numero'] = next_number;
                              fields['giorno'] = new Date().toISOString().slice(0, 10);
                              fields['data_documento'] = new Date().toISOString().slice(0, 10);
                              fields['ora'] =  new Date().getHours()+':'+new Date().getMinutes();
                              reset(fields);

                              setWait(false);
                            });
                          }      
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }, [reset]);

  const retrieveTanksByCustomer = (id_customer)  => {
    setWait(true);

    setCustomerTanks([]);
    setSelectCustomerTanks([]);
    setSelectedCustomerTank(null);

    axios.post(window.SERVER_URL+'tanks/list_by_customer', { customer_id: id_customer }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setCustomerTanks(res.data);
      let select_options = [];
      res.data.map((tank) => {
        select_options.push({ value: tank.id, label: tank.matricola });
      });
      setSelectCustomerTanks(select_options);

      setWait(false);
    });
  }

  const handleAddTank = () => {
    if (selectedTank) {
      // Controllo se il serbatoio è già presente nella bolla
      let is_present = false;
      materials.forEach(function(mat) {
        if (mat.codice == selectedTank.label)
          is_present = true;
      });
      // Se non era presente, recupero tutte le info e lo inserisco
      if (!is_present) {
        axios.post(window.SERVER_URL+'tanks/get', { id: selectedTank.value }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          let newMaterial = {
            'entita': 'tank',
            'codice': res.data.matricola,
            'descrizione': 'Num. fabbrica: '+res.data.num_fabbrica+' - Tipologia: '+res.data.tank_type.descrizione+' - Capacità: '+res.data.capacita+' lt',
            'quantita': 1
          }
          setMaterials(current => [...current, newMaterial]);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: 'Il serbatoio selezionato è già presente nei materiali'
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi scegliere un serbatoio'
      });
    }
  }

  const handleAddExtinguisher = () => {
    if (selectedExtinguisher) {
      // Controllo se l'estintore è già presente nella bolla
      let is_present = false;
      materials.forEach(function(mat) {
        if (mat.codice == selectedExtinguisher.label)
          is_present = true;
      });
      // Se non era presente, recupero tutte le info e lo inserisco
      if (!is_present) {
        axios.post(window.SERVER_URL+'extinguishers/get', { id: selectedExtinguisher.value }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          let newMaterial = {
            'entita': 'extinguisher',
            'codice': res.data.matricola,
            'descrizione': 'Estintore - Tipologia: '+res.data.extinguisher_type.sigla+' '+res.data.extinguisher_type.descrizione+' - Peso: '+res.data.peso+' kg',
            'quantita': 1
          }
          setMaterials(current => [...current, newMaterial]);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "L'estintore selezionato è già presente nei materiali"
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi scegliere un estintore'
      });
    }
  }

  const handleAddMeter = () => {
    if (selectedMeter) {
      // Controllo se il contatore è già presente nella bolla
      let is_present = false;
      materials.forEach(function(mat) {
        if (mat.codice == selectedMeter.label)
          is_present = true;
      });
      // Se non era presente, recupero tutte le info e lo inserisco
      if (!is_present) {
        axios.post(window.SERVER_URL+'meters/get', { id: selectedMeter.value }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          let newMaterial = {
            'entita': 'meter',
            'codice': res.data.matricola,
            'descrizione': 'Contatore',
            'quantita': 1
          }
          setMaterials(current => [...current, newMaterial]);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "Il contatore selezionato è già presente nei materiali"
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi scegliere un contatore'
      });
    }
  }

  const handleAddValve = () => {
    if (selectedValve) {
      // Controllo se la valvola è già presente nella bolla
      let is_present = false;
      materials.forEach(function(mat) {
        if (mat.codice == selectedValve.label)
          is_present = true;
      });
      // Se non era presente, recupero tutte le info e la inserisco
      if (!is_present) {
        axios.post(window.SERVER_URL+'valves/get', { id: selectedValve.value }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          let newMaterial = {
            'entita': 'valve',
            'codice': res.data.matricola,
            'descrizione': 'Valvola',
            'quantita': 1
          }
          setMaterials(current => [...current, newMaterial]);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "La valvola selezionata è già presente nei materiali"
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi scegliere una valvola'
      });
    }
  }

  const handleAddStock = () => {
    if (selectedStock) {
      // Controllo se il materiale è già presente nella bolla
      let is_present = false;
      materials.forEach(function(mat) {
        if (mat.descrizione == selectedStock.label)
          is_present = true;
      });
      // Se non era presente, recupero tutte le info e lo inserisco
      if (!is_present) {
        axios.post(window.SERVER_URL+'stocks/get', { id: selectedStock.value }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          // Controllo se la giacenza in magazzino è sufficiente
          if (res.data.giacenza >= selectedStockQuantity) {
            let newMaterial = {
              'entita': 'stock',
              'codice': res.data.codice,
              'descrizione': res.data.descrizione+' - Tipologia: '+res.data.stock_type.descrizione+' - Unità di misura: '+res.data.measurement_unit.descrizione,
              'quantita': selectedStockQuantity
            }
            setMaterials(current => [...current, newMaterial]);
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Giacenza materiale insufficiente',
              text: "La giacenza in magazzino ("+res.data.giacenza+") non copre la quantità richiesta; vuoi procedere comunque?",
              // showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Procedi',
              // denyButtonText: 'Nuova pianificazione',
              cancelButtonText: "Annulla",
              confirmButtonColor: '#198754',
              cancelButtonColor: '#3085d6',
              denyButtonColor: '#3085d6',
            }).then((result) => {
              if (result.isConfirmed) {
                let newMaterial = {
                  'entita': 'stock',
                  'codice': res.data.codice,
                  'descrizione': res.data.descrizione+' - Tipologia: '+res.data.stock_type.descrizione+' - Unità di misura: '+res.data.measurement_unit.descrizione,
                  'quantita': selectedStockQuantity
                }
                setMaterials(current => [...current, newMaterial]);
              }
            });
          }
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "Il materiale selezionato è già presente nella lista"
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi scegliere un materiale dal magazzino'
      });
    }
  }

  const handleAddCustom = () => {
    if (selectedCustom && selectedCustomQuantity > 0) {
      let newMaterial = {
        'entita': null,
        'codice': null,
        'descrizione': selectedCustom,
        'quantita': selectedCustomQuantity
      }
      setMaterials(current => [...current, newMaterial]);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi inserire la descrizione e la quantità'
      });
    }
  }

  const handleDeleteMaterial = (codice, descrizione) => {
    if (codice)
      setMaterials(materials.filter(mat => mat.codice !== codice));
    else if (descrizione)
      setMaterials(materials.filter(mat => mat.descrizione !== descrizione));
    else
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Impossibile eliminare il materiale'
      });
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    if (form_data.numero_completo)
      delete form_data.numero_completo;
    form_data.materiali = materials;
    form_data.supplier_id = selectedSupplier ? selectedSupplier.value : null;
    form_data.customer_id = selectedCustomer ? selectedCustomer.value : null;
    form_data.tank_id = selectedCustomerTank ? selectedCustomerTank.value : null;
    form_data.delivery_note_motive_id = selectedMotive ? selectedMotive.value : null;
    form_data.delivery_note_transportation_id = selectedTransportation ? selectedTransportation.value : null;
    form_data.delivery_note_appearance_id = selectedAppearance ? selectedAppearance.value : null;
    form_data.delivery_note_carrier_id = selectedCarrier ? selectedCarrier.value : null;
    console.log(form_data);
    axios.post(window.SERVER_URL+'delivery_notes/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/delivery_notes');
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica bolla</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label>Anno</label>
                  <input type="text" placeholder="Anno" {...register("anno", { required: true })} />
                  {errors.anno && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Numero</label>
                  <input type="text" placeholder="numero" {...register("numero", { required: true })} />
                  {errors.numero && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Data documento</label>
                  <input type="date" name="data_documento" {...register("data_documento", { required: true })} />
                  {errors.data_documento && <span>Campo obbligatorio</span>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Fornitore / Mittente</label>
                  <Select value={selectedSupplier} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Cliente / Destinatario</label>
                  <Select value={selectedCustomer} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="customer_id" options={selectCustomers} onChange={(e) => { setSelectedCustomer(e); retrieveTanksByCustomer(e.value); }} />
                  { selectCustomerTanks && selectCustomerTanks.length ?
                    <>
                      <label style={{'margin':'8px 0 18px'}}>Serbatoio</label>
                      <Select value={selectedCustomerTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tank_id" options={selectCustomerTanks} onChange={(e) => setSelectedCustomerTank(e) } />
                    </>
                  : '' }
                </Col>
                <Col>
                  <label>Destinazione <b style={{'fontSize':'13px'}}>(riempire solo se destinazione diversa da cliente registrato)</b></label>
                  <textarea placeholder="Destinazione" {...register("destinazione")}></textarea>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Causale trasporto</label>
                  <Select value={selectedMotive} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="delivery_note_motive_id" options={selectMotives} onChange={(e) => setSelectedMotive(e)} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Trasporto a cura</label>
                  <Select value={selectedTransportation} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="delivery_note_transportation_id" options={selectTransportations} onChange={(e) => setSelectedTransportation(e)} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Aspetto dei beni</label>
                  <Select value={selectedAppearance} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="delivery_note_appearance_id" options={selectAppearances} onChange={(e) => setSelectedAppearance(e)} />
                </Col>
                <Col>
                  <label>Num. colli</label>
                  <input type="number" interval="1" name="colli" {...register("colli")} />
                </Col>
              </Row>
              <br /><hr />
              <div style={{'background':'#d3dae5','padding':'20px'}}>
                <h2>Aggiungi materiali</h2>
                <Row>
                  <Col onClick={() => { setAccSelected('tank'); }} className={ accSelected == 'tank' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="GiFuelTank" size="24" spaced /> Serbatoi</Col>
                  <Col onClick={() => { setAccSelected('extinguisher'); }} className={ accSelected == 'extinguisher' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="FaFireExtinguisher" size="24" spaced /> Estintori</Col>
                  <Col onClick={() => { setAccSelected('meter'); }} className={ accSelected == 'meter' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="BsSpeedometer" size="24" spaced /> Contatori</Col>
                  <Col onClick={() => { setAccSelected('valve'); }} className={ accSelected == 'valve' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="GiValve" size="24" spaced /> Valvole</Col>
                  <Col onClick={() => { setAccSelected('stock'); }} className={ accSelected == 'stock' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="FaWarehouse" size="24" spaced /> Magazzino</Col>
                  <Col onClick={() => { setAccSelected('custom'); }} className={ accSelected == 'custom' ? 'acc_label active' : 'acc_label' }><DynamicIcon iconName="FaPen" size="24" spaced /> Manuale</Col>
                </Row>
                { accSelected == 'tank' ? 
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <Select value={selectedTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} options={selectTanks} onChange={(e) => setSelectedTank(e)} />
                    </Col>
                    <Col lg={2}>
                      <Button onClick={() => { handleAddTank(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : accSelected == 'extinguisher' ?
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <Select value={selectedExtinguisher} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} options={selectExtinguishers} onChange={(e) => setSelectedExtinguisher(e)} />
                    </Col>
                    <Col lg={2}>
                      <Button onClick={() => { handleAddExtinguisher(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : accSelected == 'meter' ?
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <Select value={selectedMeter} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} options={selectMeters} onChange={(e) => setSelectedMeter(e)} />
                    </Col>
                    <Col lg={2}>
                      <Button onClick={() => { handleAddMeter(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : accSelected == 'valve' ?
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <Select value={selectedValve} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} options={selectValves} onChange={(e) => setSelectedValve(e)} />
                    </Col>
                    <Col lg={2}>
                      <Button onClick={() => { handleAddValve(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : accSelected == 'stock' ?
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <Select value={selectedStock} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} options={selectStocks} onChange={(e) => setSelectedStock(e)} />
                    </Col>
                    <Col lg={2}>
                      <input type="number" className='styled' interval="1" value={selectedStockQuantity} onChange={(e) => setSelectedStockQuantity(e.target.value)} />
                    </Col>
                    <Col lg={2}>
                      <Button onClick={() => { handleAddStock(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : accSelected == 'custom' ?
                  <Row className='acc_box'>
                    <Col lg={6}>
                      <input type="text" className='styled' placeholder='Inserisci la descrizione del materiale' onChange={(e) => setSelectedCustom(e.target.value)} />
                    </Col>
                    <Col lg={2}>
                      <input type="number" className='styled' interval="1" value={selectedCustomQuantity} onChange={(e) => setSelectedCustomQuantity(e.target.value)} />
                    </Col>
                    <Col lg={2}>
                      <Button className='success' onClick={() => { handleAddCustom(); }}><DynamicIcon iconName="FaPlusCircle" size='20' spaced /> Aggiungi ai materiali</Button>
                    </Col>
                  </Row>
                : '' }
                <br />
                <h2>Materiali inseriti</h2>
                { materials && materials.length ?
                  <table>
                    <thead>
                      <tr>
                        <th style={{'width':'250px'}}>Matricola / Codice</th>
                        <th>Descrizione</th>
                        <th style={{'width':'100px','textAlign':'center'}}>Quantità</th>
                        <th style={{'width':'70px'}}></th>
                      </tr>
                    </thead>
                    <tbody>
                      { materials.map((mat, i) => {
                        return <tr key={i}>
                          <td>{ mat.codice }</td>
                          <td>{ mat.descrizione }</td>
                          <td style={{'textAlign':'center'}}>{ mat.quantita }</td>
                          <td className="actions">
                            <Button onClick={() => { handleDeleteMaterial(mat.codice, mat.descrizione) }} className="delete"><DynamicIcon iconName="FaTrash" /></Button>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                : <p style={{'margin':'0 20px'}}><DynamicIcon iconName="FaExclamationTriangle" spaced /> Non ci sono materiali in questa bolla</p> }
              </div>
              <br /><hr />
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Vettore</label>
                  <Select value={selectedCarrier} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="delivery_note_carrier_id" options={selectCarriers} onChange={(e) => setSelectedCarrier(e)} />
                </Col>
                <Col>
                  <label>Data trasporto</label>
                  <input type="date" name="giorno" {...register("giorno", { required: true })} />
                  {errors.giorno && <span>Campo obbligatorio</span>}
                </Col>
                <Col>
                  <label>Ora trasporto</label>
                  <input type="time" name="ora" {...register("ora", { required: true })} />
                  {errors.ora && <span>Campo obbligatorio</span>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Note</label>
                  <textarea placeholder="Note" {...register("note")}></textarea>
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/delivery_notes', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
          </Col>
        </Row>
      </Container>
    </>
  ); 
}

export default DeliveryNotesAdd;