import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash, FaSearch } from 'react-icons/fa';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function DeliveryNotes() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [deliveryNotes, setDeliveryNotes] = useState(null);
  // PAGINATION
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    setWait(true);

    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'delivery_notes/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setDeliveryNotes(res.data.delivery_notes);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);

      setWait(false);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (delivery_note_id) => {
    Swal.fire({
      icon: 'question',
      title: 'Sei sicuro di voler cancellare questa bolla?',
      text: '',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Procedi',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'delivery_notes/delete', { id: delivery_note_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se la cancellazione è andata a buon fine
            setDeliveryNotes(deliveryNotes.filter(devnote => devnote.id !== delivery_note_id));
            getPage(1, query);
            
            Swal.fire({
              icon: 'success',
              title: 'Bolla cancellata correttamente',
              text: ''
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: 'Impossibile cancellare la bolla'
            });
          }
        });
      }
    });    
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Bolle</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
            <Col>
              <Button variant="success" onClick={() => { navigate('/delivery_notes/add', {state: {page: page, query: query}}); }}><BiPlus /> Crea nuova bolla</Button>
            </Col>
          </Row>
        <Row>
            <Col style={{margin:'0 20px'}}>
              <form className="custom_form" id="query_form">
                <FaSearch />
                <input type="text" placeholder="Cerca contatore" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
              </form>
            </Col>
          </Row>
        <Row>
          <Col>
            { deliveryNotes && deliveryNotes.length ? 
              <>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Numero</th>
                      <th>Data documento</th>
                      <th>Data trasporto</th>
                      <th>Causale</th>
                      <th>Cliente</th>
                      <th>Num. colli</th>
                      <th style={{'width':'180px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { deliveryNotes.length ? deliveryNotes.map((devnote) => {
                        return <tr key={ devnote.id }>
                                <td>{ devnote.id }</td>
                                <td>{ devnote.numero_completo }</td>
                                <td>{ devnote.data_documento }</td>
                                <td>{ devnote.giorno } { devnote.ora }</td>
                                <td>{ devnote.delivery_note_motive ? devnote.delivery_note_motive.descrizione : '-' }</td>
                                <td>{ devnote.customer ? devnote.customer.etichetta : '-' }</td>
                                <td>{ devnote.colli }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/delivery_notes/add', {state: {delivery_note_id: devnote.id, page: page, query: query}}) }}><IoIosArrowForward /></Button>
                                  <Button onClick={() => { window.location.href = window.SERVER_URL.replace('api/', '')+'export/modules/4/delivery_note/'+devnote.id; }}><DynamicIcon iconName="FaFilePdf" /></Button>
                                  <Button onClick={() => { handleDelete(devnote.id) }} className="delete"><FaTrash /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna bolla presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default DeliveryNotes;