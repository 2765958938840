import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function StockHandlingsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  var date = new Date();
  var dateString = date.toISOString().substring(0, 10);
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: { data_movimento: dateString }
  });
  // DATA
  const [stockHandling, setStockHandling] = useState();
  const [stocks, setStocks] = useState([]);
  const [selectStocks, setSelectStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [measurementUnit, setMeasurementUnit] = useState(null);
  const [stockQuantity, setStockQuantity] = useState(null);
  const [taxRates, setTaxRates] = useState([]);
  const [selectTaxRates, setSelectTaxRates] = useState([]);
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);

  useEffect(() => {
    // Carico la merce in magazzino
    axios.post(window.SERVER_URL+'stocks/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data.stocks);
      setStocks(res.data.stocks);
      var tmp_stocks = res.data.stocks;
      let select_options = [];
      res.data.stocks.map((stock) => {
        select_options.push({ value: stock.id, label: stock.descrizione });
      });
      setSelectStocks(select_options);

      // Carico le aliquote IVA
      axios.post(window.SERVER_URL+'tax_rates/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTaxRates(res.data.tax_rates);
        let select_options = [];
        res.data.tax_rates.map((tax) => {
          select_options.push({ value: tax.id, label: tax.descrizione });
        });
        setSelectTaxRates(select_options);

        // Se sto modificando una movimento esistente, carico le informazioni relative
        if (state && state.stock_handling_id) {
          axios.post(window.SERVER_URL+'stock_handlings/get', { id: state.stock_handling_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            setStockHandling(res.data);

            // Mi setto le select
            if (res.data.stock_id)
              setSelectedStock({ value: res.data.stock_id, label: res.data.stock.descrizione });
            if (res.data.tax_rate_id)
              setSelectedTaxRate({ value: res.data.tax_rate_id, label: res.data.tax_rate.descrizione });
            
            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(res.data))
              if (typeof val !== 'object')
                fields[key] = val;
            reset(fields);
              
            tmp_stocks.forEach((stock) => {
              if (stock.id == res.data.stock_id) {
                setMeasurementUnit(stock.measurement_unit.descrizione);
                setStockQuantity(stock.giacenza+(stock.measurement_unit.sigla ? ' '+stock.measurement_unit.sigla : ''));
              }
            });
            
          });
        }
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.stock_id = selectedStock ? selectedStock.value : null;
    form_data.tax_rate_id = selectedTaxRate ? selectedTaxRate.value : null;
    axios.post(window.SERVER_URL+'stock_handlings/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/stock_handlings');
    }).catch(function(error) {
      console.log(error);
    });
  }

  const setStockInfo = (stock_id) => {
    stocks.forEach((stock) => {
      if (stock.id == stock_id) {
        setMeasurementUnit(stock.measurement_unit.descrizione);
        setStockQuantity(stock.giacenza+(stock.measurement_unit.sigla ? ' '+stock.measurement_unit.sigla : ''));
      }
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica movimento di magazzino</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Materiale</label>
                <Select value={selectedStock} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="stock_id" options={selectStocks} onChange={(e) => { setSelectedStock(e); setStockInfo(e.value); }} />
              </Col>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Attività</label>
                { stockHandling && stockHandling.activity_operation ?
                  <>
                    <input type="text" value={stockHandling.activity_operation.data+' - '+stockHandling.activity_operation.descrizione} />
                    <Button onClick={() => navigate('/activity_operations/add', {state: { activity_id: stockHandling.activity_operation.activity_id, activity_operation_id: stockHandling.activity_operation.id }})}>Vai all'attività</Button>
                  </>
                : 'Nessuna attività collegata' }
                {/* <Select value={selectedStock} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="stock_id" options={selectStocks} onChange={(e) => setSelectedStock(e)} /> */}
              </Col>
              <Col>
                <label>Data movimento</label>
                <input type="date" placeholder="Data movimento" {...register("data_movimento")} />
              </Col>
            </Row>
            <Row>
              <Col id="stock_info">
                <span>Giacenza attuale:</span><span>{ stockQuantity }</span>
              </Col>
            </Row>
            <Row style={{'marginTop':'10px'}}>
              <Col>
                <label>Quantità</label>
                <input type="number" min="0" step="0.1" placeholder="Quantità" {...register("quantita")} />
              </Col>
              <Col>
                <label>Unità di misura</label>
                <input type="text" readOnly value={measurementUnit} />
              </Col>
              <Col>
                <label>In/Out</label>
                { (state && state.stock_handling_id) ?
                  <input type="text" readOnly {...register("direzione")} />
                :
                  <select {...register("direzione")}>
                    <option value="">- Scegli direzione -</option>
                    <option value="in" selected={(stockHandling && stockHandling.direzione == 'in') ? true : false}>Ingresso</option>
                    <option value="out" selected={(stockHandling && stockHandling.direzione == 'out') ? true : false}>Uscita</option>
                  </select>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Prezzo</label>
                <input type="number" step="0.01" placeholder="Prezzo" {...register("prezzo")} />
              </Col>
              <Col>
                <label>Aliquota IVA</label>
                <Select value={selectedTaxRate} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tax_rate_id" options={selectTaxRates} onChange={(e) => setSelectedTaxRate(e)} />
              </Col>
              <Col>
                <label>Variazione</label>
                <input type="number" step="0.01" placeholder="Variazione" {...register("variazione")} />
              </Col>
              <Col>
                <label>Tipo variazione</label>
                <select {...register("tipo_variazione")}>
                  <option value="nessuna" selected={(stockHandling && stockHandling.tipo_variazione == 'nessuna') ? true : false}>Nessuna</option>
                  <option value="percentuale" selected={(stockHandling && stockHandling.tipo_variazione == 'percentuale') ? true : false}>Percentuale</option>
                  <option value="importo" selected={(stockHandling && stockHandling.tipo_variazione == 'importo') ? true : false}>Importo</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Numero bolla</label>
                <input type="text" placeholder="Num. bolla" {...register("numero_bolla")} />
              </Col>
              <Col>
                <label>Data bolla</label>
                <input type="date" placeholder="Data bolla" {...register("data_bolla")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Note</label>
                <input type="text" placeholder="Note" {...register("note")} />
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/stock_handlings', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna ai movimenti</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default StockHandlingsAdd;