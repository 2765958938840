import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT FILE UPLOADER
import { FileUploader } from "react-drag-drop-files";
// REACT SWEETALERT
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus, BiSearch } from 'react-icons/bi';
import { FaTrash, FaSearch } from 'react-icons/fa';

function OperationsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, setValue } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [activity, setActivity] = useState(null);
  const [activityOperation, setActivityOperation] = useState(null);
  // const [operationTypes, setOperationTypes] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectOperations, setSelectOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [operationStatuses, setOperationStatuses] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [entities, setEntities] = useState([]);
  const [selectEntities, setSelectEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [stockHandlings, setStockHandlings] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [selectStocks, setSelectStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [problemReasons, setProblemReasons] = useState(null);
  const [stockUsed, setStockUsed] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  // FILE UPLOADER
  const [file, setFile] = useState(null);
  const [activityOperationFiles, setActivityOperationFiles] = useState([]);
  // MODAL
  const [modalOptions, setModalOptions] = useState({});
  const [showAbortConfirm, setShowAbortConfirm] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);

  useEffect(() => {
    // Se non provengo da un'attività faccio vedere un alert e poi lo rimando alla schermata delle attività per sceglierne una
    if (state && state.activity_id) {
      setWait(true);

      axios.post(window.SERVER_URL+'activities/get', { id: state.activity_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setActivity(res.data);

        // Carico le operazioni
        axios.post(window.SERVER_URL+'operations/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setOperations(res.data.operations);
          let select_options = [];
          res.data.operations.map((op) => {
            select_options.push({ value: op.id, label: op.descrizione });
          });
          setSelectOperations(select_options);

          // Carico gli stati delle operazioni
          axios.post(window.SERVER_URL+'operation_statuses/list', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setOperationStatuses(res.data.operation_statuses);

            // Carico le tipologie di entità
            axios.post(window.SERVER_URL+'entity_types/list', { 'is_utente': 0 }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setEntityTypes(res.data);

              // Carico i fornitori
              axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                setSuppliers(res.data.suppliers);
                let select_options = [];
                res.data.suppliers.map((sup) => {
                  select_options.push({ value: sup.id, label: sup.ragione_sociale });
                });
                setSelectSuppliers(select_options);

                // Se sto modificando un'operazione esistente, carico le informazioni relative
                if (state && state.activity_operation_id) {
                  axios.post(window.SERVER_URL+'activity_operations/get', { id: state.activity_operation_id }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }}).then(res => {
                    console.log(res.data);
                    setActivityOperation(res.data);

                    setWait(true);

                    // Carico i file dell'attività
                    axios.post(window.SERVER_URL+'activity_operations/get_files', { activity_operation_id: state.activity_operation_id }, {
                      headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }
                    }).then(resf => {
                      console.log(resf.data);
                      setActivityOperationFiles(resf.data.files);

                      // Carico la lista delle problematiche
                      axios.post(window.SERVER_URL+'problem_reasons/list', { entity_type_id: res.data.entity_type_id }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(resprob => {
                        setProblemReasons(resprob.data.problem_reasons);

                        let movements = [];
                        res.data.stock_handlings.map((mov) => {
                          movements.push({
                            'id': mov.id,
                            'id_stock': mov.stock.id,
                            'codice': mov.stock.codice,
                            'descrizione': mov.stock.descrizione,
                            'quantita': mov.quantita
                          });
                        });
                        setStockHandlings(movements);
                                    
                        // Mi setto le select
                        if (res.data.operation_id)
                          setSelectedOperation({ value: res.data.operation_id, label: res.data.operation.descrizione });
                        if (res.data.supplier_id)
                          setSelectedSupplier({ value: res.data.supplier_id, label: res.data.supplier.ragione_sociale });
                        if (res.data.entity && res.data.entity_id) {
                          console.log(">>> devo recuperare i "+res.data.entity.modello_plurale);
                          retrieveEntities(res.data);
                        } else {
                          setWait(false);
                        }

                        // Setto i campi della form
                        let fields = {};
                        for (const [key, val] of Object.entries(res.data))
                          if (typeof val !== 'object' || (key == 'info' && val !== null))
                            fields[key] = val;
                        reset(fields);
                      });
                    });
                  });
                }
              });

              if (!state || !state.activity_operation_id)
                setWait(false);
            });
          });
        });
      });
    } else {
      setModalOptions({
        'title': 'ERRORE',
        'text': "Puoi aggiungere un'operazione solo dalla schermata di un'attività",
        // 'closeTxt': 'CHIUDI',
        'confirmTxt': 'OK'
      });
      setShowAbortConfirm(true);
    }
  }, [reset, state]);

  const doAbort = () => {
    navigate('/activities');
  }

  const retrieveEntities = (data_obj)  => {
    console.log("ACTIVITY");
    console.log(activity);
    console.log("DATA_OBJ");
    console.log(data_obj);
    setWait(true);

    let modello_plurale = (typeof data_obj == "string" ? data_obj : data_obj.entity.modello_plurale);
    let location_id = (typeof data_obj == "string" ? activity.location_id : data_obj.activity.location_id);
    axios.post(window.SERVER_URL+modello_plurale+'/list_by_location', { 'location_id': location_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setEntities(res.data);
      let select_options = [];
      res.data.map((entity) => {
        select_options.push({ value: entity.id, label: entity.matricola });
      });
      setSelectEntities(select_options);

      if (typeof data_obj != "string" && data_obj.entity.id && data_obj.entity.matricola)
        setSelectedEntity({ value: data_obj.entity.id, label: data_obj.entity.matricola });
      else
        setSelectedEntity(null);

      setWait(false);
    });
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.operation_id = selectedOperation ? selectedOperation.value : null;
    if (form_data.activity_log)
      form_data.activity_log.user_id = authobj.user_id;
    form_data.activity_id = state.activity_id;
    form_data.entity_id = selectedEntity ? selectedEntity.value : null;
    form_data.supplier_id = selectedSupplier ? selectedSupplier.value : null;
    // form_data.location_id = selectedLocation ? selectedLocation.value : null;

    if (form_data.activity_id == "" || form_data.operation_id == "" || (form_data.entity_type_id == "" && form_data.matricola == "")) {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Non hai compilato tutti i campi obbligatori'
      });
    } else {
      axios.post(window.SERVER_URL+'activity_operations/save', form_data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token,
          // 'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.success == true) {
          if (activityOperation && activityOperation.next_operation) {
            // Resetto tutti i campi
            reset();
            // Popup utente
            Swal.fire({
              icon: 'question',
              title: 'Ci sono altre attività in questo intervento',
              text: "Vuoi procedere con la prossima o tornare all'intervento?",
              // showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Vai alla prossima',
              // denyButtonText: 'Nuova pianificazione',
              cancelButtonText: "Torna all'intervento",
              confirmButtonColor: '#198754',
              cancelButtonColor: '#3085d6',
              denyButtonColor: '#3085d6',
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                navigate('/activity_operations/add', {state: { activity_id: state.activity_id, activity_operation_id: activityOperation.next_operation }})
              } else {
                navigate('/activities/add', { state: { activity_id: state.activity_id } });

              }
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Salvataggio effettuato',
              text: 'I dati inseriti sono stati salvati correttamente'
            }).then((result) => {
              if (result.isConfirmed)
                navigate('/activities/add', { state: { activity_id: state.activity_id } });
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Errore',
            text: 'Impossibile salvare i dati'
          });
        }
      }).catch(function(error) {
        console.log(error);
      });
    }
  }

  const handleFileUpload = (files) => {
    console.log(files);
    if (state.activity_operation_id) {
      setWait(true);

      // Carico i files
      const formData = new FormData();
      var readed_files = [];
      // Ciclo sui vari file
      const promises = [];
      Array.from(files).forEach(function(f, idx) {
        promises.push(new Promise((resolve, reject) => {
          var fileReader = new FileReader();
          fileReader.onload = function(evt) {
            let readed = {
              'metadata': {
                'name': f.name,
                'type': f.type,
                'size': f.size
              },
              'content': evt.target.result
            };
            readed_files.push(readed);
            resolve(readed);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(f);
        }));
      });

      Promise.all(promises).then((results) => {
        formData.append('uploads', JSON.stringify(results));
        formData.append('activity_operation_id', state.activity_operation_id);
        axios.post(window.SERVER_URL+'activity_operations/upload', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success == true) {
            setWait(false);
            Swal.fire({
              icon: 'success',
              title: 'Caricamento completato',
              html: '<b>'+res.data.log.length+' file</b> sono stati caricati correttamente'
            }).then((result) => {
              if (result.isConfirmed) {
                // Recupero la lista aggiornata dei files
                setWait(true);
                axios.post(window.SERVER_URL+'activity_operations/get_files', { activity_operation_id: state.activity_operation_id }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reslist => {
                  console.log(reslist.data);
                  setActivityOperationFiles(reslist.data.files);
                  setWait(false);
                });
              }
            });
          } else {
            let log = "";
            res.data.log.forEach(function(row, idx) {
              log += row.name+': '+(row.result ? 'caricato' : 'fallito')+'<br>'
            });
            Swal.fire({
              icon: res.data.log.length ? 'warning' : 'error',
              title: res.data.log.length ? 'Uno o più caricamenti sono falliti' : 'Caricamento dei file fallito',
              html: log
            });
          }
        });
      });

      setWait(false);
    }
  };

  const retrieveStocks = (query) => {
    // console.log("QUERY: "+query);
    if (query && query.length >= 3) {
      var params = {};
      params.search = query;
      axios.post(window.SERVER_URL+'stocks/list', { params }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setStocks(res.data.stocks);
        let select_options = [];
        res.data.stocks.map((stock) => {
          select_options.push({ value: stock.id, label: stock.codice+' - '+stock.descrizione+' (Disp. '+stock.giacenza+')' });
        });
        setSelectStocks(select_options);
        setSelectedStock(null);
      });
    } else {
      // setStocks([]);
      // setSelectStocks([]);
      // setSelectedStock(null);
    }
  }

  const handleAddStock = () => {
    if (selectedStock && stockUsed) {
      let form_data = new FormData();
      form_data.append('stock_id', selectedStock.value);
      form_data.append('activity_operation_id', state.activity_operation_id);
      form_data.append('quantita', stockUsed);
      form_data.append('direzione', 'out');
  
      axios.post(window.SERVER_URL+'stock_handlings/save', form_data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token,
          // 'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        console.log(res.data);
        if (res.data.success === true) {
          let tmp_stock = {
            'id': res.data.stock_handling.id,
            'id_stock': res.data.stock_handling.stock.id,
            'codice': res.data.stock_handling.stock.codice,
            'descrizione': res.data.stock_handling.stock.descrizione,
            'quantita': res.data.stock_handling.quantita
          }
          setStockHandlings(current => [...current, tmp_stock]);

          setStocks([]);
          setSelectStocks([]);
          setSelectedStock(null);

          MySwal.fire({
            title: <strong>Salvataggio eseguito</strong>,
            html: <p>Il materiale è stato aggiunto alla tua attività</p>,
            icon: 'success'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Si è verificato un problema nel salvataggio'
          });
        }
      }).catch(function(error) {
        console.log(error);
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un materiale e la quantità utilizzata'
      });
    }
  }

  const handleDelete = (stock_handling_id) => {
    // setIdToDelete(stock_handling_id);
    Swal.fire({
      title: '<strong>Sei sicuro di voler cancellare questo movimento?</strong>',
      html: "<p>L'operazione non è reversibile</p>",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'ELIMINA',
      denyButtonText: 'ANNULLA',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'stock_handlings/delete', { id: stock_handling_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            setStockHandlings(stockHandlings.filter(stock => stock.id !== stock_handling_id));

            setStocks([]);
            setSelectStocks([]);
            setSelectedStock(null);

            Swal.fire({
              title: '<strong>Cancellazione eseguita</strong>',
              html: '<p>Il movimento è stato cancellato</p>',
              icon: 'success'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Si è verificato un problema nella cancellazione'
            });
          }
        });
      }
    })
  }

  const handleDeleteFile = (id_file) => {
    Swal.fire({
      title: '<strong>Sei sicuro di voler cancellare questo allegato?</strong>',
      html: "<p>L'operazione non è reversibile</p>",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'ELIMINA',
      denyButtonText: 'ANNULLA',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'activity_operations/delete_file', { id: id_file }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            setActivityOperationFiles(activityOperationFiles.filter(f => f.id !== id_file));
            Swal.fire({
              icon: 'success',
              title: 'Foto cancellata',
              // text: 'Il movimento è stato cancellato',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: 'Si è verificato un problema nella cancellazione'
            });
          }
        });
      }
    });
  }

  const doDelete = () => {
    // axios.post(window.SERVER_URL+'activities/delete', { id: idToDelete }, { headers: {
    //   'Access-Control-Allow-Origin': '*',
    //   'Authorization': 'Bearer '+authobj.token
    // }}).then(res => {
    //   if (res.data.success === true) {
    //     // se la cancellazione è andata a buon fine
    //     setActivities(activities.filter(activity => activity.id !== idToDelete));
    //     getPage(1, query);
    //     setShowDeleteConfirm(false);
    //   } else {
    //     setModalOptions({
    //       'title': 'ERRORE',
    //       'text': 'Si è verificato un errore durante la cancellazione',
    //       'closeTxt': 'CHIUDI',
    //       'confirmTxt': null
    //     });
    //     setShowDeleteConfirm(true);
    //   }
    // });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <ModalConfirm show={showAbortConfirm} handleClose={setShowAbortConfirm} handleConfirm={doAbort} modalOptions={modalOptions} />
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica attività</h1>
          </Col>
        </Row>
        <Row className="activity_info">
          <Col className="title" lg={3}>
            <h2>Informazioni intervento</h2>
            <div className='action_bar bottom' style={{'padding':'0','margin':'0'}}>
              <Button onClick={() => navigate('/activities/add', { state: { activity_id: state.activity_id } }) } style={{'margin':'0'}}><IoIosArrowBack /> Torna all'intervento</Button>
            </div>
          </Col>
          <Col>
            { activity ? 
            <ul>
              <li><b>Sede tecnica:</b> { activity.location.sede_tecnica }</li>
              {/* <li><b>Data preventivata:</b> { activity.data_preventivata ? activity.data_preventivata.split('-').reverse().join('/') : '' }</li> */}
              <li><b>Priorità:</b> { activity.activity_priority.descrizione }</li>
              <li><b>Ubicazione:</b> { activity.location.denominazione }</li>
              <li><b>Indirizzo:</b> { activity.location.indirizzo }</li>
              <li><b>Creato il:</b> { `${new Date(activity.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` }</li>
            </ul>
            : '' }
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Attività</label>
                  <Select value={selectedOperation} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="operation_id" options={selectOperations} onChange={(e) => setSelectedOperation(e)} />
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Fornitore</label>
                  <Select value={selectedSupplier} required={false} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => setSelectedSupplier(e)} />
                </Col>
              </Row>
              <Row style={{'marginTop':'10px'}}>
                <Col>
                  <label>Tipo entità</label>
                  <select {...register("entity_type_id")} onChange={(e) => retrieveEntities(e.target.options[e.target.selectedIndex].getAttribute("data-model"))}>
                    <option value="">- Scegli un tipo di entità -</option>
                    { entityTypes ? entityTypes.map((entity_type) => {
                      if (entity_type.id != 8)
                        return <option key={ entity_type.id } value={ entity_type.id } data-model={ entity_type.modello_plurale }>{ entity_type.descrizione }</option>
                    }) : '' }
                  </select>
                </Col>
                <Col>
                  <label style={{'margin':'8px 0 18px'}}>Entità</label>
                  <Select value={selectedEntity} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="entity_id" options={selectEntities} onChange={(e) => setSelectedEntity(e)} />
                </Col>
                <Col style={{'background':'#fff399'}}>
                  <label>Matricola <b>(inserire solo se entità non presente in archivio)</b></label>
                  <input type="text" placeholder="Matricola" {...register("matricola")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Descrizione</label>
                  <input type="text" placeholder="Descrizione" {...register("descrizione")} />
                </Col>
                <Col>
                  { activityOperation ?
                    <>
                      <label>Stato attuale</label>
                      <div className="op_status" style={{'padding':'10px'}}>
                        <span className="square" style={{'background':activityOperation.operation_status.colore}}></span>
                        <span style={{'color':activityOperation.operation_status.colore}}>{ activityOperation.operation_status.descrizione }</span>
                      </div>
                    </>
                  : '' }
                </Col>
              </Row>
              { state && state.activity_operation_id ? 
                <>
                  <Row className="change_status_block">
                    <Row>
                      <Col><h2 className="fieldset_label">Cambia stato attività</h2></Col>
                    </Row>
                    <Col>
                      <label>Cambia stato</label>
                      <select {...register("activity_log[operation_status_id]")}>
                        <option value="">- Non aggiornare lo stato -</option>
                        { operationStatuses ? operationStatuses.map((operation_stasus) => {
                          return <option key={ operation_stasus.id } value={ operation_stasus.id }>{ operation_stasus.descrizione }</option>
                        }) : '' }
                      </select>
                    </Col>
                    <Col>
                      <label>Scegli problematica</label>
                      <select {...register("activity_log[problem_reason_id]")}>
                        <option value="">- Nessuna problematica -</option>
                        { problemReasons ? problemReasons.map((problem_reason) => {
                          return <option key={ problem_reason.id } value={ problem_reason.id }>{ problem_reason.descrizione }</option>
                        }) : '' }
                      </select>
                    </Col>
                    <Col>
                      <label>Note cambio stato</label>
                      <input type="text" placeholder="Note cambio stato" {...register("activity_log[note]")} />
                    </Col>
                  </Row>
                  {/* LETTURA CONTATORE */ }
                  { activityOperation && activityOperation.operation_id == 29 ? 
                    <Row className="change_info_block">
                      <Row>
                        <Col><h2 className="fieldset_label">Inserisci informazioni <DynamicIcon spaced={true} iconName={ window.ENTITY_ICONS[activityOperation.entity.modello_singolare] } /> { activityOperation.operation.descrizione }</h2></Col>
                      </Row>
                      <Col>
                        <label>Data lettura</label>
                        <input type="date" placeholder="Data lettura" defaultValue={ new Date().toISOString().slice(0, 10) } {...register("info[data]")} />
                      </Col>
                      <Col>
                        <label>Anno di riferimento</label>
                        <select {...register("info[anno]")} defaultValue={ new Date().getFullYear() }>
                          { window.YEARS.map((year, index) => {
                            return <option value={year}>{ year }</option>
                          })}
                        </select>
                      </Col>
                      <Col>
                        <label>Mese di riferimento</label>
                        <select {...register("info[mese]")} defaultValue={ (parseInt(new Date().getMonth())+1) }>
                          { window.MONTHS.map((month, index) => {
                            return <option value={(index+1)}>{ month }</option>
                          })}
                        </select>
                      </Col>
                      <Col>
                        <label>Lettura</label>
                        <input type="text" placeholder="Lettura" {...register("info[lettura]")} />
                      </Col>
                      <Col>
                        <label>Livello riempimento</label>
                        <input type="text" placeholder="Livello riempimento" {...register("info[riempimento]")} />
                      </Col>
                    </Row>
                  : '' }
                </>
              : '' }
              <Row>
                <Col>
                  <label>Note</label>
                  <textarea placeholder="Note" {...register("note")}></textarea>
                </Col>
              </Row>
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        { state && state.activity_id ?
          <>
            {/* STORICO STATI */}          
            { activityOperation ?
              <>
                {/* <hr style={{'border':'10px solid #000','borderColor':'#000'}} /> */}
                <div className='box_fieldset'>
                  <Row>
                    <Col>
                      <h2 style={{'width':'600px','maxWidth':'90%','background':'#333','color':'#fff','padding':'10px 30px','borderTopRightRadius':'20px'}}>Storico stati</h2>
                    </Col>
                  </Row>
                  { activityOperation.activity_logs.length ?
                    <Row style={{'paddingRight':'20px'}}>
                      <br />
                      <table style={{'marginTop':'15px','background':'#fff'}}>
                        <thead>
                          <tr>
                            <th style={{'width':'160px'}}>Data</th>
                            <th style={{'width':'400px'}}>Utente</th>
                            <th style={{'width':'220px'}}>Stato</th>
                            <th>Problematica</th>
                            <th>Note</th>
                            <th style={{'width':'20px'}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        { activityOperation.activity_logs.map((log) => 
                          <tr key={log.id}>
                            <td>{ `${new Date(log.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` }</td>
                            <td>{ log.user ? log.user.nome+' '+log.user.cognome : '-' }</td>
                            {/* <td>{ log.operation_status.descrizione }</td> */}
                            <td className="op_status">
                              <span className="square" style={{'background':log.operation_status.colore}}></span>
                              <span style={{'color':log.operation_status.colore}}>{ log.operation_status.descrizione }</span>
                            </td>
                            <td>{ log.problem_reason ? log.problem_reason.descrizione : '-' }</td>
                            <td>{ log.note ? log.note : '-' }</td>
                            <td>
                              { log.path_id ?
                                <Button onClick={() => { navigate('/paths/add', {state: {path_id: log.path_id}}) }} title="Vai alla pianificazione"><DynamicIcon iconName="FaRoute" /></Button>
                              : '' }
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </Row>
                  : <p style={{'padding':'0 40px'}}>Nessun log presente</p> }
                </div>
              </>
            : '' }

            {/* MATERIALI UTILIZZATI */}
            { activityOperation ?
              <>
                <br /><hr />
                <div className='box_fieldset'>
                  <Row>
                    <Col>
                      <h2>Materiali utilizzati</h2>
                    </Col>
                  </Row>            
                  <Row className="action_bar top">
                    <Col lg={5}>
                      <label>Cerca materiale</label>
                      <Select value={selectedStock} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="stock_id" options={selectStocks} onInputChange={(e) => retrieveStocks(e)} onChange={(e) => setSelectedStock(e) } onValueClick={(e) => e.stopPropagation(e) } />
                    </Col>
                    <Col lg={2}>
                      <label>Inserisci quantità</label><br />
                      <input type="number" value={stockUsed} name="stock_used" id="stock_quantity" onChange={(e) => setStockUsed(e.target.value)} style={{'height':'36px','border':'1px solid rgb(204,204,204)','borderRadius':'4px','padding':'0 10px'}} />
                    </Col>
                    <Col>
                      <label style={{'display':'block','height':'25px'}}></label>
                      <Button variant="success" onClick={() => handleAddStock() } ><BiPlus /> Aggiungi materiale</Button>
                    </Col>
                  </Row>
                  { stockHandlings && stockHandlings.length ?
                    <Row style={{'padding':'0 10px'}}>
                      <table style={{'background':'#fff'}}>
                        <thead>
                          <tr>
                            <th style={{'width':'100px'}}>ID</th>
                            <th style={{'width':'250px'}}>Codice</th>
                            <th>Descrizione</th>
                            <th style={{'width':'120px'}}>Quantità</th>
                            <th style={{'width':'120px'}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        { stockHandlings.map((mov) => 
                            <tr key={ mov.id }>
                              <td>{ mov.id_stock }</td>
                              <td>{ mov.codice }</td>
                              <td>{ mov.descrizione }</td>
                              <td>{ mov.quantita }</td>
                              <td className="actions">
                                <Button onClick={() => { navigate('/stock_handlings/add', {state: { stock_handling_id: mov.id }}) }}><IoIosArrowForward /></Button>
                                <Button onClick={() => { handleDelete(mov.id) }} className="delete"><FaTrash /></Button>
                              </td>
                            </tr>
                        )}
                        </tbody>
                      </table>
                    </Row>
                  : <p style={{'padding':'0 20px'}}><b><DynamicIcon iconName="GrCircleAlert" /> Nessun materiale utilizzato</b></p> }
                </div>
              </>
            : '' }

            {/* DOCUMENTI ALLEGATI */}
            { activityOperation ?
              <>
                <br /><hr />
                <div className='box_fieldset'>
                  <Row>
                    <Col>
                      <h2>Documenti allegati</h2><br />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FileUploader handleChange={handleFileUpload} name="file" multiple={true} types={['JPG','PNG','JPEG','PDF','DOC','DOCX','XLS','XLSX']} />
                    </Col>
                  </Row>
                  { activityOperationFiles && activityOperationFiles.length ? 
                    <>
                      <p style={{'margin':'0 20px 5px'}}><b>Allegati caricati:</b></p>
                      <ul className='filelist'>
                        { activityOperationFiles.map((f) => 
                          <li key={ f.id }>
                            <DynamicIcon iconName="GrDocument" />
                            { f.nome } 
                            <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            <Button title="Elimina file" onClick={() => { handleDeleteFile(f.id); }} className='delete'><DynamicIcon iconName="MdDelete" /></Button>
                          </li>
                        )}
                      </ul>
                    </>
                  : <p style={{'padding':'0 20px'}}><b><DynamicIcon iconName="GrCircleAlert" /> Nessun documento allegato</b></p> }
                </div>
              </>
            : <p style={{'padding':'0 20px'}}><b><DynamicIcon iconName="GrCircleAlert" /> Nessun documento allegato</b></p> }
          </>
        : '' }
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate(-1) } className="back"><DynamicIcon iconName="RiArrowGoBackFill" /> Torna indietro</Button>
            <Button onClick={() => navigate('/activities/add', { state: { activity_id: state.activity_id } }) }><IoIosArrowBack /> Torna all'intervento</Button>
          </Col>
        </Row>
      </Container>
    </>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default OperationsAdd;