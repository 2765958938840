import React, { useState, useContext, useEffect } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// ICONS
import { BiPlus } from 'react-icons/bi';
import { GoDot } from 'react-icons/go';
import { FaFlagCheckered, FaCarAlt, FaWrench, FaClock, FaCoffee } from 'react-icons/fa';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// AXIOS
import axios from 'axios';
// PARTIALS
import Map from '../partials/map';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Plan() {
  // AUTH, STATE, NAVIGATION
  const { authobj, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState({ lat: 42.9705321, lng: 13.7150639 });
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [distKm, setDistKm] = useState(null);
  const [mapKey, setMapKey] = useState(0);
  const [routes, setRoutes] = useState(null);
  const [origChecks, setOrigChecks] = useState([]);
  const [checks, setChecks] = useState([]);
  const [filteredChecks, setFilteredChecks] = useState([]);
  const [routedChecks, setRoutedChecks] = useState({});
  const [priorities, setPriorities] = useState([]);
  const [selectPriorities, setSelectPriorities] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [driverStartAddress, setDriverStartAddress] = useState(null);
  const [driverStartLat, setDriverStartLat] = useState(null);
  const [driverStartLng, setDriverStartLng] = useState(null);
  const [driverEndAddress, setDriverEndAddress] = useState(null);
  const [driverEndLat, setDriverEndLat] = useState(null);
  const [driverEndLng, setDriverEndLng] = useState(null);
  const [operationTypes, setOperationTypes] = useState([]);
  const [selectOperationTypes, setSelectOperationTypes] = useState([]);
  const [selectedOperationTypes, setSelectedOperationTypes] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPathDate, setSelectedPathDate] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectSuppliers, setSelectSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState(null);
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTowns, setSelectedTowns] = useState(null);
  const [selectedStartTown, setSelectedStartTown] = useState(null);
  const [selectedEndTown, setSelectedEndTown] = useState(null);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect (() => {
    setWait(true);

    if (!checks.length) {  // solo se non ho già caricato la lista degli interventi pending
      // Recupero la lista degli operai
      axios.post(window.SERVER_URL+'users/list', { params: { roles: [1,3] } }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setUsers(res.data.users);
        let select_options = [];
        res.data.users.map((usr) => {
          select_options.push({ value: usr.id, label: usr.nome+' '+usr.cognome });
        });
        setSelectUsers(select_options);

        // Recupero la lista delle tipologie di operazioni
        axios.post(window.SERVER_URL+'operations/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setOperationTypes(res.data.operations);
          let select_options = [];
          res.data.operations.map((type) => {
            select_options.push({ value: type.id, label: type.descrizione });
          });
          setSelectOperationTypes(select_options);

          // Recupero la lista delle priorità
          axios.post(window.SERVER_URL+'activity_priorities/list', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setPriorities(res.data.activity_priorities);
            let select_options = [];
            res.data.activity_priorities.map((priority) => {
              select_options.push({ value: priority.id, label: priority.descrizione });
            });
            setSelectPriorities(select_options);

            // Recupero la lista dei fornitori
            axios.post(window.SERVER_URL+'suppliers/list', {}, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setSuppliers(res.data.suppliers);
              let select_options = [];
              res.data.suppliers.map((sup) => {
                select_options.push({ value: sup.id, label: sup.ragione_sociale });
              });
              setSelectSuppliers(select_options);

              // Recupero la lista dei comuni
              axios.post(window.SERVER_URL+'locations/towns', {}, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                setTowns(res.data);
                let select_options = [];
                res.data.map((town) => {
                  select_options.push({ value: town.nome+' ('+town.province.sigla+')', label: town.nome+' ('+town.province.sigla+')' });
                });
                setSelectTowns(select_options);

                getPending(); // recupero la lista degli interventi pending
              });
            });
          });
        });
      });
    } else {
      filterActivities();
      setWait(false);
    }
  }, [selectedOperationTypes, selectedSuppliers, selectedPriorities, selectedTowns]);

  const getPending = () => {
    setWait(true);
    axios.post(window.SERVER_URL+'activities/pending', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data) {
        let tmp_checks = [];
        setOrigChecks(res.data);
        console.log(res.data);
        let locations_not_ok = "";
        res.data.forEach(function(act) {
          var tmp_op_ids;
          tmp_op_ids = [];
          if (act.activity_operations && act.activity_operations.length) {
            if (act.location.lat && act.location.lng && (act.location.lng > 8 && act.location.lng < 15 && act.location.lat < 48 && act.location.lat > 35)) {
              act.activity_operations.forEach(function(op) {
                tmp_op_ids.push(op.operation_id);
              });
              tmp_checks.push({
                id: act.id.toString().padStart(12, "0"), 
                lat: parseFloat(act.location.lat.toFixed(6)),
                lng: parseFloat(act.location.lng.toFixed(6)), 
                name: act.location.denominazione, 
                address: act.location.indirizzo+(act.location.town ? ' - '+act.location.town.nome : ''), 
                priority: act.activity_priority.descrizione,
                num_operations: act.activity_operations.length,
                time_req: act.durata,
                op_ids: tmp_op_ids,
                sup_ids: act.suppliers,
                activity_operations: act.activity_operations,
                contact: act.contact,
                town: (act.location.town ? act.location.town.nome : '')
              });
            } else {
              locations_not_ok += "Ubicazione <b>"+act.location.denominazione+"</b>: coordinate non valide<br>";
              // console.log("L'ubicazione <b>"+act.location.denominazione+"</b> non ha coordinate valide e quindi non verrà mostrata sulla mappa");
            }
          }
        });
        if (locations_not_ok) {
          Swal.fire({
            icon: 'warning',
            title: 'Attenzione',
            html: locations_not_ok
          });
        }
        setChecks(tmp_checks);
        console.log("CHECKS");
        console.log(tmp_checks);
        setFilteredChecks(tmp_checks);
        setWait(false);
      }
    });
  }

  const setPlan = (tmp_routed_checks) => {
    console.log("STO DENTRO LA CHIAMATA SET_PLAN");
    axios.post(window.SERVER_URL+'optimo/get_plan', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      // 'Access-Control-Allow-Headers': '*',
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json',
      // 'Access-Control-Allow-Credentials': true,
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      res = res.data;
      // res = JSON.parse(res.data);
      console.log(">>> RISULTATO ELABORAZIONE:");
      console.log(res);
      if (res.routes && res.routes != "false") {
        setWaitPercent(100);

        console.log("STOP RICHIESTI:");
        console.log(tmp_routed_checks);
        let stops_requested = Object.keys(tmp_routed_checks).length
        let stops_planned = 0;
        let planned_ids = [];
        res.routes[0].stops.forEach(function(stop) {
          if (!stop.orderNo.startsWith("break")) {
            stops_planned++;
            planned_ids.push(stop.orderNo);
          }
        });

        console.log("PLANNED IDS:");
        console.log(planned_ids);

        // VADO A VERIFICARE SE GLI STOP RICHIESTI SONO PRESENTI TRA QUELLI PIANIFICATI
        // SE NON SONO PRESENTI LI ELIMINO IN MODO DA NON FARLI RIENTRARE NELLA PIANIFICAZIONE
        let real_tmp_routed_checks = {};
        for (const [key, check] of Object.entries(tmp_routed_checks)) {
          if (planned_ids.includes(check.id)) {
            real_tmp_routed_checks[key] = check;
          }
        }
        // tmp_routed_checks = tmp_routed_checks.filter(check => planned_ids.includes(check.id));
       
        console.log("TAPPE RICHIESTE: "+stops_requested);
        console.log("TAPPE ELABORATE: "+stops_planned);
        console.log("TAPPE INSERITE IN PIANIFICAZIONE: "+Object.keys(real_tmp_routed_checks).length);
        
        console.log("ROUTES:");
        console.log(res.routes);
        setRoutes(res.routes);
        console.log("ROUTED CHECKS:");
        console.log(real_tmp_routed_checks);
        setRoutedChecks(real_tmp_routed_checks);

        setWait(false);
        setWaitPercent(null);
        Swal.fire({
          icon: 'success',
          title: 'Elaborazione completata',
          html: 'Il percorso è stato elaborato correttamente<br />Sono stati pianificati <b>'+stops_planned+'</b> interventi sui <b>'+stops_requested+'</b> selezionati'
        });
      } else {
        setWait(false);
        setWaitPercent(null);
        Swal.fire({
          icon: 'error',
          title: 'Attenzione',
          html: 'Non è stato possibile elaborare un percorso.<br /><b>Routes:</b> '+res.routes
        });
      }
    }).catch(function (error) {
      setWait(false);
      setWaitPercent(null);
      Swal.fire({
        icon: 'error',
        title: 'Attenzione',
        html: 'La chiamata di ottimizzazione percorso non è andata a buon fine<br /><b>Error:</b> '+error
      });
    });
  }

  async function checkPlan(planID, tmp_routed_checks) {  
    var intervalId = setInterval(async () => {
      console.log("INTERVAL ID: "+intervalId);
      try {
        const res = await axios.post(window.SERVER_URL+'optimo/check_plan', { planID }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log("Controllo lo stato del plan: "+planID);
          res = JSON.parse(res.data);
          console.log(res);
          // Stato del plan: {"status":"F","percentageComplete":100,"success":true}
          if (res.status == 'F' && res.percentageComplete == 100) {
            setWaitPercent(95);
            console.log(">>> OTTIMIZZAZIONE PERCORSO COMPLETATA");
            console.log("CLEAR INTERVAL ID: "+intervalId);
            console.log("AVVIO LA CHIAMATA SET_PLAN");
            setPlan(tmp_routed_checks);
            clearInterval(intervalId);
          }
        });
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }, 1000);
  }

  const optimize = (points) => {
    setRoutes([]);
    if (points.length) {
      if (driverStartLat && driverStartLng && selectedUser) {
        setWaitPercent(0);
        setWait(true);
        // RECUPERO I DATI DELL'UTENTE SCELTO
        axios.post(window.SERVER_URL+'users/get', { id: selectedUser }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          var userInfo = res.data;
          console.log("USER INFO:");
          console.log(userInfo);
          if (userInfo.inizio_lavoro && userInfo.fine_lavoro) {
            // CANCELLO GLI INTERVENTI PRECEDENTI
            axios.post(window.SERVER_URL+'optimo/delete_orders', { }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              if (res.data.success) {
                setWaitPercent(5);
                // AGGIORNO IL DRIVER
                var dataForm = new FormData();
                dataForm.append('driver_start_pos_add', driverStartAddress);
                dataForm.append('driver_start_pos_lat', driverStartLat);
                dataForm.append('driver_start_pos_lng', driverStartLng);
                dataForm.append('driver_end_pos_add', driverEndAddress);
                dataForm.append('driver_end_pos_lat', driverEndLat);
                dataForm.append('driver_end_pos_lng', driverEndLng);
                dataForm.append('driver_wt_start', userInfo.inizio_lavoro);
                dataForm.append('driver_wt_end', userInfo.fine_lavoro);
                axios.post(window.SERVER_URL+'optimo/update_driver', dataForm, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  console.log(res.data);
                  if (res.data.success) {
                    setWaitPercent(10);
                    // CARICO TUTTI GLI INTERVENTI
                    var geopoints = [];
                    var tmp_routed_checks = {};

                    points.forEach(function(point, i) {
                      checks.forEach(function(check, c) {
                        if (check.id == point) {
                          geopoints.push({
                            "address": check.address,
                            "latitude": check.lat,
                            "longitude": check.lng,
                            "locationName": check.name,
                            "locationNo": check.id,
                            "valid": true,
                            "notes": "-",
                            "checkInTime": 0,
                            "time_req": check.time_req,
                            "start_work": userInfo.inizio_lavoro,
                            "end_work": userInfo.fine_lavoro
                          });
                          tmp_routed_checks[check.id] = check;
                        }
                      });
                    });

                    const promises = [];
                    geopoints.forEach(function(geopoint) {
                      let axios_call = axios.post(window.SERVER_URL+'optimo/insert_order', { 'loc': geopoint }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(res => {
                        console.log(res.data);
                        if (res.data.success) {
                          setWaitPercent(prevWaitPercent => prevWaitPercent + Math.floor(70 / geopoints.length));
                        }
                      });
                      promises.push(axios_call);
                    });

                    Promise.all(promises).then((results) => {
                      // SE TUTTI GLI INTERVENTI SONO STATI CARICATI
                      axios.post(window.SERVER_URL+'optimo/start_plan', {}, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(res => {
                        console.log(res.data);
                        if (res.data.success) {
                          setWaitPercent(prevWaitPercent => prevWaitPercent + 5);
                          let planID = res.data.planningId;
                          checkPlan(planID, tmp_routed_checks);
                        } else {
                          setWaitPercent(null);
                          setWait(false);
                          Swal.fire({
                            icon: 'error',
                            title: 'Errore',
                            text: 'Impossibile avviare la pianificazione'
                          });
                        }
                      });
                    }).catch((error) => {
                      setWaitPercent(null);
                      setWait(false);
                      Swal.fire({
                        icon: 'error',
                        title: 'Errore',
                        text: 'Impossibile caricare gli interventi: '+error
                      });
                    });
                  } else {
                    setWaitPercent(null);
                    setWait(false);
                    Swal.fire({
                      icon: 'error',
                      title: 'Errore',
                      text: 'Impossibile aggiornare partenza e arrivo del tecnico'
                    });
                  }
                });
              } else {
                setWaitPercent(null);
                setWait(false);
                Swal.fire({
                  icon: 'error',
                  title: 'Errore',
                  text: 'Impossibile cancellare gli ordini pianificati in precedenza'
                });
              }
            });
          } else {
            setWaitPercent(null);
            setWait(false);
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "L'utente selezionato non ha degli orari di lavoro validi"
            });
          }
        });

                      /*
                      var geopoints = [];
                      var tmp_routed_checks = {};
                      points.forEach(function(point, i) {
                        checks.forEach(function(check, c) {
                          if (check.id == point) {
                            geopoints.push({
                              "address": check.address,
                              "latitude": check.lat,
                              "longitude": check.lng,
                              "locationName": check.name,
                              "locationNo": check.id,
                              "valid": true,
                              "notes": "",
                              "checkInTime": 0,
                              "time_req": check.time_req
                            });
                            tmp_routed_checks[check.id] = check;
                          }
                        });
                      });

                      var dataForm = new FormData();
                      dataForm.append('geopoints', JSON.stringify(geopoints));
                      dataForm.append('driver_start_pos_lat', driverStartLat);
                      dataForm.append('driver_start_pos_lng', driverStartLng);
                      dataForm.append('driver_end_pos_lat', driverEndLat);
                      dataForm.append('driver_end_pos_lng', driverEndLng);
                      axios({
                        method: 'POST',
                        url: window.OPTIMO_URL,
                        data: dataForm,
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          'Content-Type': 'multipart/form-data'
                        }
                      })
                      .then(res => {
                        if (res.data.routes && res.data.routes != "false") {
                          console.log("ROUTES:");
                          console.log(res.data.routes);
                          setRoutes(res.data.routes);
                          console.log("ROUTED CHECKS:");
                          console.log(tmp_routed_checks);
                          setRoutedChecks(tmp_routed_checks);
                        } else {
                          Swal.fire({
                            icon: 'error',
                            title: 'Attenzione',
                            text: 'Non è stato possibile elaborare un percorso'
                          });
                        }
                        // setWait(false);
                      }).catch(function (error) {
                        console.log(error);
                      });
                      */
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "Seleziona correttamente il punto di partenza, di arrivo e l'utente incaricato"
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Non hai selezionato punti da ottimizzare'
      });
    }
  }

  const handleChangeSelectedOperationTypes = (ar_types) => {
    setSelectedOperationTypes(ar_types);
    console.log("SELECTED TYPES:");
    console.log(ar_types);
  }

  const handleChangeSelectedSuppliers = (ar_suppliers) => {
    setSelectedSuppliers(ar_suppliers);
    console.log("SELECTED SUPPLIERS:");
    console.log(ar_suppliers);
  }

  const handleChangeSelectedPriorities = (ar_priorities) => {
    setSelectedPriorities(ar_priorities);
  }

  const handleChangeSelectedTowns = (ar_towns) => {
    setSelectedTowns(ar_towns);
    console.log("SELECTED TOWNS:");
    console.log(ar_towns);
  }

  const filterActivities = () => {
    var tmp_filtered_checks = checks;

    // Filtro per tipologia
    if (selectedOperationTypes && selectedOperationTypes.length) {
      let ar_type_ids = [];
      selectedOperationTypes.forEach(function(type) {
        ar_type_ids.push(type.value);
      });
      console.log("**FILTER >> SELECTED TYPES:");
      console.log(ar_type_ids);
      tmp_filtered_checks = tmp_filtered_checks.filter(act => ar_type_ids.some(id => act.op_ids.includes(id)));
    }

    // Filtro per fornitore
    if (selectedSuppliers && selectedSuppliers.length) {
      let ar_supplier_ids = [];
      selectedSuppliers.forEach(function(sup) {
        ar_supplier_ids.push(sup.value);
      });
      console.log("**FILTER >> SELECTED SUPPLIERS:");
      console.log(ar_supplier_ids);
      tmp_filtered_checks = tmp_filtered_checks.filter(act => ar_supplier_ids.some(id => act.sup_ids.includes(id)));
    }

    // Filtro per priorità
    // TO DO

    // Filtro per comune
    if (selectedTowns && selectedTowns.length) {
      let ar_town_names = [];
      selectedTowns.forEach(function(town) {
        ar_town_names.push(town.value);
      });
      console.log("**FILTER >> SELECTED TOWNS:");
      console.log(ar_town_names);
      tmp_filtered_checks = tmp_filtered_checks.filter(act => ar_town_names.includes(act.town));
    }

    setFilteredChecks(tmp_filtered_checks);
  }

  /*
  const addPoint = (point_to_insert) => {
    console.log(point_to_insert);
    if (!points.includes(point_to_insert))
      setPoints([...points, point_to_insert]);
    console.log(points);
  }

  const delPoint = (point_to_remove) => {
    console.log(point_to_remove);
    setPoints(points.filter(point => point_to_remove !== point));
    console.log(points);
  }
  */

  const render = (status) => {
    if (status === Status.SUCCESS && checks.length) {
      return <Map key={mapKey} center={center} zoom={zoom} startPoint={startPoint} endPoint={endPoint} distKm={distKm} checks={filteredChecks} width={'100%'} height={'600px'} optimize={optimize} />;
    }
  }

  const geocode_point = (address) => {
    return axios.get(window.GMAPS_GEOCODE_URL+address+'&key='+window.GMAPS_APIKEY);
  }

  const geocode_start_point = (address) => {
    let driver_start_pos = geocode_point(address);
    driver_start_pos.then(response => {
      setDriverStartAddress(address);
      setDriverStartLat(response.data.results[0].geometry.location.lat);
      setDriverStartLng(response.data.results[0].geometry.location.lng);
    }).catch(error => {
      console.error(error);
    });    
  }

  const geocode_end_point = (address) => {
    let driver_end_pos = geocode_point(address);
    driver_end_pos.then(response => {
      setDriverEndAddress(address);
      setDriverEndLat(response.data.results[0].geometry.location.lat);
      setDriverEndLng(response.data.results[0].geometry.location.lng);
    }).catch(error => {
      console.error(error);
    });    
  }

  const onSubmit = (data) => {
    if (!selectedStartTown) {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un comune di partenza'
      });
    } else if (!selectedEndTown) {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un comune di arrivo'
      });
    } else if (!selectedUser) {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un tecnico incaricato'
      });
    } else if (!data.distKm) {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un raggio in km'
      });
    } else {
      setStartPoint(selectedStartTown.label);
      geocode_start_point(selectedStartTown.label);
      setEndPoint(selectedEndTown.label);
      geocode_end_point(selectedEndTown.label);
      setDistKm(data.distKm);
      setMapKey(prev => prev + 1);

      render();
    }

    /*
    Promise.all([geocode(data.start_point), geocode(data.end_point)]).then(function(results) {
      console.log(results[0]);
      console.log(results[1]);
    });
    */
  }

  const giveMeRealIndex = (orderNo) => {
    if (routes && routes[0] && routes[0].stops) {
      let found = false;
      let index = 1;
      routes[0].stops.forEach(function(stop) {
        if (stop.orderNo != orderNo && !found) {
          if (!stop.orderNo.startsWith("break"))
            index++;
        } else {
          found = true;
        }
      });
      return index;
    }
  }

  const restartPlan = () => {
    setZoom(10);
    setCenter({ lat: 42.9705321, lng: 13.7150639 });
    setStartPoint(null);
    setEndPoint(null);
    setDistKm(null);
    setMapKey(0);
    setRoutes(null);
    setOrigChecks([]);
    setChecks([]);
    setFilteredChecks([]);
    setRoutedChecks({});
    setDriverStartLat(null);
    setDriverStartLng(null);
    setDriverEndLat(null);
    setDriverEndLng(null);
    setSelectedPriorities([]);
    setSelectedOperationTypes(null);
    setSelectedUser(null);
    setSelectedPathDate(null);
    let fields = {
      'start_point': null,
      'end_point': null,
      'distKm': null,
    };
    reset(fields);
    getPending();
  }

  const convalidate = () => {
    if (selectedUser && selectedPathDate) {
      console.log("PATH DATE: "+selectedPathDate);
      Swal.fire({
        icon: 'question',
        title: 'Vuoi convalidare la pianificazione?',
        text: '',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Procedi',
        // denyButtonText: 'Annulla',
        cancelButtonText: 'Annulla',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          var dataForm = new FormData();
          dataForm.append('paths[data]', selectedPathDate);
          dataForm.append('paths[user_id]', selectedUser.value);
          dataForm.append('paths[partenza]', JSON.stringify({'localita':startPoint,'lat':driverStartLat,'lng':driverStartLng}));
          dataForm.append('paths[arrivo]', JSON.stringify({'localita':endPoint,'lat':driverEndLat,'lng':driverEndLng}));
          dataForm.append('paths[tappe]', JSON.stringify(routes));
          dataForm.append('interventi', JSON.stringify(routedChecks));
          axios({
            method: 'POST',
            url: window.SERVER_URL+'paths/save',
            data: dataForm,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            if (res.data.ret.success) {
              console.log(res.data.ret);
              Swal.fire({
                icon: 'success',
                title: 'Pianificazione convalidata e registrata',
                text: 'Cosa vuoi fare adesso?',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Stampa',
                denyButtonText: 'Nuova pianificazione',
                cancelButtonText: 'Torna alla home',
                confirmButtonColor: '#198754',
                cancelButtonColor: '#3085d6',
                denyButtonColor: '#3085d6',
              }).then((result) => {
                console.log(result);
                if (result.isConfirmed) {
                  window.open(window.SERVER_URL+'paths/export/'+res.data.ret.path_id);
                  navigate('/paths/add', {state: {path_id: res.data.ret.path_id}})
                } else if (result.isDenied) {
                  restartPlan();
                } else {
                  navigate('/dashboard');
                }
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Attenzione',
                text: 'Si è verificato un errore durante la convalida della pianificazione'
              });
            }
          }).catch(function (error) {
            console.log(error);
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Attenzione',
        text: 'Devi selezionare un utente e una data a cui assegnare la pianificazione'
      });
    }
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Pianifica interventi</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <p className="fieldset_title"><DynamicIcon iconName="FaListUl" size="20" /> FILTRO INTERVENTI</p>
                  <Row>
                    <Col>
                      <label style={{textAlign: 'left'}}>Tipi interventi</label>
                      <Select value={selectedOperationTypes} isMulti isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="operation_type_id" options={selectOperationTypes} onChange={(e) => handleChangeSelectedOperationTypes(e)} />
                    </Col>
                    <Col>
                      <label style={{textAlign: 'left'}}>Fornitori</label>
                      <Select value={selectedSuppliers} isMulti isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="supplier_id" options={selectSuppliers} onChange={(e) => handleChangeSelectedSuppliers(e)} /> 
                    </Col>
                    <Col lg={6}>
                      <label style={{textAlign: 'left', 'marginRight': '10px'}}>Comuni</label>
                      <Select value={selectedTowns} isMulti isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="town_id" options={selectTowns} onChange={(e) => handleChangeSelectedTowns(e)} /> 
                    </Col>
                    {/* 
                    <Col>
                      <label style={{textAlign: 'left'}}>Priorità</label>
                      <Select value={selectedPriorities} isMulti isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="priority_id" options={selectPriorities} onChange={(e) => handleChangeSelectedPriorities(e)} /> 
                    </Col>
                    */}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="fieldset_title space_top"><DynamicIcon iconName="PiPathBold" /> IMPOSTAZIONI PERCORSO</p>
                  <Row>
                    <Col>
                      <label style={{textAlign: 'left', 'marginRight': '10px'}}>Comune di partenza</label>
                      <Select value={selectedStartTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="start_point" options={selectTowns} onChange={(e) => setSelectedStartTown(e)} /> 
                      {/* <input {...register("start_point")} /> */}
                      {/* {errors.start_point && <span>Devi inserire il comune di partenza</span>} */}
                    </Col>
                    <Col>
                      <label style={{textAlign: 'left', 'marginRight': '10px'}}>Comune di arrivo</label>
                      <Select value={selectedEndTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="start_point" options={selectTowns} onChange={(e) => setSelectedEndTown(e)} /> 
                      {/* <input {...register("end_point")} /> */}
                      {/* {errors.end_point && <span>Devi inserire il comune di arrivo</span>} */}
                    </Col>
                    <Col lg={3}>
                      <label style={{textAlign: 'left', 'marginRight': '10px'}}>Dist. dal percorso (in km)</label>
                      <input type="number" step="0.1" {...register("distKm")} />
                      {errors.distKm && <span>Devi inserire la distanza dal percorso</span>}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <p className="fieldset_title space_top"><DynamicIcon iconName="FaUser" size="20" /> TECNICO INCARICATO</p>
                  <Row>
                    <Col>
                      <label style={{textAlign: 'left'}}>Utenti</label>
                      <Select value={selectedUser} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="user_id" options={selectUsers} onChange={(e) => setSelectedUser(e)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col style={{'textAlign':'center'}}>
                  <Button style={{'width':'200px','padding':'6px 20px','margin':'18px auto 0'}} type="submit" variant="primary" className="waste_btn">Aggiorna mappa</Button>
                </Col>
              </Row>
            </form>

            <br /><hr />
            <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>

            {/*
            <br /><br /> <br />
            <Button onClick={() => { addPoint(Math.floor(Math.random() * 10)); }}>Aggiungi valore</Button>
            <Button onClick={() => { delPoint(Math.floor(Math.random() * 10)); }}>Rimuovi valore</Button>
            <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/news/add'); }}><BiPlus /> lorem ipsum</Button>
            <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/inspections/add'); }}><BiPlus /> lorem ipsum</Button>
            */}
          </Col>
        </Row>
        <Row>
          <Col>
            { routes && routes.length && routedChecks ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{ 'width':'50px' }}></th>
                      <th style={{ 'width':'350px' }}></th>
                      <th>Nome e indirizzo</th>
                      <th style={{'width':'250px'}}>Coordinate</th>
                      <th style={{ 'width':'200px' }}>Recapiti</th>
                      <th style={{ 'width':'120px' }}>Dist</th>
                      <th style={{ 'width':'120px' }}>Tempo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ 'textAlign':'center' }}><FaFlagCheckered style={{ 'fontSize':'20px' }} /></td>
                      <td>Partenza</td>
                      <td><DynamicIcon iconName="FaMapMarker" /> { startPoint }</td>
                      <td>
                        { driverStartLat }, { driverStartLng }
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    { routes[0] && routes[0].stops ? routes[0].stops.map((stop, i) => {
                      return (
                        <>
                          { !stop.orderNo.startsWith("break") ? 
                            <>
                              <tr key={ stop.orderNo } style={{'borderTop':'3px solid #000'}}>
                                <td style={{'textAlign':'center'}}><FaCarAlt style={{'fontSize':'20px'}} /></td>
                                <td>Spostamento a Intervento #{giveMeRealIndex(stop.orderNo)}</td>
                                <td>
                                  <DynamicIcon iconName="FaUserAlt" /> { stop.locationName }<br />
                                  <DynamicIcon iconName="FaMapMarker" /> { stop.address }
                                </td>
                                <td>
                                  { stop.latitude }, { stop.longitude }
                                </td>
                                <td>
                                  { routedChecks[stop.orderNo].contact && routedChecks[stop.orderNo].contact[0] && routedChecks[stop.orderNo].contact[0].telefono ?
                                    <>
                                      <DynamicIcon iconName="BsTelephoneFill" /> &nbsp;
                                      { routedChecks[stop.orderNo].contact[0].telefono.startsWith("73") ? '0' : ''}{ routedChecks[stop.orderNo].contact[0].telefono }
                                      <br />
                                    </>
                                  : '' }
                                  { routedChecks[stop.orderNo].contact && routedChecks[stop.orderNo].contact[0] && routedChecks[stop.orderNo].contact[0].cellulare ?
                                    <>
                                      <DynamicIcon iconName="BsTelephoneFill" />  &nbsp;
                                      { routedChecks[stop.orderNo].contact[0].cellulare }
                                    </>
                                  : '' }
                                </td>
                                <td>{ Math.round(stop.distance / 1000) } km</td>
                                <td>{ Math.round(stop.travelTime / 60) } min</td>
                              </tr>
                              <tr className="grey" key={ stop.orderNo+"bis" }>
                                <td style={{'textAlign':'center'}}><FaWrench style={{'fontSize':'20px'}} /></td>
                                <td className="tdcol">
                                  Intervento #{giveMeRealIndex(stop.orderNo)} - <FaClock /> Previsto per le { stop.scheduledAt }
                                </td>
                                <td colSpan="4" className="tdcol">
                                  { routedChecks[stop.orderNo] && routedChecks[stop.orderNo].activity_operations ? routedChecks[stop.orderNo].activity_operations.map((act) => 
                                    <>
                                      <span><GoDot /> { act.descrizione } - <FaClock /> Tempo { act.operation.durata } min - <DynamicIcon iconName="FaSuitcase" /> { act.supplier.ragione_sociale }</span>
                                    </>
                                  ) : ''}
                                </td>
                                <td>{ routedChecks[stop.orderNo] && routedChecks[stop.orderNo].activity_operations ? 
                                        (routedChecks[stop.orderNo].time_req > 60 ? `${Math.floor(routedChecks[stop.orderNo].time_req / 60)} ore ${routedChecks[stop.orderNo].time_req % 60} min` : routedChecks[stop.orderNo].time_req+' min') 
                                      : '' }
                                </td>
                              </tr>
                            </>
                          : 
                          <tr style={{'borderTop':'3px solid #000'}}>
                            <td style={{'textAlign':'center','background':'#f9e548'}}><FaCoffee style={{'fontSize':'22px'}} /></td>
                            <td style={{'background':'#f9e548'}}></td>
                            <td style={{'background':'#f9e548','textAlign':'center','fontSize':'20px','fontWeight':'600'}}>PAUSA PRANZO</td>
                            <td colSpan="3" style={{'background':'#f9e548'}}></td>
                            <td style={{'background':'#f9e548'}}>60 min</td>
                          </tr>
                          }
                        </>
                      );
                    }) : '' }
                    <tr style={{'borderTop':'3px solid #000'}}>
                      <td style={{ 'textAlign':'center' }}><FaFlagCheckered style={{ 'fontSize':'20px' }} /></td>
                      <td>Arrivo</td>
                      <td><DynamicIcon iconName="FaMapMarker" /> { endPoint }</td>
                      <td>
                        { driverEndLat }, { driverEndLng }
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
                <Row style={{'background':'#f6f6f6','padding':'20px'}}>
                  <Col style={{'paddingLeft':'40px'}}>
                    <label style={{textAlign:'left','display':'block'}}>Data</label>
                    <input type="date" name="data" onChange={(e) => setSelectedPathDate(e.target.value)} style={{'padding':'7px 20px','borderRadius':'3px','border':'1px solid #ccc'}} />
                  </Col>
                  <Col style={{'textAlign':'center'}}>
                    <Button variant="primary" className="waste_btn" style={{'display':'block','width':'320px','marginTop':'20px'}} onClick={() => { convalidate(); }}>Convalida pianificazione</Button>
                  </Col>
                </Row>
              </>
               : '' }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Plan;