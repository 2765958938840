import React from 'react';
// REACT ROUTER
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// AUTH
import { AuthProvider, RequireAuth } from './components/contexts/AuthContext';
// LAYOUT
import Main from './components/main.js';
// FRONT
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import Customers from './components/pages/customers';
import CustomersAdd from './components/pages/customers_add';
import Suppliers from './components/pages/suppliers';
import SuppliersAdd from './components/pages/suppliers_add';
import Makers from './components/pages/makers';
import MakersAdd from './components/pages/makers_add';
import Locations from './components/pages/locations';
import LocationsAdd from './components/pages/locations_add';
import Tanks from './components/pages/tanks';
import TanksAdd from './components/pages/tanks_add';
import TankTypes from './components/pages/tank_types';
import TankTypesAdd from './components/pages/tank_types_add';
import Valves from './components/pages/valves';
import ValvesAdd from './components/pages/valves_add';
import ValveTypes from './components/pages/valve_types';
import ValveTypesAdd from './components/pages/valve_types_add';
import Meters from './components/pages/meters';
import MetersAdd from './components/pages/meters_add';
import MeterTypes from './components/pages/meter_types';
import MeterTypesAdd from './components/pages/meter_types_add';
import Extinguishers from './components/pages/extinguishers';
import ExtinguishersAdd from './components/pages/extinguishers_add';
import ExtinguisherTypes from './components/pages/extinguisher_types';
import ExtinguisherTypesAdd from './components/pages/extinguisher_types_add';
import ExtinguisherActivitiesAdd from './components/pages/extinguisher_activities_add';
import Activities from './components/pages/activities';
import ActivitiesAdd from './components/pages/activities_add';
import ActivityOperationsAdd from './components/pages/activity_operations_add';
import Plan from './components/pages/plan';
import Operations from './components/pages/operations';
import OperationsAdd from './components/pages/operations_add';
import OperationTypes from './components/pages/operation_types';
import OperationTypesAdd from './components/pages/operation_types_add';
import Users from './components/pages/users';
import UsersAdd from './components/pages/users_add';
import Stocks from './components/pages/stocks';
import StocksAdd from './components/pages/stocks_add';
import StockTypes from './components/pages/stock_types';
import StockTypesAdd from './components/pages/stock_types_add';
import StockHandlings from './components/pages/stock_handlings';
import StockHandlingsAdd from './components/pages/stock_handlings_add';
import MeasurementUnits from './components/pages/measurement_units';
import MeasurementUnitsAdd from './components/pages/measurement_unit_add';
import Regulations from './components/pages/regulations';
import RegulationsAdd from './components/pages/regulations_add';
import RegulationDescriptions from './components/pages/regulation_descriptions';
import RegulationDescriptionsAdd from './components/pages/regulation_descriptions_add';
import RegulationsTanksAdd from './components/pages/regulations_tanks_add';
import TaxRates from './components/pages/tax_rates';
import OperationSuppliers from './components/pages/operation_suppliers';
import OperationSuppliersAdd from './components/pages/operation_suppliers_add';
import ImportCustomers from './components/pages/import_customers';
import ImportMeters from './components/pages/import_meters';
import ImportJobs from './components/pages/import_jobs';
import ImportValves from './components/pages/import_valves';
import ImportActivities from './components/pages/import_activities';
import Paths from './components/pages/paths';
import PathsAdd from './components/pages/paths_add';
import DeleteActivityOperations from './components/pages/delete_activity_operations';
import DeliveryNotes from './components/pages/delivery_notes';
import DeliveryNotesAdd from './components/pages/delivery_notes_add';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
    <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route element={<Main />}>
                    {/* FRONT */}
                    <Route path="/" element={<Login  />} />
                    <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
                    <Route path="/customers" element={<RequireAuth><Customers /></RequireAuth>} />
                    <Route path="/customers/add" element={<RequireAuth><CustomersAdd /></RequireAuth>} />
                    <Route path="/suppliers" element={<RequireAuth><Suppliers /></RequireAuth>} />
                    <Route path="/suppliers/add" element={<RequireAuth><SuppliersAdd /></RequireAuth>} />
                    <Route path="/makers" element={<RequireAuth><Makers /></RequireAuth>} />
                    <Route path="/makers/add" element={<RequireAuth><MakersAdd /></RequireAuth>} />
                    <Route path="/locations" element={<RequireAuth><Locations /></RequireAuth>} />
                    <Route path="/locations/add" element={<RequireAuth><LocationsAdd /></RequireAuth>} />
                    <Route path="/tanks" element={<RequireAuth><Tanks /></RequireAuth>} />
                    <Route path="/tanks/add" element={<RequireAuth><TanksAdd /></RequireAuth>} />
                    <Route path="/tank_types" element={<RequireAuth><TankTypes /></RequireAuth>} />
                    <Route path="/tank_types/add" element={<RequireAuth><TankTypesAdd /></RequireAuth>} />
                    <Route path="/valves" element={<RequireAuth><Valves /></RequireAuth>} />
                    <Route path="/valves/add" element={<RequireAuth><ValvesAdd /></RequireAuth>} />
                    <Route path="/valve_types" element={<RequireAuth><ValveTypes /></RequireAuth>} />
                    <Route path="/valve_types/add" element={<RequireAuth><ValveTypesAdd /></RequireAuth>} />
                    <Route path="/meters" element={<RequireAuth><Meters /></RequireAuth>} />
                    <Route path="/meters/add" element={<RequireAuth><MetersAdd /></RequireAuth>} />
                    <Route path="/meter_types" element={<RequireAuth><MeterTypes /></RequireAuth>} />
                    <Route path="/meter_types/add" element={<RequireAuth><MeterTypesAdd /></RequireAuth>} />
                    <Route path="/extinguishers" element={<RequireAuth><Extinguishers /></RequireAuth>} />
                    <Route path="/extinguishers/add" element={<RequireAuth><ExtinguishersAdd /></RequireAuth>} />
                    <Route path="/extinguisher_types" element={<RequireAuth><ExtinguisherTypes /></RequireAuth>} />
                    <Route path="/extinguisher_types/add" element={<RequireAuth><ExtinguisherTypesAdd /></RequireAuth>} />
                    <Route path="/extinguisher_activities/add" element={<RequireAuth><ExtinguisherActivitiesAdd /></RequireAuth>} />
                    <Route path="/activities" element={<RequireAuth><Activities /></RequireAuth>} />
                    <Route path="/activities/add" element={<RequireAuth><ActivitiesAdd /></RequireAuth>} />
                    <Route path="/activity_operations/add" element={<RequireAuth><ActivityOperationsAdd /></RequireAuth>} />
                    <Route path="/plan" element={<RequireAuth><Plan /></RequireAuth>} />
                    <Route path="/operations" element={<RequireAuth><Operations /></RequireAuth>} />
                    <Route path="/operations/add" element={<RequireAuth><OperationsAdd /></RequireAuth>} />
                    <Route path="/operation_types" element={<RequireAuth><OperationTypes /></RequireAuth>} />
                    <Route path="/operation_types/add" element={<RequireAuth><OperationTypesAdd /></RequireAuth>} />
                    <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
                    <Route path="/users/add" element={<RequireAuth><UsersAdd /></RequireAuth>} />
                    <Route path="/stocks" element={<RequireAuth><Stocks /></RequireAuth>} />
                    <Route path="/stocks/add" element={<RequireAuth><StocksAdd /></RequireAuth>} />
                    <Route path="/stock_types" element={<RequireAuth><StockTypes /></RequireAuth>} />
                    <Route path="/stock_types/add" element={<RequireAuth><StockTypesAdd /></RequireAuth>} />
                    <Route path="/stock_handlings" element={<RequireAuth><StockHandlings /></RequireAuth>} />
                    <Route path="/stock_handlings/add" element={<RequireAuth><StockHandlingsAdd /></RequireAuth>} />
                    <Route path="/measurement_units" element={<RequireAuth><MeasurementUnits /></RequireAuth>} />
                    <Route path="/measurement_units/add" element={<RequireAuth><MeasurementUnitsAdd /></RequireAuth>} />
                    <Route path="/regulations" element={<RequireAuth><Regulations /></RequireAuth>} />
                    <Route path="/regulations/add" element={<RequireAuth><RegulationsAdd /></RequireAuth>} />
                    <Route path="/regulation_descriptions" element={<RequireAuth><RegulationDescriptions /></RequireAuth>} />
                    <Route path="/regulation_descriptions/add" element={<RequireAuth><RegulationDescriptionsAdd /></RequireAuth>} />
                    <Route path="/regulations_tanks/add" element={<RequireAuth><RegulationsTanksAdd /></RequireAuth>} />
                    <Route path="/tax_rates" element={<RequireAuth><TaxRates /></RequireAuth>} />
                    <Route path="/operation_suppliers" element={<RequireAuth><OperationSuppliers /></RequireAuth>} />
                    <Route path="/operation_suppliers/add" element={<RequireAuth><OperationSuppliersAdd /></RequireAuth>} />
                    <Route path="/import_customers" element={<RequireAuth><ImportCustomers /></RequireAuth>} />
                    <Route path="/import_meters" element={<RequireAuth><ImportMeters /></RequireAuth>} />
                    <Route path="/import_jobs" element={<RequireAuth><ImportJobs /></RequireAuth>} />
                    <Route path="/import_valves" element={<RequireAuth><ImportValves /></RequireAuth>} />
                    <Route path="/import_activities" element={<RequireAuth><ImportActivities /></RequireAuth>} />
                    <Route path="/paths" element={<RequireAuth><Paths /></RequireAuth>} />
                    <Route path="/paths/add" element={<RequireAuth><PathsAdd /></RequireAuth>} />
                    <Route path="/delete_activity_operations" element={<RequireAuth><DeleteActivityOperations /></RequireAuth>} />
                    <Route path="/delivery_notes" element={<RequireAuth><DeliveryNotes /></RequireAuth>} />
                    <Route path="/delivery_notes/add" element={<RequireAuth><DeliveryNotesAdd /></RequireAuth>} />
                </Route>
            </Routes>
        </BrowserRouter>
    </AuthProvider>
);