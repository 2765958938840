import React, { useContext, useState } from 'react';
// REACT ROUTER
import { Navigate } from "react-router-dom";


export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  let [authobj, setAuthobj] = useLocalStorage('authobj', null);
  let [globalRefresh, setGlobalRefresh] = useState(null);
  let [lang, setLang] = useLocalStorage('lang', 'it');

  const lingue = window.LANGS;

  let signin = (authobj, callback) => {
    authobj.date_login = Math.floor(Date.now() / 1000);
    console.log(authobj);
    setAuthobj(authobj);
    // setLanguage(authobj.lingua);
    if (typeof callback === 'function')
      callback();
  };

  let signout = (callback) => {
    setAuthobj(null);
    if (typeof callback === 'function')
      callback();
  };

  let setLanguage = (lang_code, callback) => {
    // i18n.changeLanguage(lang_code);
    // setLang(lang_code);
    if (typeof callback === 'function')
      callback();
  }

  let value = { authobj, lingue, lang, setLanguage, signin, signout, globalRefresh, setGlobalRefresh };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const RequireAuth = ({ children }) => {
  const { authobj, signout } = useContext(AuthContext);
  const actual_path = window.location.pathname;

  // se non è autenticato
  let now = Math.floor(Date.now() / 1000);
  if (!authobj || !authobj.success || !authobj.token || !authobj.date_login || (actual_path.startsWith('/admin') && authobj.group_id > 2))
    return <Navigate to="/" />;
  if (authobj && authobj.date_login && (now - authobj.date_login) > window.MANTAIN_LOGIN_SECONDS) {
    signout();
  }

  return children;
}