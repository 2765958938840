import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function ExtinguisherActivitiesAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();

  const [activity, setActivity] = useState(null);
  const [extinguishers, setExtinguishers] = useState([]);
  const [selectExtinguishers, setSelectExtinguishers] = useState([]);
  const [selectedExtinguisher, setSelectedExtinguisher] = useState(null);
  const [extinguisherActivityTypes, setExtinguisherActivityTypes] = useState([]);

  useEffect(() => {
    // se sto aggiungendo o modificando un'attività per un estintore specifico
    if (state && state.extinguisher_id)
      setSelectedExtinguisher(state.extinguisher_id);

    // Carico la lista degli estintori
    axios.post(window.SERVER_URL+'extinguishers/list', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data.extinguishers);
      setExtinguishers(res.data.extinguishers);
      let select_options = [];
      res.data.extinguishers.map((ext) => {
        select_options.push({ value: ext.id, label: '['+ext.matricola+'] '+ext.extinguisher_type.descrizione+' ('+ext.peso+' kg)' });
      });
      setSelectExtinguishers(select_options);

      // Carico le tipologie di attività
      axios.post(window.SERVER_URL+'extinguisher_activity_types/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setExtinguisherActivityTypes(res.data.extinguisher_activity_types);
      
        // Se sto modificando una attività estintore esistente, carico le informazioni relative
        if (state && state.activity_id) {
            axios.post(window.SERVER_URL+'extinguisher_activities/get', { id: state.activity_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setActivity(res.data);

              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                fields[key] = val;
              reset(fields);
            });
          }
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    form_data.extinguisher_id = selectedExtinguisher;
    console.log(form_data);
    
    axios.post(window.SERVER_URL+'extinguisher_activities/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Activity': 'multipart/form-data'
      }
    }).then(res => {
      // console.log(res.data);
      navigate('/extinguishers/add', { state: { extinguisher_id: selectedExtinguisher } });
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica attività estintore</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Estintore</label>
                <Select value={state && state.selected_extinguisher ? state.selected_extinguisher : null} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="extinguisher_id" options={selectExtinguishers} onChange={(e) => setSelectedExtinguisher(e.value)} />
              </Col>
              <Col>
                <label>Tipologia</label>
                <select {...register("extinguisher_activity_type_id")}>
                  <option value="">- Scegli una tipologia -</option>
                  { extinguisherActivityTypes ? extinguisherActivityTypes.map((type) => {
                    return <option key={ type.id } value={ type.id }>{ type.descrizione }</option>
                  }) : '' }
              </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Data effettuazione</label>
                <input type="date" placeholder="Data effettuazione" {...register("data_effettuazione")} />
              </Col>
              <Col>
                <label>Note</label>
                <input type="text" placeholder="Note" {...register("note")} />
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate('/extinguisher_activities', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
    /* <a href="#" onClick={() => (fileRef.current.value = null)}>Deseleziona file</a> */
  );

  
}

export default ExtinguisherActivitiesAdd;