import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../partials/confirm.js";
// ICONS
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { FaTrash, FaSearch, FaUserAlt, FaRegCalendarAlt, FaMapMarkerAlt, FaClock, FaRoad } from 'react-icons/fa';
import { PiMagnifyingGlassBold, PiPrinterFill } from 'react-icons/pi';

function Paths() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // DATA
  const [paths, setPaths] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // MODAL
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    // document.getElementById('query').value = state && state.query ? state.query : query;
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'paths/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setPaths(res.data.paths);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleDelete = (path_id) => {
    setIdToDelete(path_id);
    Swal.fire({
      icon: 'question',
      title: 'Vuoi cancellare la pianificazione?',
      text: "L'operazione non è reversibile; tutte le attività incluse in questa pianificazione torneranno allo stato precedente. Scegli \"Procedi (con attività)\" per cancellare anche tutte le attività incluse nella pianificazione.",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Procedi',
      denyButtonText: 'Procedi (con attività)',
      cancelButtonText: 'Annulla',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        doDelete(path_id, false);
      }
      if (result.isDenied) {
        doDelete(path_id, true);
      }
    });
  }

  const doDelete = (path_id, with_activities) => {
    axios.post(window.SERVER_URL+'paths/delete', { id: path_id, attivita: with_activities }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        // se la cancellazione è andata a buon fine
        setPaths(paths.filter(path => path.id !== path_id));
        getPage(1, query);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Attenzione',
          text: 'Si è verificato un errore durante la cancellazione della pianificazione'
        });
        setIdToDelete(null);
      }
    });
  }

  const hasBreak = (path_obj) => {
    let has_break = false;
    path_obj.stops.forEach(function(stop) {
      if (stop.orderNo.startsWith("break"))
        has_break = true;
    });
    return has_break;
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Pianificazioni</h1>
          </Col>
        </Row>
        <Row className="action_bar top">
          <Col>
            <Button variant="success" onClick={() => { navigate('/plan'); }}><BiPlus /> Crea pianificazione</Button>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{margin:'0 20px'}}>
            <form className="custom_form" id="query_form">
              <FaSearch />
              <input type="text" placeholder="Cerca serbatoio" id="query" onChange={(e) => { handleInputChange(e.target.value) }} />
            </form>
          </Col>
        </Row> */}
        <Row>
          <Col>
            { paths ? 
              <>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th style={{'width':'120px'}}>Data</th>
                      <th>Partenza</th>
                      <th>Arrivo</th>
                      <th style={{'width':'90px'}}>Interventi</th>
                      <th style={{'width':'100px'}}>Distanza</th>
                      <th style={{'width':'150px'}}>Durata stimata</th>
                      <th>Assegnato a</th>
                      <th>Creato il</th>
                      <th style={{'width':'180px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { paths.length ? paths.map((path) => {
                        return <tr key={ path.id }>
                                <td>{ path.id }</td>
                                <td><FaRegCalendarAlt /> { `${new Date(path.data).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}` }</td>
                                <td>{ path.partenza.localita.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                                <td>{ path.arrivo.localita.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                                <td><FaMapMarkerAlt /> { hasBreak(path.tappe) ? (path.tappe.stops.length-1) : path.tappe.stops.length }</td>
                                <td><FaRoad /> { Math.round(path.tappe.distance) } km</td>
                                <td><FaClock /> { (path.tappe.duration > 60 ? `${Math.floor(path.tappe.duration / 60)} ore ${path.tappe.duration % 60} min` : path.tappe.duration+' min') }</td>
                                <td><FaUserAlt /> { path.user.nome } { path.user.cognome }</td>
                                <td>{ `${new Date(path.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` }</td>
                                <td className="actions">
                                  <Button onClick={() => { navigate('/paths/add', {state: {path_id: path.id, page: page, query: query}}) }}><PiMagnifyingGlassBold /></Button>
                                  <Button onClick={() => { window.open(window.SERVER_URL+'paths/export/'+path.id) }} title='Stampa itinerario'><PiPrinterFill /></Button>
                                  <Button onClick={() => { handleDelete(path.id) }} className="delete"><FaTrash /></Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna pianificazione presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Paths;