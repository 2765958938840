import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// ICONS
import { IoIosArrowBack } from 'react-icons/io';

function ExtinguisherActivitiesAdd() {
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();

  const [regulationTank, setRegulationTank] = useState(null);
  const [tanks, setTanks] = useState([]);
  const [selectTanks, setSelectTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [regulations, setRegulations] = useState([]);
  const [selectRegulations, setSelectRegulations] = useState([]);
  const [selectedRegulation, setSelectedRegulation] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [selectDescriptions, setSelectDescriptions] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState(null);

  useEffect(() => {
    // se sto aggiungendo o modificando una normativa per un serbatoio specifico
    if (state && state.tank_id && state.tank_label) {}
      setSelectedTank({ value: state.tank_id, label: state.tank_label });

    // Carico la lista dei serbatoi
    axios.post(window.SERVER_URL+'tanks/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data.tanks);
      setTanks(res.data.tanks);
      let select_options = [];
      res.data.tanks.map((tank) => {
        select_options.push({ value: tank.id, label: tank.matricola });
      });
      setSelectTanks(select_options);

      // Carico la lista delle normative
      axios.post(window.SERVER_URL+'regulations/list', {}, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTanks(res.data.regulations);
        let select_options = [];
        res.data.regulations.map((reg) => {
          select_options.push({ value: reg.id, label: reg.descrizione });
        });
        setSelectRegulations(select_options);

        // Carico la lista delle descrizioni normative
        axios.post(window.SERVER_URL+'regulation_descriptions/list', {}, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setDescriptions(res.data.regulation_descriptions);
          let select_options = [];
          res.data.regulation_descriptions.map((desc) => {
            select_options.push({ value: desc.id, label: desc.descrizione });
          });
          setSelectDescriptions(select_options);

          // Se sto modificando una normativa serbatoio esistente, carico le informazioni relative
          if (state && state.regulation_tank_id) {
            axios.post(window.SERVER_URL+'regulations_tanks/get', { id: state.regulation_tank_id }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              console.log(res.data);
              setRegulationTank(res.data);

              // Mi setto le select
              if (res.data.tank_id)
                setSelectedTank({ value: res.data.tank_id, label: res.data.tank.matricola });
              if (res.data.regulation_id)
                setSelectedRegulation({ value: res.data.regulation_id, label: res.data.regulation.descrizione });
              if (res.data.regulation_description_id)
                setSelectedDescription({ value: res.data.regulation_description_id, label: res.data.regulation_description.descrizione });

              // Setto i campi della form
              let fields = {};
              for (const [key, val] of Object.entries(res.data))
                if (typeof val !== 'object')
                  fields[key] = val;
              reset(fields);
            });
          }
        });
      });
    });
  }, [reset]);
  
  // Submit della form
  const onSubmit = (form_data) => {
    form_data.tank_id = selectedTank ? selectedTank.value : null;
    form_data.regulation_id = selectedRegulation ? selectedRegulation.value : null;
    form_data.regulation_description_id = selectedDescription ? selectedDescription.value : null;
    console.log(form_data);
    
    axios.post(window.SERVER_URL+'regulations_tanks/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Activity': 'multipart/form-data'
      }
    }).then(res => {
      navigate('/tanks/add', { state: { tank_id: selectedTank } });
    }).catch(function(error) {
      console.log(error);
    });
  }

  return (
    <Container className="content">
      <Row className="page_title">
        <Col>
          <h1>Aggiungi o modifica normativa serbatoio</h1>
        </Col>
      </Row>
      <Row>
        <Col className="custom_form_wrapper">
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Serbatoio</label>
                <Select value={selectedTank} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="tank_id" options={selectTanks} onChange={(e) => setSelectedTank(e)} />
              </Col>
              <Col>
                <label>Data certificazione</label>
                <input type="date" placeholder="Data certificazione" {...register("data_certificazione")} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Normativa</label>
                <Select value={selectedRegulation} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="regulation_id" options={selectRegulations} onChange={(e) => setSelectedRegulation(e)} />
              </Col>
              <Col>
                <label style={{'margin':'8px 0 18px'}}>Descrizione normativa</label>
                <Select value={selectedDescription} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="regulation_description_id" options={selectDescriptions} onChange={(e) => setSelectedDescription(e)} />
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
          </form>
        </Col>
      </Row>
      <Row className="action_bar bottom">
        <Col>
          <Button onClick={() => navigate(-1) }><IoIosArrowBack /> Torna indietro</Button>
        </Col>
      </Row>
    </Container>
  );

  
}

export default ExtinguisherActivitiesAdd;